export const listAllowedRoles = userID => `
  query {
    listAllowedRoles(UserID: "${userID}") {
      Description
      Name
      Type
      Product {
        Type
        Name
        SKU
      }
    }
  }
`;
