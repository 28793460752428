function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/* eslint-disable no-console */

/* eslint-disable one-var */
import axios from 'axios';
import { saveAs } from 'file-saver';
import SessionStorage from '../utils/storage';
import { Config } from '../application/config';
import { doLogin, doLogout, getAndSetTokensUsingRefreshToken, isIdTokenValid, isRefreshTokenPresent } from './session';
export var apiContextPath = function apiContextPath() {
  if (Config.getApiPrefix()) {
    return Config.getApiPrefix() + '/';
  }

  return Config.isDevMode() ? window.location.origin + '/' : window.location.protocol + '//' + 'api' + '.' + window.location.host + '/';
};

var getFullUrl = function getFullUrl(url) {
  if (url.indexOf('http') === 0) {
    return url;
  }

  return apiContextPath() + url;
};

export var generateFormBody = function generateFormBody(obj) {
  var formBody = []; // IE complains about const

  for (var key in obj) {
    formBody.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
  }

  return formBody.join('&');
};
export var isUserLastActiveLessThan2Hrs = function isUserLastActiveLessThan2Hrs() {
  var currentTime = new Date().getTime();

  if (isNaN(parseInt(SessionStorage.getItem('userLastActivityTime')))) {
    SessionStorage.setItem('userLastActivityTime', currentTime);
  }

  return currentTime - parseInt(SessionStorage.getItem('userLastActivityTime')) < Config.getUserInactivityTime();
};
export var checkSessionPromiseWrapper = function checkSessionPromiseWrapper(callback) {
  return new Promise(function (resolve, reject) {
    if (!isUserLastActiveLessThan2Hrs()) {
      doLogout();
    } else if (isIdTokenValid()) {
      callback(resolve, reject);
    } else if (isRefreshTokenPresent()) {
      getAndSetTokensUsingRefreshToken().then(function () {
        callback(resolve, reject);
      });
    } else {
      doLogin();
    }
  });
};

var handleResponse = function handleResponse(response, resolve, reject) {
  switch (response.status) {
    case 200:
      response.clone().json().then(function (json) {
        resolve(json);
      })["catch"](function () {
        response.text().then(function (text) {
          resolve(text);
        });
      });
      break;

    case 302:
      window.location.replace(response.url);
      break;

    case 404:
      reject('Not Found!');
      break;

    default:
      console.log('Throwing error....', response.status);
      response.json().then(function (json) {
        reject(json);
      })["catch"](function () {
        reject(response.status);
      });
  }
};

export var doRegularFetchNoAuth = function doRegularFetchNoAuth(url) {
  var method = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'GET';
  var data = arguments.length > 2 ? arguments[2] : undefined;
  var dataType = arguments.length > 3 ? arguments[3] : undefined;
  var signal = arguments.length > 4 ? arguments[4] : undefined;
  return new Promise(function (resolve, reject) {
    fetch(getFullUrl(url), {
      method: method,
      credentials: 'omit',
      headers: data ? {
        'Content-Type': dataType,
        'Content-Length': data.size
      } : {},
      body: data,
      signal: signal
    }).then(function (res) {
      handleResponse(res, resolve, reject);
    })["catch"](function (err) {
      if (err.name !== 'AbortError') {
        console.log('Throwing error...', 'err');
        reject(err);
      }
    });
  });
};

var fetchWithHeaders = function fetchWithHeaders(resolve, reject, url) {
  var method = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'GET';
  var isAuthRequired = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
  var data = arguments.length > 5 ? arguments[5] : undefined;
  var signal = arguments.length > 6 ? arguments[6] : undefined;
  return fetch(url, {
    method: method,
    credentials: 'omit',
    headers: isAuthRequired ? {
      'Authorization': SessionStorage.getItem('id_token'),
      'Content-Type': 'application/json; charset=utf-8'
    } : {
      'Content-Type': 'application/json; charset=utf-8'
    },
    body: JSON.stringify(data),
    signal: signal
  }).then(function (res) {
    handleResponse(res, resolve, reject);
  })["catch"](function (err) {
    if (err.name !== 'AbortError') {
      console.log('Throwing error...', 'Generic');
      reject(err);
    }
  });
};

export var doFetchCustomHeader = function doFetchCustomHeader(url) {
  var method = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'GET';
  var isAuthRequired = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var customHeaders = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var data = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : undefined;
  var signal = arguments.length > 5 ? arguments[5] : undefined;
  var headers = new Headers(_objectSpread({}, customHeaders));

  if (!headers.has('Content-Type')) {
    headers.set('Content-Type', 'application/json; charset=utf-8');
  }

  if (isAuthRequired) {
    headers.set('Authorization', SessionStorage.getItem('id_token'));
  }

  var doFetch = function doFetch(resolve, reject) {
    return fetch(getFullUrl(url), {
      method: method,
      credentials: 'omit',
      headers: headers,
      body: method !== 'GET' ? JSON.stringify(data) : undefined,
      signal: signal
    }).then(function (res) {
      handleResponse(res, resolve, reject);
    })["catch"](function (err) {
      if (err.name !== 'AbortError') {
        console.log('Throwing error...', 'Generic');
        reject(err);
      }
    });
  };

  return checkSessionPromiseWrapper(doFetch);
};
export var doGet = function doGet(url, isAuthRequired, signal) {
  var doFetch = function doFetch(resolve, reject) {
    return fetchWithHeaders(resolve, reject, getFullUrl(url), 'GET', isAuthRequired, undefined, signal);
  };

  return checkSessionPromiseWrapper(doFetch);
};
export var doPost = function doPost(url, data, isAuthRequired, signal) {
  var doFetch = function doFetch(resolve, reject) {
    return fetchWithHeaders(resolve, reject, getFullUrl(url), 'POST', isAuthRequired, data, signal);
  };

  return checkSessionPromiseWrapper(doFetch);
};
export var doPut = function doPut(url, data, isAuthRequired, signal) {
  var doFetch = function doFetch(resolve, reject) {
    return fetchWithHeaders(resolve, reject, getFullUrl(url), 'PUT', isAuthRequired, data, signal);
  };

  return checkSessionPromiseWrapper(doFetch);
};
export var doPatch = function doPatch(url, data, isAuthRequired, signal) {
  var doFetch = function doFetch(resolve, reject) {
    return fetchWithHeaders(resolve, reject, getFullUrl(url), 'PATCH', isAuthRequired, data, signal);
  };

  return checkSessionPromiseWrapper(doFetch);
};
export var doDelete = function doDelete(url, data, isAuthRequired, signal) {
  var doFetch = function doFetch(resolve, reject) {
    return fetchWithHeaders(resolve, reject, getFullUrl(url), 'DELETE', isAuthRequired, data, signal);
  };

  return checkSessionPromiseWrapper(doFetch);
};
export var doDownload = function doDownload(url, fileType, fileName, data) {
  var isAuthRequired = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
  var successCallback = arguments.length > 5 ? arguments[5] : undefined;
  var errorCallback = arguments.length > 6 ? arguments[6] : undefined;

  var doFetch = function doFetch(resolve, reject) {
    var handleSuccess = function handleSuccess(response) {
      var blob = new Blob([response.data], {
        type: fileType
      });

      if (fileName) {
        saveAs(blob, fileName);
        resolve(response);
      } else {
        resolve(blob);
      }

      if (successCallback) {
        successCallback();
      }
    },
        handleError = function handleError(error) {
      console.log('Throwing error....', error);
      reject(error);

      if (errorCallback) {
        errorCallback();
      }
    };

    if (data) {
      return axios({
        method: 'post',
        url: getFullUrl(url),
        credentials: 'omit',
        headers: isAuthRequired ? {
          'Authorization': SessionStorage.getItem('id_token'),
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        } : {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        responseType: 'arraybuffer',
        data: generateFormBody({
          0: data
        })
      }).then(function (res) {
        handleSuccess(res);
      })["catch"](function (err) {
        handleError(err);
      });
    }

    return axios.get(getFullUrl(url), {
      credentials: 'omit',
      headers: isAuthRequired ? {
        'Authorization': SessionStorage.getItem('id_token'),
        'Content-Type': 'blob'
      } : {
        'Content-Type': 'blob'
      },
      responseType: 'arraybuffer'
    }).then(function (res) {
      handleSuccess(res);
    })["catch"](function (err) {
      handleError(err);
    });
  };

  return checkSessionPromiseWrapper(doFetch);
};
export var doUpload = function doUpload() {
  alert('To be implemented.');
};
export default {
  'defaultNoAuth': doRegularFetchNoAuth,
  'defaultCustomHeader': doFetchCustomHeader,
  'get': doGet,
  'post': doPost,
  'put': doPut,
  'patch': doPatch,
  'delete': doDelete,
  'fileDownload': doDownload,
  'fileUpload': doUpload
};