import './styles.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  selectIsAmAdmin,
  selectIsApiAdmin,
  selectIsBmAdmin,
  selectIsPmAdmin,
  selectIsXmAdmin,
} from '../../../redux/sessionUser/sessionUser.slice';
import {
  selectAmActivations,
  selectAmLicenses,
  selectAmPmPendingInvites,
  selectApiActivations,
  selectApiLicenses,
  selectBmActiveUsers,
  selectBmPendingInvites,
  selectPmActivations,
  selectPmLicenses,
  selectUserGroups,
  selectXmActivations,
  selectXmLicenses,
} from '../../../redux/userAdmin/userAdmin.slice';

const ViewDefault = ({ isLoading }) => {
  const amActivations = useSelector(selectAmActivations);
  const amLicenses = useSelector(selectAmLicenses);
  const amPmPendingInvites = useSelector(selectAmPmPendingInvites);
  const apiActivations = useSelector(selectApiActivations);
  const apiLicenses = useSelector(selectApiLicenses);
  const bmActiveUsers = useSelector(selectBmActiveUsers);
  const bmPendingInvites = useSelector(selectBmPendingInvites);
  const isAmAdmin = useSelector(selectIsAmAdmin);
  const isApiAdmin = useSelector(selectIsApiAdmin);
  const isBmAdmin = useSelector(selectIsBmAdmin);
  const isPmAdmin = useSelector(selectIsPmAdmin);
  const isXmAdmin = useSelector(selectIsXmAdmin);
  const pmActivations = useSelector(selectPmActivations);
  const pmLicenses = useSelector(selectPmLicenses);
  const userGroups = useSelector(selectUserGroups);
  const xmActivations = useSelector(selectXmActivations);
  const xmLicenses = useSelector(selectXmLicenses);

  if (isLoading) {
    return (
      <div className="text-banner">
        {Array.from({ length: 6 }, (_, i) => (
          <div className="loading-indicator" key={i}>
            <div className="upper" />
            <div className="lower" />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="text-banner">
      {isAmAdmin && (
        <>
          <div className="license-info">
            <div className="key">
              <FormattedMessage id={'userAdmin.amUsersLabel'} />
            </div>
            <div className="value">{`${amActivations} of ${amLicenses} activated`}</div>
          </div>
        </>
      )}
      {isPmAdmin && (
        <div className="license-info">
          <div className="key">
            <FormattedMessage id={'userAdmin.pmUsersLabel'} />
          </div>
          <div className="value">{`${pmActivations} of ${pmLicenses} activated`}</div>
        </div>
      )}
      {isBmAdmin && (
        <React.Fragment>
          <div className="license-info bm">
            <div className="key">
              <FormattedMessage id={'userAdmin.bmActiveUsersLabel'} />
            </div>
            <div className="value">{bmActiveUsers}</div>
          </div>
          <div className="license-info">
            <div className="key">
              BM&nbsp;
              <FormattedMessage id={'userAdmin.pendingInvitesLabel'} />
            </div>
            <div className="value">{bmPendingInvites}</div>
          </div>
        </React.Fragment>
      )}
      {(isAmAdmin || isPmAdmin) && amPmPendingInvites !== undefined && (
        <div className="license-info">
          <div className="key">
            {isAmAdmin && <span>AM</span>}
            {isAmAdmin && isPmAdmin && <span>/</span>}
            {isPmAdmin && <span>PM</span>}
            &nbsp;
            <FormattedMessage id={'userAdmin.pendingInvitesLabel'} />
          </div>
          <div className="value">{amPmPendingInvites}</div>
        </div>
      )}
      {isXmAdmin && (
        <div className="license-info">
          <div className="key">
            <FormattedMessage id={'userAdmin.xmUsersLabel'} />
          </div>
          <div className="value">{`${xmActivations} of ${xmLicenses} activated`}</div>
        </div>
      )}
      {(isBmAdmin || isPmAdmin || isXmAdmin) && userGroups.length > -1 && (
        <div className="license-info">
          <div className="key">
            <FormattedMessage id={'userAdmin.userGroupsLabel'} />
          </div>
          <div className="value">{userGroups.length}</div>
        </div>
      )}
      {isApiAdmin && (
        <div className="license-info">
          <div className="key">
            <FormattedMessage id={'userAdmin.apiUsersLabel'} />
          </div>
          <div className="value">{`${apiActivations} of ${apiLicenses} activated`}</div>
        </div>
      )}
    </div>
  );
};

ViewDefault.propTypes = {
  isLoading: PropTypes.bool,
};

export default ViewDefault;
