import { Config } from '../application/config';
export var sessionHandler = function sessionHandler() {
  if (!sessionStorage.length) {
    localStorage.setItem('getSessionStorage', Date.now());
  }

  window.addEventListener('storage', function (event) {
    var setSessionStorage = function setSessionStorage(storageStr) {
      if (storageStr) {
        var data = JSON.parse(storageStr); // IE complains about const

        for (var key in data) {
          sessionStorage.setItem(key, data[key]);
        }
      }
    };

    if (event.key === 'clearSessionStorage' && event.newValue) {
      sessionStorage.clear();

      if (Config.isDevMode()) {
        window.location.replace(Config.getRouteLogoutDevMode() + '?time=' + Date.now());
      } else {
        window.location.replace(Config.getRouteLogout() + '?time=' + Date.now());
      }
    }

    if (event.key === 'removeSessionStorageKey' && event.newValue) {
      sessionStorage.removeItem(event.newValue);
    }

    if (event.key === 'updateSessionStorage' && event.newValue) {
      if (JSON.stringify(sessionStorage) !== event.newValue) {
        // Not saving keys which begin with 'appConfig.'
        var parsedEventNewValue = JSON.parse(event.newValue),
            newSessionStorage = Object.keys(parsedEventNewValue).reduce(function (object, key) {
          if (key.indexOf('appConfig.') !== 0) {
            object[key] = parsedEventNewValue[key];
          }

          return object;
        }, {});
        setSessionStorage(JSON.stringify(newSessionStorage));
      }
    }

    if (event.key === 'getSessionStorage') {
      localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
      localStorage.removeItem('sessionStorage');
    } else if (event.key === 'sessionStorage' && !sessionStorage.length) {
      setSessionStorage(event.newValue);
    }
  });
};