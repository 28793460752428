export const mapAllowedRoles = allowedRolesArr => {
  const allowedRolesObj = {};

  allowedRolesArr
    .filter(role => role !== null)
    .sort((a, b) => (a.Type > b.Type ? 1 : -1))
    .forEach(({ Description, Name, Product, Type }) => {
      allowedRolesObj[Type] = {
        id: Type,
        description: Description,
        name: Name,
        product: Product.SKU,
      };
    });

  return allowedRolesObj;
};
