/* eslint-disable react/jsx-no-bind */
/* eslint-disable one-var */
import './styles.scss';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { KEY_STATUS_EXPIRING, KEY_STATUS_REVOKED } from '../../../constants/appVariables';
import View from './view';

const ApiKeyDisplay = ({
  daysTillExpire,
  expirationDate,
  isApiUserOnly,
  isHistorical = false,
  keyString,
  onCopyKey = null,
  onSelectKey,
  onToggleModal = null,
  showBanner = false,
  status,
}) => {
  const handleCopyKey = () => {
    onCopyKey(keyString);
  };

  const handleSetKeyBannerMessage = () => {
    const additionalStatement = isApiUserOnly ? 'Contact your administrator to generate a new key.' : '';

    if (status === KEY_STATUS_EXPIRING) {
      if (daysTillExpire === 0) {
        return `Attention: This key will expire in less than an hour. ${additionalStatement}`;
      } else if (daysTillExpire < 1) {
        return `Attention: This key will expire in ${daysTillExpire * 24} hour(s). ${additionalStatement}`;
      }

      return `Attention: This key will expire in ${daysTillExpire} day(s). ${additionalStatement}`;
    } else if (status === KEY_STATUS_REVOKED) {
      return isApiUserOnly ? (
        <FormattedMessage id="apiKeyManagement.apiUserOnly.revokedKeyBanner" />
      ) : (
        <FormattedMessage id="apiKeyManagement.revokedKeyBanner" />
      );
    }

    return isApiUserOnly ? (
      <FormattedMessage id="apiKeyManagement.apiUserOnly.expiredKeyBanner" />
    ) : (
      <FormattedMessage id="apiKeyManagement.expiredKeyBanner" />
    );
  };

  const handleToggleModal = () => {
    onSelectKey(keyString);
    onToggleModal();
  };

  return (
    <div className="api-key-display">
      {showBanner && (
        <div className={classnames('attention-banner', `${status.toLowerCase()}`)}>
          <span className={`${status.toLowerCase()}-icon`} />
          <span>{handleSetKeyBannerMessage()}</span>
        </div>
      )}
      <View
        expirationDate={expirationDate}
        isApiUserOnly={isApiUserOnly}
        isHistorical={isHistorical}
        keyString={keyString}
        onCopyKey={handleCopyKey}
        onToggleModal={handleToggleModal}
        status={status}
      />
    </div>
  );
};

ApiKeyDisplay.propTypes = {
  daysTillExpire: PropTypes.number,
  expirationDate: PropTypes.string,
  isApiUserOnly: PropTypes.bool,
  isHistorical: PropTypes.bool,
  keyString: PropTypes.string,
  onCopyKey: PropTypes.func,
  onSelectKey: PropTypes.func,
  onToggleModal: PropTypes.func,
  showBanner: PropTypes.bool,
  status: PropTypes.string,
};

export default ApiKeyDisplay;
