export const ACTIVE = 'Active',
  ADD_USER = 'ADD_USER',
  ADMIN = 'admin',
  API_KEYS = 'api_keys',
  AUTOPROVISIONING = 'autoprovisioning',
  DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please try again later.',
  DISABLED = 'Disabled',
  EDIT_USER = 'EDIT_USER',
  ERROR = 'error',
  FEDERATION = 'federation',
  FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD',
  KEY_STATUS_ACTIVE = 'Active',
  KEY_STATUS_EXPIRED = 'Expired',
  KEY_STATUS_EXPIRING = 'Expiring',
  KEY_STATUS_REVOKED = 'Revoked',
  LOCKED = 'Locked',
  PENDING = 'Pending',
  RESOURCES = 'resources',
  ROLE_ADMIN = 'Admin',
  ROLE_FEDERATED_MANAGER = 'FederatedManager',
  ROLE_AM_ADMIN = 'AMAdmin',
  ROLE_AM_REGULAR_USER = 'AMRegularUser',
  ROLE_API_ADMIN = 'APIAdmin',
  ROLE_API_USER = 'APIUser',
  ROLE_BM_ADMIN = 'BMAdmin',
  ROLE_BM_REGULAR_USER = 'BMRegularUser',
  ROLE_PM_ADMIN = 'PMAdmin',
  ROLE_PM_REGULAR_USER = 'PMRegularUser',
  ROLE_XM_ADMIN = 'XMAdmin',
  ROLE_XM_REGULAR_USER = 'XMRegularUser',
  SUCCESS = 'success',
  WARNING = 'warning',
  APPS = {
    PM: 'PM',
    AM: 'AM',
    BM: 'BM',
    CC: 'CC',
    XM: 'XM',
  };
