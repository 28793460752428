import {
  setAmActivations,
  setAmLicenses,
  setApiActivations,
  setApiLicenses,
  setBmActivations,
  setBmLicenses,
  setBmManagerActivations,
  setBmManagerLicenses,
  setPmActivations,
  setPmLicenses,
  setXmActivations,
  setXmLicenses,
} from './userAdmin.slice';

const metricsLimitsSettings = {
  'am.seats.count': {
    activationsType: value => setAmActivations(value),
    licensesType: value => setAmLicenses(value),
  },
  'bm.seats.count': {
    activationsType: value => setBmActivations(value),
    licensesType: value => setBmLicenses(value),
  },
  'bm.manager_seats.count': {
    activationsType: value => setBmManagerActivations(value),
    licensesType: value => setBmManagerLicenses(value),
  },
  'cc.seats.count': {
    activationsType: value => setApiActivations(value),
    licensesType: value => setApiLicenses(value),
  },
  'pm.seats.count': {
    activationsType: value => setPmActivations(value),
    licensesType: value => setPmLicenses(value),
  },
  'xm.seats.count': {
    activationsType: value => setXmActivations(value),
    licensesType: value => setXmLicenses(value),
  },
};

export const getMetricLimits = metricLimit => {
  if (metricsLimitsSettings[metricLimit.Type]) {
    const settings = metricsLimitsSettings[metricLimit.Type];
    const activations = isNaN(parseInt(metricLimit.Value)) ? 0 : parseInt(metricLimit.Value);
    const licenses = isNaN(parseInt(metricLimit.HardLimit)) ? 0 : parseInt(metricLimit.HardLimit);

    return {
      activationsType: settings.activationsType,
      licensesType: settings.licensesType,
      activations: activations,
      licenses: licenses,
    };
  }
};
