// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/imgs/icons.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".banner-text-area{height:100px;padding:20px 40px;border-top:1px solid #80808061;border-bottom:1px solid #80808061;display:flex;background-color:#F2F2F2}.banner-text-area .loading-bar-wrapper{height:18px;display:flex;align-items:center}.banner-text-area .label{display:flex;flex-direction:column;justify-content:center;font-weight:normal;font-size:18px;text-align:left;min-width:175px;padding-right:25px;color:#293033}.banner-text-area .label.full-width{min-width:100%;padding-left:0;padding-right:0}.banner-text-area .label .title{font-size:14px;padding-bottom:10px;color:#7a7a7a}.banner-text-area .label .info-icon{width:16px;height:16px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:-644px -784px;background-repeat:no-repeat;display:inline-block;position:relative;top:-2px;margin-left:5px}.banner-text-area .label .info-icon .tooltip-info{display:none;transition:.2s all ease-in-out;position:absolute;background:#2d2e2e;padding:10px 15px 10px 11px;color:white;font-size:14px;line-height:20px;bottom:-10px;left:-8px;background:#2d2e2e;transform:translate(0, 100%);border-radius:4px;border:0 4px 32px 0 rgba(45,46,46,0.16),0 0 4px 0 rgba(45,46,46,0.2)}.banner-text-area .label .info-icon .tooltip-info::before{content:\"\";position:absolute;border-left:6px solid transparent;border-right:6px solid transparent;border-top:6px solid transparent;top:1px;left:0;margin-left:-9px;-webkit-transform:translate(20px, -100%);transform:translate(20px, -100%);border-bottom:6px solid #2d2e2e}.banner-text-area .label .info-icon:hover{background-position:-644px -840px}.banner-text-area .label .info-icon:hover .tooltip-info{display:block}\n", ""]);
// Exports
module.exports = exports;
