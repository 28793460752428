import './styles.scss';

import * as Sentry from '@sentry/react';
import {
  Button,
  InputSearchTypeaheadGroups,
  InputTextbox,
  LoadingSpinnerMd,
  ModalMd,
  ModalSm,
  TooltipNoHandler,
  Utils,
} from 'c2-common-ui';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import IntlFormatterHOC from '../../../../utils/intlFormatterHOC';
import { getUsersInUserGroup } from '../../../../webAPIs/userCollaboration';

class EditUserGroupModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmModalOpen: false,
      groupName: '',
      initialExistingUsers: [],
      loading: true,
      selectedUsers: [],
      selectedUsersForListDisplay: [],
      userGroupItems: [],
    };
  }

  componentDidMount() {
    const { userGroup, userGroups, users } = this.props;

    getUsersInUserGroup(userGroup.id)
      .then(res => {
        let selectedUsersArr = [],
          userGroupsArr = [];

        selectedUsersArr = res.data.map(({ user_id, name, email }) => ({
          id: user_id,
          displayName: name,
          displayAdditional: email,
        }));

        userGroupsArr = userGroups.map(obj => ({
          id: obj.id,
          displayName: obj.name,
          subGroup: [],
        }));

        userGroupsArr.push({
          id: 'default',
          displayName: 'Default',
          subGroup: [],
        });

        userGroupsArr.forEach(userGroup => {
          const usersBelongingToUserGroup = users
            .filter(({ groups }) =>
              userGroup.displayName === 'Default' ? groups.length === 0 : groups.indexOf(userGroup.displayName) > -1
            )
            .map(({ id, name, email }) => ({
              id: id,
              displayName: name,
              displayAdditional: email,
            }));

          userGroup.subGroup = usersBelongingToUserGroup;
        });

        this.setState({
          groupName: this.props.userGroup.name,
          initialExistingUsers: selectedUsersArr,
          loading: false,
          userGroupItems: userGroupsArr,
        });

        this.handleSelectUser(selectedUsersArr);
      })
      .catch(err => {
        Sentry.captureException(err);
      });
  }

  handleDeleteGroup = () => {
    const users = this.state.selectedUsers.map(userObj => userObj.id);

    this.props.onDeleteUserGroup(this.props.userGroup.id, users);
    this.handleToggle();
  };

  handleInputChange = event => {
    const target = event.currentTarget;

    this.setState({
      groupName: target.value,
    });
  };

  handleRemoveUser = event => {
    const { selectedUsers } = this.state,
      userId = event.target.id,
      filteredSelectedUsers = selectedUsers.filter(selectedUserObj => selectedUserObj.id !== userId);

    this.handleSelectUser(filteredSelectedUsers);
  };

  handleSelectUser = items => {
    const { allowedRoles, users } = this.props,
      itemsCopy = Utils.deepClone(items);

    itemsCopy.forEach(item => {
      const user = users.find(user => user.id === item.id),
        { groups, roles } = user || { groups: [], roles: [] };

      item.roles = roles
        .map(role => {
          const roleDetails = Object.values(allowedRoles).filter(({ id }) => id === role)[0];

          return roleDetails !== undefined ? roleDetails.name : role;
        })
        .sort()
        .join(', ');

      item.groups = [...groups].sort().join(', ');
    });

    this.setState({
      selectedUsers: items,
      selectedUsersForListDisplay: itemsCopy.sort((a, b) =>
        a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1
      ),
    });
  };

  handleSetUserToUserGroupMapping = userGroupsList => {
    const usersToUserGroupsList = Utils.deepClone(userGroupsList);

    this.props.users.forEach(userObj => {
      if (userObj.groups.length === 0) {
        usersToUserGroupsList
          .filter(obj => obj.id === 'default')[0]
          .subGroup.push({
            id: userObj.id,
            displayName: userObj.name,
            displayAdditional: userObj.email,
          });
      } else {
        userObj.groups.forEach(userGroup => {
          usersToUserGroupsList
            .filter(obj => obj.displayName === userGroup)[0]
            .subGroup.push({
              id: userObj.id,
              displayName: userObj.name,
              displayAdditional: userObj.email,
            });
        });
      }
    });

    return usersToUserGroupsList;
  };

  handleSubmit = () => {
    let usersToAdd = [],
      usersToRemove = [];

    const addedUsers = this.state.selectedUsers.filter(
        selectedUserObj => !this.state.initialExistingUsers.some(userObj => userObj.id === selectedUserObj.id)
      ),
      removedUsers = this.state.initialExistingUsers.filter(
        initialUserObj => !this.state.selectedUsers.some(userObj => userObj.id === initialUserObj.id)
      );

    usersToAdd = addedUsers.map(userObj => ({
      id: userObj.id,
      email: userObj.displayAdditional,
      name: userObj.displayName,
    }));

    usersToRemove = removedUsers.map(userObj => userObj.id);

    this.props.onSubmit(this.props.userGroup.id, this.state.groupName, usersToAdd, usersToRemove);
    this.handleToggle();
  };

  handleToggle = () => {
    this.props.onToggleModal();
  };

  handleToggleConfirmModal = () => {
    this.setState({
      confirmModalOpen: !this.state.confirmModalOpen,
    });
  };

  render() {
    return (
      <ModalMd
        classes={classnames('edit-user-group-modal')}
        onHandleClose={this.handleToggle}
        open={this.props.isOpen}
        title={this.props.onFormatIntl('userModal.editUserGroupTitle')}
      >
        <div className="modal-body">
          {this.state.loading && <LoadingSpinnerMd />}
          {!this.state.loading && (
            <React.Fragment>
              <InputTextbox
                classes={'group-name-textbox'}
                label={this.props.onFormatIntl('userModal.userGroupNameLabel')}
                name={'groupNameInput'}
                onChange={this.handleInputChange}
                placeholder={this.props.onFormatIntl('userModal.userGroupNamePlaceholder')}
                value={this.state.groupName}
              />
              <div className="typeahead-wrapper">
                <InputSearchTypeaheadGroups
                  classes={'select-users-typeahead'}
                  label={this.props.onFormatIntl('userModal.selectUsersLabel')}
                  items={this.state.userGroupItems}
                  onSelect={this.handleSelectUser}
                  placeholder={this.props.onFormatIntl('userModal.selectUsersPlaceholder')}
                  selectedItems={this.state.selectedUsers}
                />
                {this.state.selectedUsers.length > 0 && (
                  <span className="selected-user-caption">{`${this.state.selectedUsers.length} user(s) selected`}</span>
                )}
                <span className="info-icon" />
                <TooltipNoHandler
                  infoArr={['You can only select multiple users who have similar roles']}
                  showOnClick={false}
                />
              </div>
              <div className="selected-users-list">
                {this.state.selectedUsersForListDisplay.map((selectedUserObj, i) => (
                  <div className="list-item" key={i}>
                    <div className="top-row">
                      <span className="user-name">{selectedUserObj.displayName}</span>
                      <span className="user-email">{selectedUserObj.displayAdditional}</span>
                      <span className="remove-button" id={selectedUserObj.id} onClick={this.handleRemoveUser} />
                    </div>
                    <div className="bottom-row">
                      <span className="user-roles">{selectedUserObj.roles}</span>
                      {selectedUserObj.groups && <span className="divider" />}
                      <span className="user-groups">{selectedUserObj.groups}</span>
                    </div>
                  </div>
                ))}
              </div>
            </React.Fragment>
          )}
        </div>
        <div className="modal-footer">
          <div>
            <Button
              classes={'delete-group-button'}
              color={'secondary'}
              disabled={this.state.loading}
              onClick={this.handleToggleConfirmModal}
            >
              <FormattedMessage id="userModal.deleteGroupButton" />
            </Button>
          </div>
          <div>
            <Button
              classes={'cancel-button'}
              color={'secondary'}
              disabled={this.state.loading}
              onClick={this.handleToggle}
            >
              <FormattedMessage id="userModal.cancelButton" />
            </Button>
            <Button classes={'save-button'} color={'primary'} disabled={this.state.loading} onClick={this.handleSubmit}>
              <FormattedMessage id={'userModal.saveButton'} />
            </Button>
          </div>
        </div>
        {this.state.confirmModalOpen && (
          <ModalSm
            classes={'confirm-modal'}
            onHandleClose={this.handleToggleConfirmModal}
            open={this.state.confirmModalOpen}
            title={this.props.onFormatIntl('userModal.confirmTitle')}
          >
            <div className="modal-subheader">
              <span className="warning-icon" />
              <span className="warning-text">You are about to delete the group</span>
            </div>
            <div className="modal-body">
              <span className="message">
                If you delete group, then the users will not be able to share with each other going forward.
              </span>
            </div>
            <div className="modal-footer">
              <Button color={'secondary'} onClick={this.handleToggleConfirmModal}>
                <FormattedMessage id="userModal.cancelButton" />
              </Button>
              <Button color={'primary'} disabled={this.state.loading} onClick={this.handleDeleteGroup}>
                Delete Group
              </Button>
            </div>
          </ModalSm>
        )}
      </ModalMd>
    );
  }
}

EditUserGroupModal.propTypes = {
  allowedRoles: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onDeleteUserGroup: PropTypes.func,
  onFormatIntl: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onToggleModal: PropTypes.func.isRequired,
  userGroup: PropTypes.object,
  userGroups: PropTypes.array,
  users: PropTypes.array.isRequired,
};

export default IntlFormatterHOC(EditUserGroupModal);
