/* eslint-disable one-var */
export var deepClone = function deepClone() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return JSON.parse(JSON.stringify(obj));
};
export var isObjectNull = function isObjectNull() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};
export var isTestMode = function isTestMode() {
  return window.location.origin.indexOf('dev') >= 0 || window.location.origin.indexOf('localhost') >= 0;
};
export var toTitleCase = function toTitleCase() {
  var str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return str.toLowerCase().split(' ').map(function (word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }).join(' ');
};
export default {
  deepClone: deepClone,
  isObjectNull: isObjectNull,
  isTestMode: isTestMode,
  toTitleCase: toTitleCase
};