import { I18nMessageContent, I18nMessageObject } from '../i18nContent';

export const getLocale = () =>
  (navigator && navigator.languages && navigator.languages[0]) || navigator.language || 'en-US';

export const flattenMessages = (nestedMessages: I18nMessageContent | I18nMessageObject | object, prefix = '') => {
  return Object.keys(nestedMessages).reduce((messages: I18nMessageObject, key) => {
    const value = nestedMessages[key],
      prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value as string;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};
