import { Fetch, SessionStorage } from 'c2-common-ui';

const API_PATH = localStorage.getItem('devModeCyberCube') ? 'http://localhost:3000/' : '';

export const getFederationIdpInfo = (payload, signal) => Fetch.get(`${API_PATH}admin/fed/IDP/info`, payload, signal);
export const putCreateFederationIdp = payload => {
  const isFedAuthOnly = SessionStorage.getItem('fed_auth_only') === 'true';

  if (typeof payload === 'string') {
    return Fetch.put(`${API_PATH}admin/fed/IDP/create?authentication_only=${isFedAuthOnly}`, { link: payload });
  }

  const apiContextPath = localStorage.getItem('devModeCyberCube')
    ? 'http://localhost:3000/'
    : `${window.location.protocol}//api${window.location.host}/`;

  return fetch(`${apiContextPath}admin/fed/IDP/create?authentication_only=${isFedAuthOnly}`, {
    method: 'PUT',
    credentials: 'omit',
    headers: {
      Authorization: SessionStorage.getItem('id_token'),
    },
    body: payload,
  }).then(res => res.clone().json());
};
export const putSetFederationRoleMapping = payload => Fetch.put(`${API_PATH}admin/fed/setrolemapping`, payload);
