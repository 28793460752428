/* eslint-disable react/jsx-no-bind */
import './styles.scss';

import * as Sentry from '@sentry/react';
import { Button, Footer, Header, InputCheckbox, InputTextbox, LoadingSpinnerLg } from 'c2-common-ui';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectEntityId,
  selectFetchFedIdpInfoLoading,
  selectMetadataUrl,
  selectOrganizationName,
  selectSsoUrl,
} from '../../redux/federation/federation.slice';
import { createFedIdp, fetchFedIdpInfo } from '../../redux/federation/federation.thunks';
import { clearNotifications } from '../../redux/notifications/notifications.slice';
import { selectIsFederatedAuthOnly, setIsFederatedAuthOnly } from '../../redux/sessionUser/sessionUser.slice';
import IntlFormatterHOC from '../../utils/intlFormatterHOC';
import telemetryEvent, { Events, Features } from '../../utils/telemetry';
import ErrorFallback from '../common/ErrorFallback';
import UploadMetadataModal from './UploadMetadataModal';

const Federation = ({ onFormatIntl }) => {
  const dispatch = useDispatch();

  const _controller = useMemo(() => new AbortController(), []);
  const _signal = _controller.signal;

  const isFetchFedIdpInfoLoading = useSelector(selectFetchFedIdpInfoLoading);
  const entityId = useSelector(selectEntityId);
  const isFederatedAuthOnly = useSelector(selectIsFederatedAuthOnly);
  const metadataUrl = useSelector(selectMetadataUrl);
  const organizationName = useSelector(selectOrganizationName);
  const ssoUrl = useSelector(selectSsoUrl);

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchFedIdpInfo({ signal: _signal }));

    return () => {
      if (_controller) {
        _controller.abort();
      }

      dispatch(clearNotifications());
    };
  }, [dispatch, _controller, _signal]);

  const handleCopyText = inputId => () => {
    const copyText = document.querySelector(`*[name=${inputId}]`);

    if (copyText.value !== '') {
      copyText.select();

      document.execCommand('copy');
    }

    let copyEvent;

    inputId === 'signOnUrlInput' ? (copyEvent = 'copy_sign_on_url') : (copyEvent = 'copy_entity_id');

    telemetryEvent().click().button().feature(Features.FEDERATION).event(copyEvent);
  };

  const handleSetFederatedAuthOnly = boolean => {
    dispatch(setIsFederatedAuthOnly(boolean));
  };

  const handleToggleModal = () => {
    setModalOpen(!modalOpen);

    telemetryEvent().click().button().feature(Features.FEDERATION).event(Events.TOGGLE_UPLOAD_METADATA_MODAL);
  };

  const handleUploadMetadata = metadata => {
    dispatch(createFedIdp({ metadata }));

    telemetryEvent().click().button().feature(Features.FEDERATION).event(Events.SUBMIT_UPLOAD_METADATA);
  };

  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      <div className="main-view federation">
        <Header classes={'main-header'} label={onFormatIntl('federation.title')} />
        <div className="contents">
          {isFetchFedIdpInfoLoading && <LoadingSpinnerLg />}
          {!isFetchFedIdpInfoLoading && (
            <div className="wrapper">
              <div className="title">CyberCube SAML Metadata</div>
              <div className="caption">
                To set up provisional authentication with your provider, copy the provided links below to the
                appropriate places within your IdP. Then, copy or upload the SAML metadata from your IdP to the provided
                space below.
              </div>
              <div className="section">
                <InputTextbox
                  classes={'signon-textbox'}
                  label={'Single Sign On URL'}
                  name={'signOnUrlInput'}
                  readOnly={true}
                  value={ssoUrl}
                />
                <Button classes={'copy-button'} color={'secondary'} onClick={handleCopyText('signOnUrlInput')}>
                  Copy URL
                </Button>
              </div>
              <div className="section">
                <InputTextbox
                  classes={'entity-textbox'}
                  label={'CyberCube Entity ID'}
                  name={'entityIdInput'}
                  readOnly={true}
                  value={entityId}
                />
                <Button classes={'copy-button'} color={'secondary'} onClick={handleCopyText('entityIdInput')}>
                  Copy ID
                </Button>
              </div>
              {isFederatedAuthOnly && (
                <InputCheckbox
                  checked={isFederatedAuthOnly}
                  classes={'auth-only-checkbox'}
                  disabled={true}
                  name={'Enabled for authentication only'}
                />
              )}
              <div className="title">{organizationName} SAML Metadata</div>
              {metadataUrl && (
                <InputTextbox
                  classes={'metadata-textbox'}
                  label={'Metadata Document'}
                  name={'metadataUrlInput'}
                  placeholder={'Enter Metadata'}
                  readOnly={true}
                  value={metadataUrl}
                />
              )}
              <Button classes={'upload-button'} color={'primary'} onClick={handleToggleModal}>
                {ssoUrl ? 'Update' : 'Upload'} Metadata
              </Button>
            </div>
          )}
        </div>
        <Footer label={onFormatIntl('common.footer')} />
        {modalOpen && (
          <UploadMetadataModal
            isOpen={modalOpen}
            onSetFederatedAuthOnly={handleSetFederatedAuthOnly}
            isUpdate={ssoUrl !== ''}
            onSubmit={handleUploadMetadata}
            onToggleModal={handleToggleModal}
          />
        )}
      </div>
    </Sentry.ErrorBoundary>
  );
};

Federation.propTypes = {
  onFormatIntl: PropTypes.func,
};

export default IntlFormatterHOC(Federation);
