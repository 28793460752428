/* eslint-disable react/jsx-no-bind */
import './styles.scss';

import * as Sentry from '@sentry/react';
import { Button, InputTextbox, LoadingSpinnerMd, ModalLg, Utils } from 'c2-common-ui';
import isEqual from 'lodash.isequal';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { ADD_USER, EDIT_USER } from '../../../../constants/appVariables';
import {
  selectAllowedRoles,
  selectIsAmAdmin,
  selectIsApiAdmin,
  selectIsBmAdmin,
  selectIsPmAdmin,
  selectIsXmAdmin,
} from '../../../../redux/sessionUser/sessionUser.slice';
import {
  selectAmActivations,
  selectAmLicenses,
  selectApiActivations,
  selectApiLicenses,
  selectBmActivations,
  selectBmLicenses,
  selectBmManagerActivations,
  selectBmManagerLicenses,
  selectPmActivations,
  selectPmLicenses,
  selectTenantDomains,
  selectXmActivations,
  selectXmLicenses,
} from '../../../../redux/userAdmin/userAdmin.slice';
import { isEmailFormatValid } from '../../../../utils/emailValidation';
import IntlFormatterHOC from '../../../../utils/intlFormatterHOC';
import telemetryEvent, { Events, Features } from '../../../../utils/telemetry';
import { postGetUserInfo } from '../../../../webAPIs/users';
import { createRoleActivationsSettings } from './addOrEditUserModal.utils';
import RolesCheckboxGroup from './RolesCheckboxGroup';

const AddOrEditUserModal = ({
  isOpen,
  mode,
  onFormatIntl,
  onSetNotificationModalData,
  onSubmit,
  onToggleModal,
  userId,
}) => {
  const allowedRoles = useSelector(selectAllowedRoles);
  const amActivations = useSelector(selectAmActivations);
  const amLicenses = useSelector(selectAmLicenses);
  const apiActivations = useSelector(selectApiActivations);
  const apiLicenses = useSelector(selectApiLicenses);
  const bmActivations = useSelector(selectBmActivations);
  const bmLicenses = useSelector(selectBmLicenses);
  const bmManagerActivations = useSelector(selectBmManagerActivations);
  const bmManagerLicenses = useSelector(selectBmManagerLicenses);
  const isAmAdmin = useSelector(selectIsAmAdmin);
  const isApiAdmin = useSelector(selectIsApiAdmin);
  const isBmAdmin = useSelector(selectIsBmAdmin);
  const isPmAdmin = useSelector(selectIsPmAdmin);
  const isXmAdmin = useSelector(selectIsXmAdmin);
  const pmActivations = useSelector(selectPmActivations);
  const pmLicenses = useSelector(selectPmLicenses);
  const tenantDomains = useSelector(selectTenantDomains);
  const xmActivations = useSelector(selectXmActivations);
  const xmLicenses = useSelector(selectXmLicenses);

  // Component States
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [roles, setRoles] = useState({});
  const [response, setResponse] = useState({});

  const handleInputChange = event => {
    const target = event.currentTarget;

    switch (target.name) {
      case 'nameInput':
        setName(target.value);

        break;
      case 'emailInput':
      default:
        setEmail(target.value);

        break;
    }
  };

  const handlePostUserInputMetric = event => () => {
    telemetryEvent().typing().input().feature(Features.ADMIN).event(event);
  };

  const handleSubmit = () => {
    setLoading(true);

    if (mode === ADD_USER) {
      onSubmit(email, name, roles);
    } else {
      onSubmit(name, roles);
    }

    onToggleModal();
  };

  const handleToggleModal = () => {
    onToggleModal();
  };

  const handleToggleSelectRole = id => () => {
    const [roleKey, roleType] = id.split('_'),
      rolesCopy = Utils.deepClone(roles);

    rolesCopy[roleKey][roleType].isSelected = !rolesCopy[roleKey][roleType].isSelected;

    setRoles(rolesCopy);

    telemetryEvent().feature(Features.ADMIN).checkbox().click().event(Events.TOGGLE_USER_ROLE_CHECKBOX);
  };

  const handleValidateEmail = () => {
    if (!isEmailFormatValid(email)) {
      setEmailError(onFormatIntl('userModal.emailFormatError'));
    } else if (!isEmailDomainValid(email)) {
      setEmailError(onFormatIntl('userModal.emailDomainError'));
    }
  };

  const isEmailDomainValid = () => {
    const emailDomain = email.substring(email.indexOf('@') + 1).toLowerCase();

    return tenantDomains.some(domain => domain === emailDomain);
  };

  const mapUserRoles = useCallback(
    userRoles => {
      const roles = {};

      Object.entries(allowedRoles).forEach(([roleKey, roleDetails]) => {
        if (!roles[roleDetails.product]) {
          roles[roleDetails.product] = {};
        }

        roles[roleDetails.product][roleKey] = {
          description: roleDetails.description,
          name: roleDetails.name,
          isSelected: userRoles.some(({ Type }) => Type === roleKey),
        };
      });

      return roles;
    },
    [allowedRoles]
  );

  useEffect(() => {
    const areInputsFilled = email !== '' && name !== '';
    const areRolesSelected =
      Object.values(roles).filter(role => Object.values(role).filter(({ isSelected }) => isSelected).length > 0)
        .length > 0;

    if (areInputsFilled && isEmailFormatValid(email) && isEmailDomainValid(email) && areRolesSelected) {
      if (
        mode === EDIT_USER &&
        (!isEqual(roles, mapUserRoles(response.data.data_v2.roles)) || response.data.data_v2.name !== name)
      ) {
        setSubmitDisabled(false);
      } else {
        if (mode === ADD_USER) {
          setSubmitDisabled(false);
        } else {
          setSubmitDisabled(true);
        }
      }
    } else {
      setSubmitDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, name, roles, response, mode, mapUserRoles]);

  useEffect(() => {
    setEmailError('');
  }, [email]);

  useEffect(() => {
    if (mode === EDIT_USER) {
      setLoading(true);

      postGetUserInfo({ userid: userId })
        .then(res => {
          setEmail(res.data.data_v2.email);
          setName(res.data.data_v2.name);
          setRoles(mapUserRoles(res.data.data_v2.roles));
          setResponse(res);
          setLoading(false);
        })
        .catch(err => {
          Sentry.captureException(err);

          onSetNotificationModalData(
            true,
            'ERROR',
            'Edit User is currently unavailable',
            'Something went wrong. Please try again later.'
          );
        });
    } else {
      setRoles(mapUserRoles([]));
    }
  }, [allowedRoles, mapUserRoles, mode, onSetNotificationModalData, userId]);

  const activationData = {
    amActivations,
    amLicenses,
    apiActivations,
    apiLicenses,
    bmActivations,
    bmLicenses,
    bmManagerActivations,
    bmManagerLicenses,
    pmActivations,
    pmLicenses,
    xmActivations,
    xmLicenses,
  };

  const rolesActivationSettings = createRoleActivationsSettings(activationData);

  return (
    <ModalLg
      classes={'add-edit-user'}
      onHandleClose={handleToggleModal}
      open={isOpen}
      title={mode === ADD_USER ? onFormatIntl('userModal.addUser') : onFormatIntl('userModal.editUser')}
    >
      <div className="modal-body">
        {loading && <LoadingSpinnerMd />}
        {!loading && (
          <React.Fragment>
            <div className="row-title">
              <FormattedMessage id="userModal.userInfoTitle" />
            </div>
            <div className="row-element">
              <InputTextbox
                label={onFormatIntl('userModal.nameLabel')}
                name={'nameInput'}
                onChange={handleInputChange}
                onFocus={handlePostUserInputMetric(Events.INPUT_USER_NAME)}
                placeholder={onFormatIntl('userModal.namePlaceholder')}
                value={name}
              />
              <InputTextbox
                error={emailError}
                label={onFormatIntl('userModal.emailLabel')}
                name={'emailInput'}
                onBlur={handleValidateEmail}
                onChange={handleInputChange}
                onFocus={handlePostUserInputMetric(Events.INPUT_USER_EMAIL)}
                placeholder={onFormatIntl('userModal.emailPlaceholder')}
                readOnly={mode === EDIT_USER}
                value={email}
              />
            </div>
            <RolesCheckboxGroup
              activationData={rolesActivationSettings}
              isAmAdmin={isAmAdmin}
              isApiAdmin={isApiAdmin}
              isBmAdmin={isBmAdmin}
              isPmAdmin={isPmAdmin}
              isXmAdmin={isXmAdmin}
              onToggleSelectRole={handleToggleSelectRole}
              roles={roles}
            />
          </React.Fragment>
        )}
      </div>
      <div className="modal-footer">
        <Button color={'secondary'} disabled={loading} onClick={handleToggleModal}>
          <FormattedMessage id="userModal.cancelButton" />
        </Button>
        <Button color={'primary'} disabled={loading || submitDisabled} onClick={handleSubmit}>
          <FormattedMessage id={mode === ADD_USER ? 'userModal.addButton' : 'userModal.saveButton'} />
        </Button>
      </div>
    </ModalLg>
  );
};

AddOrEditUserModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  mode: PropTypes.string,
  onFormatIntl: PropTypes.func,
  onSetNotificationModalData: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onToggleModal: PropTypes.func.isRequired,
  userId: PropTypes.string,
};
export default IntlFormatterHOC(AddOrEditUserModal);
