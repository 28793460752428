/* eslint-disable react/jsx-no-bind */
/* eslint-disable one-var */
import './styles.scss';

import { SessionStorage } from 'c2-common-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { ADMIN, API_KEYS, AUTOPROVISIONING, FEDERATION, RESOURCES } from '../../constants/appVariables';
import {
  selectIsApiAdmin,
  selectIsApiUserOnly,
  selectIsFederatedAuthOnly,
  selectIsFederatedManager,
  selectIsSuperAdmin,
} from '../../redux/sessionUser/sessionUser.slice';
import { isApiUser } from '../../utils/user';
import SideNavItem from './SideNavItem';

const View = ({ activeTab, onOpenDocsPage, onOpenPage }) => {
  const isApiAdmin = useSelector(selectIsApiAdmin);
  const isApiUserOnly = useSelector(selectIsApiUserOnly);
  const isFederatedAuthOnly = useSelector(selectIsFederatedAuthOnly);
  const isSuperAdmin = useSelector(selectIsSuperAdmin);
  const isFederatedManager = useSelector(selectIsFederatedManager);

  const userRoles = JSON.parse(SessionStorage.getItem('rolesWithProducts'));

  if (isApiUserOnly) {
    return (
      <div className="side-nav-options">
        <SideNavItem
          hoverIconPosition="-414px -252px"
          iconPosition="-194px -252px"
          isActive={activeTab === RESOURCES}
          name={RESOURCES}
          onClick={onOpenDocsPage}
          tabName={RESOURCES}
        />
        <SideNavItem
          hoverIconPosition="-694px -250px"
          iconPosition="-694px -191px"
          isActive={activeTab === API_KEYS}
          name={API_KEYS}
          onClick={onOpenPage}
          tabName={API_KEYS}
        />
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="side-nav-options">
        <SideNavItem
          hoverIconPosition="-419px -458px"
          iconPosition="-198px -458px"
          isActive={activeTab === ''}
          name={ADMIN}
          onClick={onOpenPage}
          tabName=""
        />
        {isFederatedManager && (
          <React.Fragment>
            <SideNavItem
              hoverIconPosition="-421px -541px"
              iconPosition="-200px -541px"
              isActive={activeTab === FEDERATION}
              name={FEDERATION}
              onClick={onOpenPage}
              tabName={FEDERATION}
            />
            {!isFederatedAuthOnly && (
              <SideNavItem
                hoverIconPosition="-422px -624px"
                iconPosition="-201px -624px"
                isActive={activeTab === AUTOPROVISIONING}
                name={AUTOPROVISIONING}
                onClick={onOpenPage}
                tabName={AUTOPROVISIONING}
              />
            )}
          </React.Fragment>
        )}
        {(isApiAdmin || isApiUser(userRoles)) && (
          <SideNavItem
            hoverIconPosition="-694px -250px"
            iconPosition="-694px -191px"
            isActive={activeTab === API_KEYS}
            name={API_KEYS}
            onClick={onOpenPage}
            tabName={API_KEYS}
          />
        )}
      </div>
      <SideNavItem
        classes={'resources'}
        hoverIconPosition="-414px -252px"
        iconPosition="-194px -252px"
        isActive={activeTab === RESOURCES}
        name={RESOURCES}
        onClick={onOpenDocsPage}
        tabName={RESOURCES}
      />
    </React.Fragment>
  );
};

View.propTypes = {
  activeTab: PropTypes.string,
  onOpenDocsPage: PropTypes.func,
  onOpenPage: PropTypes.func,
};

export default View;
