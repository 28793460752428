import './styles.scss';

import {
  Button,
  InputSearchTypeaheadGroups,
  InputTextbox,
  LoadingSpinnerMd,
  ModalMd,
  TooltipNoHandler,
  Utils,
} from 'c2-common-ui';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import IntlFormatterHOC from '../../../../utils/intlFormatterHOC';

class CreateUserGroupModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      createButtonDisabled: true,
      groupName: '',
      selectedUsers: [],
      selectedUsersForListDisplay: [],
      userGroupItems: [],
    };
  }

  componentDidMount() {
    const { selectedUsers, userGroups, users } = this.props;

    let selectedUsersArr = [],
      userGroupsArr = [];

    selectedUsersArr = Object.keys(selectedUsers).map(username => ({
      id: username,
      displayName: selectedUsers[username].name,
      displayAdditional: selectedUsers[username].email,
    }));

    userGroupsArr = userGroups.map(obj => ({
      id: obj.id,
      displayName: obj.name,
      subGroup: [],
    }));

    userGroupsArr.push({
      id: 'default',
      displayName: 'Default',
      subGroup: [],
    });

    userGroupsArr.forEach(userGroup => {
      const usersBelongingToUserGroup = users
        .filter(({ groups }) =>
          userGroup.displayName === 'Default' ? groups.length === 0 : groups.indexOf(userGroup.displayName) > -1
        )
        .map(({ id, name, email }) => ({
          id: id,
          displayName: name,
          displayAdditional: email,
        }));

      userGroup.subGroup = usersBelongingToUserGroup;
    });

    this.setState({
      userGroupItems: userGroupsArr,
    });

    this.handleSelectUser(selectedUsersArr);
  }

  handleInputChange = event => {
    const target = event.currentTarget;

    this.setState({
      createButtonDisabled: target.value === '',
      groupName: target.value,
    });
  };

  handleRemoveUser = event => {
    const { selectedUsers } = this.state,
      userId = event.target.id,
      filteredSelectedUsers = selectedUsers.filter(selectedUserObj => selectedUserObj.id !== userId);

    this.handleSelectUser(filteredSelectedUsers);
  };

  handleSelectUser = items => {
    const { allowedRoles, users } = this.props,
      itemsCopy = Utils.deepClone(items);

    itemsCopy.forEach(item => {
      const user = users.find(user => user.id === item.id),
        { groups, roles } = user || { groups: [], roles: [] };

      item.roles = roles
        .map(role => {
          const roleDetails = Object.values(allowedRoles).filter(({ id }) => id === role)[0];

          return roleDetails !== undefined ? roleDetails.name : role;
        })
        .sort()
        .join(', ');

      item.groups = [...groups].sort().join(', ');
    });

    this.setState({
      selectedUsers: items,
      selectedUsersForListDisplay: itemsCopy.sort((a, b) =>
        a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1
      ),
    });
  };

  handleSubmit = () => {
    const { groupName, selectedUsers } = this.state,
      usersList = selectedUsers.map(selectedUserObj => ({
        id: selectedUserObj.id,
        email: selectedUserObj.displayAdditional,
        name: selectedUserObj.displayName,
      }));

    this.props.onSubmit(groupName, usersList);
    this.handleToggle();
  };

  handleToggle = () => {
    this.props.onToggleModal();
  };

  render() {
    return (
      <ModalMd
        classes={classnames('create-user-group-modal')}
        onHandleClose={this.handleToggle}
        open={this.props.isOpen}
        title={this.props.onFormatIntl('userModal.createUserGroupTitle')}
      >
        <div className="modal-body">
          {this.state.loading && <LoadingSpinnerMd />}
          {!this.state.loading && (
            <React.Fragment>
              <InputTextbox
                classes={'group-name-textbox'}
                label={this.props.onFormatIntl('userModal.userGroupNameLabel')}
                name={'groupNameInput'}
                onChange={this.handleInputChange}
                placeholder={this.props.onFormatIntl('userModal.userGroupNamePlaceholder')}
                value={this.state.groupName}
              />
              <div className="typeahead-wrapper">
                <InputSearchTypeaheadGroups
                  classes={'select-users-typeahead'}
                  label={this.props.onFormatIntl('userModal.selectUsersLabel')}
                  items={this.state.userGroupItems}
                  onSelect={this.handleSelectUser}
                  placeholder={this.props.onFormatIntl('userModal.selectUsersPlaceholder')}
                  selectedItems={this.state.selectedUsers}
                />
                {this.state.selectedUsers.length > 0 && (
                  <span className="selected-user-caption">{`${this.state.selectedUsers.length} user(s) selected`}</span>
                )}
                <span className="info-icon" />
                <TooltipNoHandler
                  infoArr={['You can only select multiple users who have similar roles']}
                  showOnClick={false}
                />
              </div>
              <div className="selected-users-list">
                {this.state.selectedUsersForListDisplay.map((selectedUserObj, i) => (
                  <div className="list-item" key={i}>
                    <div className="top-row">
                      <span className="user-name">{selectedUserObj.displayName}</span>
                      <span className="user-email">{selectedUserObj.displayAdditional}</span>
                      <span className="remove-button" id={selectedUserObj.id} onClick={this.handleRemoveUser} />
                    </div>
                    <div className="bottom-row">
                      <span className="user-roles">{selectedUserObj.roles}</span>
                      {selectedUserObj.groups && <span className="divider" />}
                      <span className="user-groups">{selectedUserObj.groups}</span>
                    </div>
                  </div>
                ))}
              </div>
            </React.Fragment>
          )}
        </div>
        <div className="modal-footer">
          <Button color={'secondary'} disabled={this.state.loading} onClick={this.handleToggle}>
            <FormattedMessage id="userModal.cancelButton" />
          </Button>
          <Button
            color={'primary'}
            disabled={this.state.loading || this.state.createButtonDisabled}
            onClick={this.handleSubmit}
          >
            <FormattedMessage id={'userModal.createButton'} />
          </Button>
        </div>
      </ModalMd>
    );
  }
}

CreateUserGroupModal.propTypes = {
  allowedRoles: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onFormatIntl: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onToggleModal: PropTypes.func.isRequired,
  selectedUsers: PropTypes.object,
  userGroups: PropTypes.array,
  users: PropTypes.array.isRequired,
};

export default IntlFormatterHOC(CreateUserGroupModal);
