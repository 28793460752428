/* eslint-disable react/jsx-no-bind */
/* eslint-disable one-var */
import './styles.scss';

import * as Sentry from '@sentry/react';
import { TooltipNoHandler } from 'c2-common-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectIsBmAdmin, selectIsPmAdmin, selectIsXmAdmin } from '../../../redux/sessionUser/sessionUser.slice.js';
import { selectUserGroups } from '../../../redux/userAdmin/userAdmin.slice.js';
import { addUsersToExistingUserGroup } from '../../../redux/userAdmin/userAdmin.thunks.js';
import BaseTableHOC from '../../../utils/baseTableHOC';
import telemetryEvent, { Events, Features } from '../../../utils/telemetry';
import { getUsersInUserGroup } from '../../../webAPIs/userCollaboration';
import ErrorTableRow from '../../common/ErrorTableRow.js';
import Table from '../../common/Table';
import CustomTableRow from './CustomTableRow';

const UsersTable = ({
  isTableLoading,
  onAddLoadingIndicator,
  onAddNotification,
  onFormatIntl,
  onSort,
  onToggleDisableUserModal,
  onToggleEditUserModal,
  onToggleResetMfaModal,
  onToggleResetPasswordModal,
  onToggleSelectUser,
  onUnlockUser,
  searchInput,
  selectedFilterCombinedList,
  selectedFilterUserGroupList,
  selectedFilterUserRoleList,
  selectedUsers,
  sortBy,
  sortOrder,
  users,
}) => {
  const dispatch = useDispatch();

  const isBmAdmin = useSelector(selectIsBmAdmin);
  const isPmAdmin = useSelector(selectIsPmAdmin);
  const isXmAdmin = useSelector(selectIsXmAdmin);
  const userGroups = useSelector(selectUserGroups);

  const DisabledDateHeader = (
    <div className="disabled-date-header">
      {onFormatIntl('userTable.disabledDateHeader')}
      <span className="info-icon" />
      <TooltipNoHandler infoArr={['Not all users will have this data.']} showOnClick={false} />
    </div>
  );

  let tableHeaders = [
    {
      id: 'name',
      displayName: onFormatIntl('userTable.userNameHeader'),
      sortable: true,
    },
    {
      id: 'email',
      displayName: onFormatIntl('userTable.userEmailHeader'),
      sortable: true,
    },
    {
      id: 'status',
      displayName: onFormatIntl('userTable.userStatusHeader'),
      sortable: true,
    },
    {
      id: 'roles',
      displayName: onFormatIntl('userTable.userRoleHeader'),
      sortable: false,
    },
    {
      id: 'groups',
      displayName: onFormatIntl('userTable.userGroupsHeader'),
      sortable: false,
    },
    {
      id: 'Last Login',
      displayName: onFormatIntl('userTable.lastLoginHeader'),
      sortable: false,
    },
    {
      id: 'Disabled Date',
      displayName: onFormatIntl('userTable.disabledDateHeader'),
      customHeader: DisabledDateHeader,
      sortable: false,
    },
    {
      id: 'actions',
      displayName: '',
      sortable: false,
    },
  ];

  const handleAddUserToExistingUserGroup = (userGroupId, userId, userEmail, userName) => {
    const userObj = {
      id: userId,
      email: userEmail,
      name: userName,
    };

    getUsersInUserGroup(userGroupId)
      .then(res => {
        if (res.status === 'success') {
          dispatch(
            addUsersToExistingUserGroup({
              userGroupId,
              users: [...new Array(userObj)],
            })
          );

          telemetryEvent().button().click().feature(Features.ADMIN).event(Events.SUBMIT_ADD_USER_TO_USER_GROUP);
        }
      })
      .catch(err => {
        Sentry.captureException(err);

        onAddNotification('error', 'Something went wrong. Please try again later.');
      });
  };

  const handleFilterTableRowData = tableData => {
    if (!searchInput && selectedFilterCombinedList.length === 0) {
      return tableData;
    }

    const filteredTableData = tableData.filter(user => {
      const isUserSelected = selectedUsers[user.id];

      let isUserFilterGroupMatch = selectedFilterUserGroupList.length === 0,
        isUserFilterRoleMatch = selectedFilterUserRoleList.length === 0,
        isUserSearchMatch = searchInput === '';

      if (selectedFilterUserGroupList.length > 0) {
        isUserFilterGroupMatch = user.groups.some(
          group => selectedFilterCombinedList.filter(obj => obj.displayName === group).length > 0
        );
      }

      if (selectedFilterUserRoleList.length > 0) {
        isUserFilterRoleMatch = user.roles.some(
          role => selectedFilterCombinedList.filter(obj => obj.id === role).length > 0
        );
      }

      if (searchInput) {
        isUserSearchMatch = user.name.toLowerCase().indexOf(searchInput.toLowerCase()) > -1;
      }

      return (isUserFilterGroupMatch && isUserFilterRoleMatch && isUserSearchMatch) || isUserSelected;
    });

    return filteredTableData;
  };

  const handleRenderTableBody = tableData => {
    const filteredTableData = handleFilterTableRowData(tableData);

    if (filteredTableData.length === 0) {
      return <ErrorTableRow />;
    }

    return filteredTableData.map((user, i) => {
      const isChecked = selectedUsers[user.id] !== undefined;

      return (
        <CustomTableRow
          key={i}
          allUserGroups={userGroups.map(({ id, name }) => ({ id, name }))}
          checked={isChecked}
          onAddLoadingIndicator={onAddLoadingIndicator}
          onAddUserToExistingUserGroup={handleAddUserToExistingUserGroup}
          onToggleDisableUserModal={onToggleDisableUserModal}
          onToggleEditUserModal={onToggleEditUserModal}
          onToggleResetMfaModal={onToggleResetMfaModal}
          onToggleResetPasswordModal={onToggleResetPasswordModal}
          onToggleSelectUser={onToggleSelectUser}
          onUnlockUser={onUnlockUser}
          user={user}
        />
      );
    });
  };

  if (!(isBmAdmin || isPmAdmin || isXmAdmin)) {
    tableHeaders = tableHeaders.filter(({ id }) => id !== 'groups');
  }

  return (
    <Table
      classes={'user-admin-table'}
      data={users}
      headers={tableHeaders}
      loading={isTableLoading}
      onRenderTableBody={handleRenderTableBody}
      onSort={onSort}
      sortBy={sortBy}
      sortOrder={sortOrder}
    />
  );
};

UsersTable.propTypes = {
  isTableLoading: PropTypes.bool,
  onAddLoadingIndicator: PropTypes.func,
  onAddNotification: PropTypes.func,
  onFormatIntl: PropTypes.func,
  onSort: PropTypes.func,
  onToggleDisableUserModal: PropTypes.func,
  onToggleEditUserModal: PropTypes.func,
  onToggleResetMfaModal: PropTypes.func,
  onToggleResetPasswordModal: PropTypes.func,
  onToggleSelectUser: PropTypes.func,
  onUnlockUser: PropTypes.func,
  searchInput: PropTypes.string,
  selectedFilterCombinedList: PropTypes.array,
  selectedFilterUserGroupList: PropTypes.array,
  selectedFilterUserRoleList: PropTypes.array,
  selectedUsers: PropTypes.object,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  users: PropTypes.array,
};

export default BaseTableHOC(UsersTable);
