/* eslint-disable react/jsx-no-bind */
/* eslint-disable one-var */
import './styles.scss';

import { SelectedFiltersDisplay, Utils } from 'c2-common-ui';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'throttle-debounce';

import { ADD_USER } from '../../constants/appVariables';
import { selectAllowedRoles } from '../../redux/sessionUser/sessionUser.slice';
import { selectUserGroups, selectUsers } from '../../redux/userAdmin/userAdmin.slice';
import {
  addUsersToExistingUserGroup,
  createUser,
  createUserGroup,
  editUserGroup,
  removeUserGroup,
} from '../../redux/userAdmin/userAdmin.thunks';
import telemetryEvent, { Events, Features } from '../../utils/telemetry';
import { mapAndSortRoles } from '../../utils/user';
import AddOrEditUserModal from '../common/Modals/AddOrEditUserModal';
import CreateUserGroupModal from '../common/Modals/CreateUserGroupModal';
import EditUserGroupModal from '../common/Modals/EditUserGroupModal';
import TextBanner from './TextBanner';
import UserInputsGroup from './UserInputsGroup';
import UsersTable from './UsersTable';

const View = ({ loading, onFormatIntl }) => {
  const dispatch = useDispatch();

  const allowedRoles = useSelector(selectAllowedRoles);
  const userGroups = useSelector(selectUserGroups);
  const users = useSelector(selectUsers);

  // Component States
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [createUserGroupModalOpen, setCreateUserGroupModalOpen] = useState(false);
  const [currentFilterGroupsList, setCurrentFilterGroupsList] = useState([]);
  const [currentFilterRolesList, setCurrentFilterRolesList] = useState([]);
  const [editUserGroupModalOpen, setEditUserGroupModalOpen] = useState(false);
  const [initFilterGroupsList, setInitFilterGroupsList] = useState([]);
  const [initFilterRolesList, setInitFilterRolesList] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [selectedFilterCombinedList, setSelectedFilterCombinedList] = useState([]);
  const [selectedFilterUserGroupList, setSelectedFilterUserGroupList] = useState([]);
  const [selectedFilterUserRoleList, setSelectedFilterUserRoleList] = useState([]);
  const [selectedUserGroup, setSelectedUserGroup] = useState({});
  const [selectedUsers, setSelectedUsers] = useState({});
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(() => {
    const filterInitGroupsList = userGroups.map(({ id, name }) => ({
      id: id,
      displayName: name,
      type: 'group',
    }));

    const filterInitRolesList = getAvailableUserRoles();

    setCurrentFilterGroupsList(filterInitGroupsList);
    setCurrentFilterRolesList(filterInitRolesList);

    setInitFilterGroupsList(filterInitGroupsList);
    setInitFilterRolesList(filterInitRolesList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers, userGroups]);

  const handleDebouncedSetStateForSearchInput = debounce(500, value => {
    setSearchInput(value);
  });

  const handleCreateUser = (email, name, roles) => {
    const selectedRolesArr = [];

    Object.values(roles).forEach(productDetails => {
      Object.entries(productDetails).forEach(([roleKey, roleDetails]) => {
        if (roleDetails.isSelected) {
          selectedRolesArr.push(roleKey);
        }
      });
    });

    dispatch(createUser({ email, name, roles: selectedRolesArr }));

    telemetryEvent().button().click().feature(Features.ADMIN).event(Events.SUBMIT_ADD_USER);
  };

  const handleAddUsersToExistingUserGroup = (userGroupId, users) => {
    dispatch(addUsersToExistingUserGroup({ userGroupId, users }));

    setSelectedUsers({});
  };

  const handleClickUserCheckbox = (userId, userEmail, userName, userRoles) => {
    const selectedUsersCopy = Utils.deepClone(selectedUsers);

    if (!selectedUsersCopy[userId]) {
      selectedUsersCopy[userId] = {
        email: userEmail,
        name: userName,
        roles: userRoles,
      };

      telemetryEvent().click().checkbox().feature(Features.ADMIN).event(Events.SELECT_USER_ROW);
    } else {
      delete selectedUsersCopy[userId];

      telemetryEvent().click().checkbox().feature(Features.ADMIN).event(Events.DESELECT_USER_ROW);
    }

    setSelectedUsers(selectedUsersCopy);
  };

  const handleClearAllFilters = () => {
    setCurrentFilterGroupsList(initFilterGroupsList);
    setCurrentFilterRolesList(initFilterRolesList);
    setSelectedFilterCombinedList([]);
    setSelectedFilterUserGroupList([]);
    setSelectedFilterUserRoleList([]);

    telemetryEvent().click().button().feature(Features.ADMIN).event(Events.CLEAR_ALL_FILTERS);
  };

  const handleCreateUserGroup = (groupName, users) => {
    dispatch(createUserGroup({ name: groupName, users }));

    setSelectedUsers({});

    telemetryEvent().click().button().feature(Features.ADMIN).event(Events.SUBMIT_CREATE_USER_GROUP);
  };

  const handleDeleteUserGroup = (userGroupId, users) => {
    dispatch(removeUserGroup({ userGroupId, users }));

    telemetryEvent().click().button().feature(Features.ADMIN).event(Events.SUBMIT_DELETE_USER_GROUP);
  };

  const handleEditUserGroup = (userGroupId, groupName, usersToAdd, usersToRemove) => {
    dispatch(editUserGroup({ userGroupId, groupName, usersToAdd, usersToRemove }));

    telemetryEvent().click().button().feature(Features.ADMIN).event(Events.SUBMIT_EDIT_USER_GROUP);
  };

  const handlePostUserInputMetric = () => {
    telemetryEvent().typing().input().feature(Features.ADMIN).event(Events.SEARCH_FOR_USER_NAME);
  };

  const handleRemoveFilter = element => {
    const uiEvent = `clear_filter_${element.type}`;

    const addElementToFilter = (arr, element) => {
      const newArr = Utils.deepClone(arr);

      newArr.push({
        id: element.id,
        displayName: element.displayName,
      });

      return newArr.sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1));
    };
    const removeElementFromFilter = (arr, element) => Utils.deepClone(arr).filter(item => item.id !== element.id);
    const newSelectedFilterCombinedList = Utils.deepClone(selectedFilterCombinedList).filter(
      item => `${item.id}_${item.type}` !== `${element.id}_${element.type}`
    );

    switch (element.type) {
      case 'group':
        setCurrentFilterGroupsList(addElementToFilter(currentFilterGroupsList, element));
        setSelectedFilterUserGroupList(removeElementFromFilter(selectedFilterUserGroupList, element));

        break;
      case 'role':
        setCurrentFilterRolesList(addElementToFilter(currentFilterRolesList, element));
        setSelectedFilterUserRoleList(removeElementFromFilter(selectedFilterUserRoleList, element));

        break;
      default:
        break;
    }

    telemetryEvent().click().button().feature(Features.ADMIN).event(uiEvent);

    setSelectedFilterCombinedList(newSelectedFilterCombinedList);
  };

  const handleSelectMenuItem = optionObj => {
    switch (optionObj.id) {
      case 'ADD_TO_USER_GROUP': {
        const users = Object.keys(selectedUsers).map(key => ({
          id: key,
          email: selectedUsers[key].email,
          name: selectedUsers[key].name,
        }));

        handleAddUsersToExistingUserGroup(optionObj.groupId, users);

        telemetryEvent().click().menuItem().feature(Features.ADMIN).event(Events.SUBMIT_ADD_USER_TO_USER_GROUP);

        break;
      }
      case 'CREATE_NEW_USER_GROUP':
        handleToggleCreateUserGroupModal();

        telemetryEvent().click().menuItem().feature(Features.ADMIN).event(Events.OPEN_CREATE_USER_GROUP_MODAL);

        break;
      case 'EDIT_USER_GROUP':
      default:
        handleToggleEditUserGroupModal(optionObj.groupId);

        telemetryEvent().click().menuItem().feature(Features.ADMIN).event(Events.OPEN_EDIT_USER_GROUP_MODAL);

        break;
    }
  };

  const handleSelectGroupFilterItem = element => {
    const filterEvent = `filter_group_${element.displayName.replace(/\s/g, '_')}`;
    const selectedFilterGroupListCopy = Utils.deepClone(selectedFilterUserGroupList);
    const selectedFilterCombinedListCopy = Utils.deepClone(selectedFilterCombinedList);

    selectedFilterGroupListCopy.push({
      id: element.id,
      displayName: element.displayName,
      type: 'group',
    });

    selectedFilterCombinedListCopy.push({
      id: element.id,
      displayName: element.displayName,
      type: 'group',
    });

    setCurrentFilterGroupsList(currentFilterGroupsList.filter(item => item.id !== element.id));
    setSelectedFilterCombinedList(selectedFilterCombinedListCopy);
    setSelectedFilterUserGroupList(selectedFilterGroupListCopy);

    telemetryEvent().click().button().feature(Features.ADMIN).event(filterEvent);
  };

  const handleSelectRoleFilterItem = element => {
    const filterEvent = `filter_role_${element.id}`;
    const selectedFilterRoleListCopy = Utils.deepClone(selectedFilterUserRoleList);
    const selectedFilterCombinedListCopy = Utils.deepClone(selectedFilterCombinedList);

    selectedFilterRoleListCopy.push({
      id: element.id,
      displayName: element.displayName,
      type: 'role',
    });

    selectedFilterCombinedListCopy.push({
      id: element.id,
      displayName: element.displayName,
      type: 'role',
    });

    setCurrentFilterRolesList(currentFilterRolesList.filter(item => item.id !== element.id));
    setSelectedFilterCombinedList(selectedFilterCombinedListCopy);
    setSelectedFilterUserRoleList(selectedFilterRoleListCopy);

    telemetryEvent().click().button().feature(Features.ADMIN).event(filterEvent);
  };

  const handleSetSearchInput = event => {
    handleDebouncedSetStateForSearchInput(event.currentTarget.value);
  };

  const handleSortColumn = columnHeaderKey => {
    const sortEvent = `sort_by_${columnHeaderKey}`;

    let newSortOrder = 'asc';

    if (columnHeaderKey === sortBy) {
      newSortOrder = sortOrder === 'asc' ? 'dsc' : 'asc';
    }

    setSortBy(columnHeaderKey);
    setSortOrder(newSortOrder);

    telemetryEvent().click().button().feature(Features.ADMIN).event(sortEvent);
  };

  const handleSortData = tableData => {
    const sortTableDataArrHelper = (columnHeaderKey, tableData) => {
      const sortedTableDataArr = [].concat(tableData).sort((obj1, obj2) => {
        if (!obj1[columnHeaderKey] || obj2[columnHeaderKey]) {
          return -1;
        }

        const obj1StringLowerCase = obj1[columnHeaderKey].toLowerCase();
        const obj2StringLowerCase = obj2[columnHeaderKey].toLowerCase();

        return obj1StringLowerCase > obj2StringLowerCase ? 1 : -1;
      });

      return sortedTableDataArr;
    };

    const sortedTableDataArr = sortTableDataArrHelper(sortBy, tableData);

    if (sortOrder === 'asc') {
      return sortedTableDataArr;
    }

    return sortedTableDataArr.reverse();
  };

  const handleToggleAddUserModal = () => {
    setAddUserModalOpen(!addUserModalOpen);

    telemetryEvent().click().button().feature(Features.ADMIN).event(Events.TOGGLE_ADD_USER_MODAL);
  };

  const handleToggleCreateUserGroupModal = () => {
    setCreateUserGroupModalOpen(!createUserGroupModalOpen);

    telemetryEvent().click().menuItem().feature(Features.ADMIN).event(Events.TOGGLE_CREATE_USER_GROUP_MODAL);
  };

  const handleToggleEditUserGroupModal = userGroupId => {
    const userGroup = userGroups.filter(({ id }) => id === userGroupId)[0];

    setEditUserGroupModalOpen(!editUserGroupModalOpen);
    setSelectedUserGroup(userGroup);

    telemetryEvent().click().menuItem().feature(Features.ADMIN).event(Events.TOGGLE_EDIT_USER_GROUP_MODAL);
  };

  const getAvailableUserRoles = useCallback(() => {
    let filterableRoles = [];

    users.forEach(user => {
      const uniqueRoles = user.roles.filter(role => filterableRoles.indexOf(role) === -1);

      filterableRoles.push(...uniqueRoles);
    });

    filterableRoles = mapAndSortRoles(allowedRoles, filterableRoles).map(role => {
      const roleDetails = Object.values(allowedRoles).filter(({ name }) => name === role)[0];

      return {
        id: roleDetails !== undefined ? roleDetails.id : role,
        displayName: role,
        type: 'role',
      };
    });

    return filterableRoles;
  }, [allowedRoles, users]);

  return (
    <div className="contents">
      <TextBanner isLoading={loading} />
      <UserInputsGroup
        currentFilterGroupsList={currentFilterGroupsList}
        currentFilterRolesList={currentFilterRolesList}
        loading={loading}
        onFormatIntl={onFormatIntl}
        onSelectGroupFilterItem={handleSelectGroupFilterItem}
        onSelectMenuItem={handleSelectMenuItem}
        onSelectRoleFilterItem={handleSelectRoleFilterItem}
        onSetSearchInput={handleSetSearchInput}
        onPostUserInputMetric={handlePostUserInputMetric}
        onToggleAddUserModal={handleToggleAddUserModal}
        selectedUsers={selectedUsers}
      />
      <SelectedFiltersDisplay
        items={selectedFilterCombinedList}
        onClearAll={handleClearAllFilters}
        onRemoveFilter={handleRemoveFilter}
      />
      <UsersTable
        isTableLoading={loading}
        onSort={handleSortColumn}
        onToggleSelectUser={handleClickUserCheckbox}
        searchInput={searchInput}
        selectedFilterCombinedList={selectedFilterCombinedList}
        selectedFilterUserGroupList={selectedFilterUserGroupList}
        selectedFilterUserRoleList={selectedFilterUserRoleList}
        selectedUsers={selectedUsers}
        sortBy={sortBy}
        sortOrder={sortOrder}
        users={handleSortData(users)}
      />
      {addUserModalOpen && (
        <AddOrEditUserModal
          isOpen={addUserModalOpen}
          mode={ADD_USER}
          onSubmit={handleCreateUser}
          onToggleModal={handleToggleAddUserModal}
        />
      )}
      {createUserGroupModalOpen && (
        <CreateUserGroupModal
          allowedRoles={allowedRoles}
          isOpen={createUserGroupModalOpen}
          onSubmit={handleCreateUserGroup}
          onToggleModal={handleToggleCreateUserGroupModal}
          selectedUsers={selectedUsers}
          userGroups={userGroups}
          users={users}
        />
      )}
      {editUserGroupModalOpen && (
        <EditUserGroupModal
          allowedRoles={allowedRoles}
          isOpen={editUserGroupModalOpen}
          onDeleteUserGroup={handleDeleteUserGroup}
          onSubmit={handleEditUserGroup}
          onToggleModal={handleToggleEditUserGroupModal}
          userGroup={selectedUserGroup}
          userGroups={userGroups}
          users={users}
        />
      )}
    </div>
  );
};

View.propTypes = {
  loading: PropTypes.bool,
  onFormatIntl: PropTypes.func,
};

export default View;
