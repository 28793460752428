export enum Features {
  ADMIN = 'admin',
  SIDE_NAV = 'sidenav',
  TENANTS_TABLE = 'tenants_table',
  COMPANY_STATUS = 'company_status',
  TENANT_PAGE = 'tenant_page',
  PAGE_HEADER = 'page_header',
  USER_FORM = 'user_form',
  TENANT_FORM = 'tenant_form',
  LICENSE_FORM = 'license_form',
  LICENSES_LIST = 'licenses_list',
  MANAGEMENT = 'management',

  AUTOPROVISIONING = 'autoprovisioning',
  BM_ADMIN = 'bm_admin',
  FEDERATION = 'federation',
  KEYS = 'keys',
}
export enum Applications {
  RBAC = 'RBAC',
}

export enum Components {
  BUTTON = 'button',
  INPUT = 'input',
  TOOLTIP = 'tooltip',
  FILTERTAB = 'filter_tab',
  PAGETAB = 'page_tab',
  DROPDOWNITEM = 'dropdown_item',
  CHECKBOX = 'checkbox',
  MENUITEM = 'menuitem',
  TABLE = 'table',
  TABLEROW = 'tablerow',
  TABLEHEADER = 'tableheader',
  LINK = 'link',
  TYPEAHEAD = 'typeahead',
  PAGE = 'page',
  SWITCH = 'switch',
  TAG_SELECTOR = 'TAG_SELECTOR',
}

export enum Actions {
  CLICK = 'click',
  SCROLL = 'scroll',
  LOAD = 'load',
  TYPING = 'typing',
  HOVER = 'hover',
  RETURN_KEY = 'return_key',
}

export enum Events {
  ADD_AUTOPROVISIONING_GROUP_ROLE_INPUT_ROW = 'add_autoprovisioning_group_role_input_row',
  CLEAR_ALL_FILTERS = 'clear_all_filters',
  COPY_API_KEY = 'copy_api_key',
  DELETE_AUTOPROVISIONING_GROUP_ROLE_INPUT_ROW = 'delete_autoprovisioning_group_role_input_row',
  DESELECT_USER_ROW = 'deselect_user_row',
  DOWNLOAD_ALL_ANALYSES = 'download_all_analyses',
  INPUT_AUTOPROVISIONING_GROUP_NAME = 'input_autoprovisioning_group_name',
  INPUT_USER_EMAIL = 'input_user_email',
  INPUT_USER_NAME = 'input_user_name',
  OPEN_CREATE_USER_GROUP_MODAL = 'open_create_user_group_modal',
  OPEN_EDIT_USER_GROUP_MODAL = 'open_edit_user_group_modal',
  SEARCH_FOR_USER_NAME = 'search_for_user_name',
  SELECT_AUTOPROVISIONING_ROLE = 'select_autoprovisioning_role',
  SELECT_USER_ROW = 'select_user_row',
  SUBMIT_ADD_USER = 'submit_add_user',
  SUBMIT_ADD_USER_TO_USER_GROUP = 'submit_add_user_to_user_group',
  SUBMIT_AUTOPROVISIONING_GROUP = 'submit_autoprovisioning_group',
  SUBMIT_CREATE_USER_GROUP = 'submit_create_user_group',
  SUBMIT_DELETE_USER_GROUP = 'submit_delete_user_group',
  SUBMIT_DISABLE_USER = 'submit_disable_user',
  SUBMIT_EDIT_USER = 'submit_edit_user',
  SUBMIT_EDIT_USER_GROUP = 'submit_edit_user_group',
  SUBMIT_ENABLE_USER = 'submit_enable_user',
  SUBMIT_GENERATE_API_KEY = 'submit_generate_api_key',
  SUBMIT_REGENERATE_API_KEY = 'submit_regenerate_api_key',
  SUBMIT_RENEW_API_KEY = 'submit_renew_api_key',
  SUBMIT_RESEND_INVITATION = 'submit_resend_invitation',
  SUBMIT_RESET_USER_2FA = 'submit_reset_user_2fa',
  SUBMIT_RESET_USER_PASSWORD = 'submit_reset_user_password',
  SUBMIT_REVOKE_API_KEY = 'submit_revoke_api_key',
  SUBMIT_UNLOCK_USER = 'submit_unlock_user',
  SUBMIT_UPLOAD_METADATA = 'submit_upload_metadata',
  TOGGLE_ADD_AUTOPROVISIONING_MODAL = 'toggle_add_autoprovisioning_modal',
  TOGGLE_ADD_USER_MODAL = 'toggle_add_user_modal',
  TOGGLE_CREATE_USER_GROUP_MODAL = 'toggle_create_user_group_modal',
  TOGGLE_DISABLE_USER_MODAL = 'toggle_disable_user_modal',
  TOGGLE_EDIT_USER_GROUP_MODAL = 'toggle_edit_user_group_modal',
  TOGGLE_EDIT_USER_MODAL = 'toggle_edit_user_modal',
  TOGGLE_KEY_REVOKE_CONFIRM_MODAL = 'toggle_key_revoke_confirm_modal',
  TOGGLE_RESET_MFA_MODAL = 'toggle_reset_mfa_modal',
  TOGGLE_RESET_PASSWORD_MODAL = 'toggle_reset_password_modal',
  TOGGLE_UPLOAD_METADATA_MODAL = 'toggle_upload_metadata_modal',
  TOGGLE_USER_ROLE_CHECKBOX = 'toggle_user_role_checkbox',
  USER_LOGGED_OUT = 'user_logged_out',
  VISIT_DOCS_PAGE = 'visit_docs_page',
}
