// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/imgs/icons.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".options-menu-container{position:relative;display:flex;background-color:transparent;width:40px;height:24px;margin-top:16px}.options-menu-container .options-menu-button{position:absolute;width:100%;height:100%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:-10px -320px;background-repeat:no-repeat;background-color:transparent;border:none;padding:0;cursor:default}.options-menu-container .options-menu-button.btn-secondary:not(:disabled):not(.disabled):active{background-color:transparent}.options-menu-container .options-menu-button.btn-secondary:not(:disabled):not(.disabled):active:focus{box-shadow:none}.options-menu-container .options-menu-button:hover,.options-menu-container .options-menu-button:focus,.options-menu-container .options-menu-button:active{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:-10px -360px;background-color:transparent;box-shadow:none;border:none;outline:none;cursor:pointer}.options-menu-container .options-menu{position:absolute;width:200px;height:auto;background-color:#ffffff;box-shadow:0 4px 12px 0 rgba(45,46,46,0.12),0 1px 4px 0 rgba(45,46,46,0.2);margin-top:5px;border:none;outline:none}.options-menu-container .options-menu .option{width:100%;height:38px;background-color:transparent;color:#525252;font-family:Roboto;font-size:14px;border:none;text-align:left;padding:0 16px;overflow:hidden;text-overflow:ellipsis}.options-menu-container .options-menu .option:hover{background-color:#f3f3f4;cursor:pointer}.options-menu-container .options-menu .option:focus{background-color:transparent;border-color:transparent;box-shadow:none;outline:none}.options-menu-container .options-menu .option[disabled]{background-color:transparent;color:#525252;opacity:0.2;cursor:not-allowed}\n", ""]);
// Exports
module.exports = exports;
