import { I18nMessageContent } from '..';

export const enUsMessages: I18nMessageContent = {
  common: {
    footer: `Copyright © ${new Date().getFullYear()} CyberCube. All rights reserved.`,
  },
  apiKeyManagement: {
    apiUserOnly: {
      availableActiveKeysCaption:
        'Keys are used to access the CyberCube APIs. Never share these keys outside of your company team.',
      expiredKeyBanner:
        'Attention: This key has expired and is no longer valid. Contact your administrator to generate a new key.',
      noActiveKeysCaption: 'No API keys have been enabled. Contact your administrator to generate a key.',
      revokedKeyBanner:
        'Attention: This key had been revoked and you have no active keys. Contact your administrator to generate a new key.',
      title: 'API Keys',
    },
    aboutToExpire: 'About to Expire',
    active: 'Active',
    apiKey: 'API Key',
    areYouSure: 'Are you sure?',
    availableActiveKeysCaption:
      'Keys are used to access the CyberCube APIs. Never share these keys outside of your company team. If your key is ever unintentionally exposed, revoke it, then generate a new key.',
    cancel: 'Cancel',
    copyKey: 'Copy Key',
    expirationDate: 'Expiration Date',
    expired: 'Expired',
    expiredKeyBanner:
      'Attention: This key has expired and is no longer valid. Please generate a new key to access the APIs.',
    generateNewKey: 'Generate New Key',
    history: 'History',
    keyStatus: 'Key Status',
    noActiveKeysCaption: 'Generate a new encrypted key to enable access to your APIs.',
    packageKeyword: 'Packages:',
    revoke: 'Revoke',
    revoked: 'Revoked',
    revokeDate: 'Revoke Date',
    revokedKeyBanner: 'Attention: This key had been revoked and you have no active keys.',
    revokeKey: 'Revoke Key',
    revokeKeyMessage: 'Revoking this key means that this key will no longer provide access to your APIs.',
    revokeKeyWarning: 'You are about to revoke an active key.',
    revokeOnlyKeyMessage:
      'After revoking this key, you will need to generate a new key in order to gain access to your APIs.',
    revokeOnlyKeyWarning: 'You are about to revoke your only active key.',
    title: 'API Key Management',
  },
  autoprovisioning: {
    title: 'Auto Provisioning',
  },
  federation: {
    title: 'Federation',
  },
  userAdmin: {
    addButton: 'Add User',
    amUsersLabel: 'AM Users',
    apiUsersLabel: 'api users',
    bmActiveUsersLabel: 'number of active bm users',
    BMAnalysisPurchasedLabel: 'total analyses purchased',
    BMAnalysisRunLabel: 'total analyses run',
    logoutButton: 'Logout',
    pendingInvitesLabel: 'pending invitations',
    pmUsersLabel: 'PM Users',
    title: 'User Administration',
    userGroupsLabel: 'User Groups',
    xmUsersLabel: 'XM Users',
  },
  userModal: {
    addButton: 'Add',
    addUser: 'Add User',
    amUserRoles: 'AM User Role(s)',
    apiUserRoles: 'API User Role(s)',
    bmUserRoles: 'BM User Role(s)',
    cancelButton: 'Cancel',
    confirmTitle: 'Are you sure?',
    createButton: 'Create',
    createUserGroupTitle: 'Create User Group',
    deleteGroupButton: 'Delete Group',
    disableButton: 'Disable',
    disableUserMessage: 'Make sure you really want to disable ',
    disableUserTitle: 'Disable User',
    editUser: 'Edit User',
    editUserGroupTitle: 'Edit User Group',
    emailDomainError: 'Email domain does not match that of tenant.',
    emailFormatError: 'Email format is incorrect.',
    emailLabel: 'Email Address',
    emailPlaceholder: 'Enter email address',
    metadataUpdateConfirm:
      "Please confirm that you would like to proceed with this change. Changing your metadata can cause issues with your SAML provider. Make sure that your changes align exactly with the information found in your access management provider's site. Cancel to review your changes before proceeding.",
    nameLabel: 'Name',
    namePlaceholder: 'Enter full name',
    pmUserRoles: 'PM User Role(s)',
    proceedButton: 'Proceed',
    resetButton: 'Reset',
    resetMfaMessage: 'Make sure you really want to reset 2FA for ',
    resetMfaTitle: 'Reset 2FA',
    resetPasswordMessage: 'Make sure you really want to reset password for ',
    resetPasswordTitle: 'Reset Password',
    saveButton: 'Save',
    selectUsersLabel: 'Select Users',
    selectUsersPlaceholder: 'Select of enter user name',
    updateMetadataTitle: 'Update Metadata',
    uploadMetadataTitle: 'Upload Metadata',
    userGroupNameLabel: 'Group Name',
    userGroupNamePlaceholder: 'Enter group name',
    userInfoTitle: 'User Info',
    userRoleTitle: 'User Role',
    xmUserRoles: 'XM User Role(s)',
  },
  userTable: {
    activeLabel: 'Active',
    addToExistingUserGroupLabel: 'Add to Existing User Group',
    disabledDateHeader: 'Disabled On',
    disabledLabel: 'Disabled',
    disableUserLabel: 'Disable User',
    editUserLabel: 'Edit User',
    enableUserLabel: 'Enable User',
    filterRolesLabel: 'Filter Roles',
    invitePendingLabel: 'Invite Pending',
    lastLoginHeader: 'Last Login',
    lockedLabel: 'Locked',
    noMatchesFoundPartOneLabel: 'No matches found. ',
    noMatchesFoundPartTwoLabel: 'Try broadening your search by de-selecting filters or changing your name search.',
    resendInviteLabel: 'Re-send invitation',
    resetMfaLabel: 'Reset 2FA',
    resetPasswordLabel: 'Reset Password',
    searchNamePlaceholder: 'Search by name',
    unlockUserLabel: 'Unlock User',
    userAnalysisRun: '# of Analyses Run',
    userEmailHeader: 'User Email',
    userGroupsHeader: 'User Groups',
    userNameHeader: 'User Name',
    userRoleHeader: 'User Role',
    userStatusHeader: 'User Status',
  },
  brokerView: {
    downloadAllReport: 'All Analyses',
    downloadAllReportTooltip: 'Download all analysis report results',
  },
};
