/* eslint-disable no-shadow */
import './styles.scss';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Header from './header';

export class Table extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableBodyHeight: 0,
    };

    this._tableBodyRef = React.createRef();
  }

  componentDidMount() {
    const tableBodyRef = this._tableBodyRef.current;

    this.setState({
      tableBodyHeight: tableBodyRef.clientHeight - tableBodyRef.firstChild.clientHeight,
    });
  }

  createArrOfLoadingRows = (numOfRows = 20, show = true) => {
    if (show) {
      return Array.from({ length: numOfRows }, (_, i) => (
        <div className="load-animation-wrapper" key={'row_' + i}>
          {Array.from({ length: this.props.headers.length }, (_, j) => (
            <div className="load-animation" key={'cell_' + j} />
          ))}
        </div>
      ));
    }
  };

  render() {
    return (
      <div className={classnames('table-container', this.props.classes)} ref={this._tableBodyRef}>
        {this.props.loading && <div className="table-headers" />}
        {!this.props.loading && (
          <div className="table-headers">
            {this.props.headers.map((header, idx) => (
              <Header
                key={idx}
                id={header.id}
                customHeader={header.customHeader}
                displayName={header.displayName}
                onSort={this.props.onSort}
                sortBy={this.props.sortBy}
                sortOrder={this.props.sortOrder}
                sortable={header.sortable}
              />
            ))}
          </div>
        )}
        <div className="table-body" style={{ height: `${this.state.tableBodyHeight}px` }}>
          {this.props.loading && this.createArrOfLoadingRows(this.state.tableBodyHeight / 56 + 1)}
          {!this.props.loading && this.props.onRenderTableBody(this.props.data)}
        </div>
      </div>
    );
  }
}

Table.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  data: PropTypes.array,
  headers: PropTypes.array,
  loading: PropTypes.bool,
  onRenderTableBody: PropTypes.func,
  onSort: PropTypes.func,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
};

export default Table;
