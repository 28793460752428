// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/imgs/icons.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".success-banner,.error-banner,.warning-banner,.info-banner{height:50px;padding:14px 24px;margin-left:-24px;margin-right:-24px;margin-bottom:1px}.success-banner:first-child,.error-banner:first-child,.warning-banner:first-child,.info-banner:first-child{margin-top:-24px}.success-banner .icon,.error-banner .icon,.warning-banner .icon,.info-banner .icon{margin-right:10px;width:21px;height:21px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;display:inline-block;vertical-align:top}.success-banner .message,.error-banner .message,.warning-banner .message,.info-banner .message{display:inline-block;margin-top:3px}.success-banner{background-color:#5ba31c;color:#ffffff}.success-banner .icon{background-position:-22px -710px}.error-banner{background-color:#d6291a;color:#ffffff}.error-banner .icon{background-position:-23px -102px}.warning-banner{background-color:#f0ad11;color:#293033}.warning-banner .icon{background-position:-23px -262px}.info-banner{background-color:#2083bd;color:#ffffff}.info-banner .icon{background-position:-23px -149px}\n", ""]);
// Exports
module.exports = exports;
