/* eslint-disable one-var */
import './styles.scss';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

const SideNavItem = ({ classes, hoverIconPosition, iconPosition, isActive, onClick, tabName }) => {
  // Component States
  const [currentIconPosition, setCurrentIconPosition] = useState('');

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(tabName);
    }
  }, [onClick, tabName]);

  const handleMouseEnter = useCallback(() => {
    setCurrentIconPosition(hoverIconPosition);
  }, [hoverIconPosition]);

  const handleMouseLeave = useCallback(() => {
    if (!isActive) {
      setCurrentIconPosition(iconPosition);
    }
  }, [iconPosition, isActive]);

  useEffect(() => {
    setCurrentIconPosition(isActive ? hoverIconPosition : iconPosition);
  }, [hoverIconPosition, iconPosition, isActive]);

  return (
    <div
      className={classnames('side-nav-item', { active: isActive }, classes)}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ backgroundPosition: `${currentIconPosition}` }}
    />
  );
};

SideNavItem.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  hoverIconPosition: PropTypes.string.isRequired,
  iconPosition: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  tabName: PropTypes.string.isRequired,
};

export default SideNavItem;
