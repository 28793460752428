import { Config } from '../application/config';
import SessionStorage from '../utils/storage';
import { doLogout } from '../webAPI/session';
export var idleLogout = function idleLogout() {
  var timeoutId;

  var maxUserInactivityDuration = Config.getUserInactivityTime(),
      handleLogout = function handleLogout() {
    var currentTime = new Date().getTime(),
        timeSinceLastUserActivity = parseInt(SessionStorage.getItem('userLastActivityTime')),
        timeLeftForLogout = maxUserInactivityDuration - (currentTime - timeSinceLastUserActivity > 0 ? currentTime - timeSinceLastUserActivity : 0);

    if (isNaN(timeSinceLastUserActivity)) {
      doLogout();
    } else if (timeLeftForLogout > 0) {
      resetTimer(timeLeftForLogout);
    } else {
      doLogout();
    }
  },
      resetTimer = function resetTimer() {
    var timeLeftForLogout = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : maxUserInactivityDuration;
    clearTimeout(timeoutId);
    SessionStorage.setItem('userLastActivityTime', new Date().getTime());

    if (isNaN(timeLeftForLogout)) {
      timeoutId = setTimeout(handleLogout, maxUserInactivityDuration);
    } else {
      timeoutId = setTimeout(handleLogout, timeLeftForLogout);
    }
  };

  document.onload = resetTimer;
  document.onmousedown = resetTimer;
  document.onkeypress = resetTimer;
  document.addEventListener('scroll', resetTimer, true);
  resetTimer();
};
export default {
  idleLogout: idleLogout
};