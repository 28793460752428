/* eslint-disable one-var */
/* eslint-disable react/jsx-no-bind */
import './styles.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { APPS } from '../../../../constants/appVariables';
import IntlFormatterHOC from '../../../../utils/intlFormatterHOC';
import RolesCheckboxGroupItem from './RolesCheckboxGroupItem';

const RolesCheckboxGroup = ({
  activationData,
  isAmAdmin,
  isApiAdmin,
  isBmAdmin,
  isPmAdmin,
  isXmAdmin,
  onFormatIntl,
  onToggleSelectRole,
  roles,
}) => {
  return (
    <div className="row-element">
      {isAmAdmin && roles[APPS.AM] && (
        <RolesCheckboxGroupItem
          onFormatIntl={onFormatIntl}
          onToggleSelectRole={onToggleSelectRole}
          formatText={'userModal.amUserRoles'}
          roles={Object.entries(roles[APPS.AM])}
          productType={APPS.AM}
          productUsageData={activationData[APPS.AM]}
        />
      )}
      {isPmAdmin && roles[APPS.PM] && (
        <RolesCheckboxGroupItem
          onFormatIntl={onFormatIntl}
          onToggleSelectRole={onToggleSelectRole}
          formatText={'userModal.pmUserRoles'}
          roles={Object.entries(roles[APPS.PM])}
          productType={APPS.PM}
          productUsageData={activationData[APPS.PM]}
        />
      )}
      {isBmAdmin && roles[APPS.BM] && (
        <RolesCheckboxGroupItem
          onFormatIntl={onFormatIntl}
          onToggleSelectRole={onToggleSelectRole}
          formatText={'userModal.bmUserRoles'}
          roles={Object.entries(roles[APPS.BM])}
          productType={APPS.BM}
          productUsageData={activationData[APPS.BM]}
        />
      )}
      {isApiAdmin && roles[APPS.CC] && (
        <RolesCheckboxGroupItem
          onFormatIntl={onFormatIntl}
          onToggleSelectRole={onToggleSelectRole}
          formatText={'userModal.apiUserRoles'}
          roles={Object.entries(roles[APPS.CC])}
          productType={APPS.CC}
          productUsageData={activationData[APPS.CC]}
        />
      )}
      {isXmAdmin && roles[APPS.XM] && (
        <RolesCheckboxGroupItem
          onFormatIntl={onFormatIntl}
          onToggleSelectRole={onToggleSelectRole}
          formatText={'userModal.xmUserRoles'}
          roles={Object.entries(roles[APPS.XM])}
          productType={APPS.XM}
          productUsageData={activationData[APPS.XM]}
        />
      )}
    </div>
  );
};

RolesCheckboxGroup.propTypes = {
  activationData: PropTypes.object,
  isAmAdmin: PropTypes.bool,
  isApiAdmin: PropTypes.bool,
  isBmAdmin: PropTypes.bool,
  isPmAdmin: PropTypes.bool,
  isXmAdmin: PropTypes.bool,
  onFormatIntl: PropTypes.func,
  onToggleSelectRole: PropTypes.func,
  roles: PropTypes.object,
};

export default IntlFormatterHOC(RolesCheckboxGroup);
