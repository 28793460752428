// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/imgs/icons.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".error-boundary{display:flex;width:100%;height:64px;padding:13px 0px;background-color:#d6291a;justify-content:center}.error-boundary .error-boundary-icon{margin-right:8px;margin-top:8px;width:21px;height:21px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:-23px -102px;background-repeat:no-repeat;display:inline-block;vertical-align:top}.error-boundary .error-boundary-text{height:16px;margin:11px 0;font-size:14px;font-weight:500;line-height:normal;letter-spacing:normal;color:#ffffff}.error-boundary .error-boundary-button{height:38px;display:flex;margin:0 84px 0 24px;padding:7px 20px;border-radius:2px;background-color:#ffffff;cursor:pointer}.error-boundary .error-boundary-button:hover{background-color:#f5f5f5}.error-boundary .error-boundary-button:hover .error-boundary-button-icon{background-position:-1242px -896px}.error-boundary .error-boundary-button:hover .error-boundary-button-text{color:#1badef}.error-boundary .error-boundary-button .error-boundary-button-icon{width:21px;height:21px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:-1242px -838px;background-repeat:no-repeat}.error-boundary .error-boundary-button .error-boundary-button-text{width:44px;height:16px;margin:4px 0 4px 12px;font-size:14px;font-weight:500;line-height:normal;letter-spacing:normal;color:#0b74a3}\n", ""]);
// Exports
module.exports = exports;
