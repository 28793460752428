import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

const Header = ({ customHeader, displayName, id, onSort, sortBy, sortOrder, sortable }) => {
  const handleSort = useCallback(() => {
    if (sortable) {
      onSort(id, id === sortBy ? sortOrder : 'dsc');
    }
  }, [id, onSort, sortable, sortBy, sortOrder]);

  return (
    <div onClick={handleSort} className={classnames('table-header', { sortable })}>
      {customHeader || displayName}
      <div className={classnames('sort-icons', { active: id === sortBy }, id === sortBy ? sortOrder : '')}>
        <i className="fas fa-caret-up" />
        <i className="fas fa-caret-down" />
      </div>
    </div>
  );
};

Header.propTypes = {
  customHeader: PropTypes.node,
  displayName: PropTypes.string,
  id: PropTypes.string,
  onSort: PropTypes.func,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  sortable: PropTypes.bool,
};

export default Header;
