import { Telemetry } from 'c2-common-ui';

import { Actions, Applications, Components, Events, Features } from '../types/telemetry';
import { isUrlLocalhost } from './sessionUtils';

/*
 Example of usage:

 1) Passing in the object. Note that it doesn't require sendMetric call.
  telemetryEvent({
    action: telemetryVariables.CLICK,
    component: telemetryVariables.BUTTON,
    feature: telemetryVariables.PORTFOLIOS_PAGE,
    event: telemetryVariables.CLICK_SEARCH_BY_COMPANY_NAME
  });

  2) Chaining function calls.
  Note that each chain returns the telemetryEvent instance so the order doesn't matter, except for sendMetric().

    a) passing variables in chained functions
    telemetryEvent()
      .action(telemetryVariables.CLICK)
      .component(telemetryVariables.BUTTON)
      .feature(telemetryVariables.PORTFOLIOS_PAGE)
      .event(telemetryVariables.CLICK_SEARCH_BY_COMPANY_NAME)
      .sendMetric();

    b) using shorthand methods like click() and button()
    telemetryEvent()
      .click()
      .button()
      .feature(telemetryVariables.PORTFOLIOS_PAGE)
      .event(telemetryVariables.CLICK_SEARCH_BY_COMPANY_NAME)
      .sendMetric();

    c) having event() method called last, if all variables are set, will trigger sendMetrics() automatically
       workes also with modalEvent() and eventWithSuffix()
     telemetryEvent()
      .click()
      .button()
      .feature(telemetryVariables.PORTFOLIOS_PAGE)
      .event(telemetryVariables.CLICK_SEARCH_BY_COMPANY_NAME)

*/

export interface TelemetryProps {
  app?: Applications;
  action?: Actions;
  feature?: Features;
  component?: Components;
  event?: Events | string;
}

export { Features, Applications, Components, Events, Actions };

// function that generates new TelemetryEventService instance
export default function telemetryEvent(props: TelemetryProps = {}) {
  const { app, action, feature, component, event } = props;

  return new TelemetryEventService({
    app: app || Applications.RBAC,
    action: action || Actions.CLICK,
    feature,
    component,
    event,
  });
}

class TelemetryEventService {
  private _app: Applications;
  private _action?: Actions;
  private _feature?: Features;
  private _component?: Components;
  private _event?: Events | string;
  isMetricSent: boolean;

  constructor({ app, action, feature, component, event }: TelemetryProps) {
    this._app = app || Applications.RBAC;
    this._action = action;
    this._feature = feature;
    this._component = component;
    this._event = event;

    this.isMetricSent = false;

    // if all data is provided in constructor, we can sendMetric right away
    if (this.isValid()) {
      this.sendMetric();
    }
  }

  isValid() {
    if (this._app && this._action && this._feature && this._component && this._event) {
      return true;
    }
    return false;
  }

  // just a short name for sendMetric
  send() {
    this.sendMetric();
  }

  sendMetric() {
    if (this.isMetricSent || isUrlLocalhost()) {
      return;
    }
    if (this.isValid()) {
      Telemetry.addUIEventMetric({
        app: this._app,
        action: this._action || '',
        feature: this._feature || '',
        component: this._component || '',
        event: this._event || '',
      });
      this.isMetricSent = true;
    } else {
      // eslint-disable-next-line no-console
      console.error('Telemetry Event failed to log. Missing required params.');
    }
  }

  app(app) {
    this._app = app;
    return this;
  }

  action(action: Actions) {
    this._action = action;
    return this;
  }

  click() {
    return this.action(Actions.CLICK);
  }

  load() {
    return this.action(Actions.LOAD);
  }

  scroll() {
    return this.action(Actions.SCROLL);
  }

  hover() {
    return this.action(Actions.HOVER);
  }

  typing() {
    return this.action(Actions.TYPING);
  }

  returnKey() {
    return this.action(Actions.RETURN_KEY);
  }

  component(component: Components) {
    this._component = component;
    return this;
  }

  button() {
    this.component(Components.BUTTON);
    // if component is a button, it's probably a click action
    if (!this._action) {
      this.click();
    }
    return this;
  }

  link() {
    this.component(Components.LINK);
    // if component is a link, it's probably a click action
    if (!this._action) {
      this.click();
    }
    return this;
  }

  input() {
    return this.component(Components.INPUT);
  }

  page() {
    return this.component(Components.PAGE);
  }

  tooltip() {
    return this.component(Components.TOOLTIP);
  }

  filterTab() {
    return this.component(Components.FILTERTAB);
  }

  pageTab() {
    return this.component(Components.PAGETAB);
  }

  dropdownItem() {
    return this.component(Components.DROPDOWNITEM);
  }

  checkbox() {
    return this.component(Components.CHECKBOX);
  }

  switch() {
    return this.component(Components.SWITCH);
  }

  tagSelector() {
    return this.component(Components.TAG_SELECTOR);
  }

  menuItem() {
    return this.component(Components.MENUITEM);
  }

  table() {
    return this.component(Components.TABLE);
  }

  tableRow() {
    return this.component(Components.TABLEROW);
  }

  tableHeader() {
    return this.component(Components.TABLEHEADER);
  }

  typeahead() {
    return this.component(Components.TYPEAHEAD);
  }

  feature(feature: Features) {
    this._feature = feature;
    return this;
  }

  event(event: Events | string) {
    this._event = event;
    this.sendMetric();
    return this;
  }

  // event that concats 'open' or 'close" (based on the second argument) at the end of the event string
  modalEvent(event: Events, open: boolean) {
    let e = event as string;

    if (typeof open === 'boolean') {
      if (open) {
        e += '_open';
      } else {
        e += '_close';
      }
    }
    return this.event(e);
  }

  // event that concats second argument (lower case) at the end of the event string
  eventWithSuffix(event: Events, suffix: string) {
    let e = event as string;

    if (typeof suffix === 'string') {
      e += '_' + suffix.toLowerCase();
    }
    return this.event(e);
  }
}
