/* eslint-disable no-console */

/* eslint-disable one-var */
import jwt from 'jsonwebtoken';
import { Config } from '../application/config';
import { generateFormBody } from '.';
import SessionStorage from '../utils/storage';
export var getTokenApi = function getTokenApi() {
  if (Config.isDevMode() && Config.getRouteTokensDevMode()) {
    return Config.getRouteTokensDevMode();
  }

  if (!SessionStorage.getItem('userpool_domain')) {
    doLogin();
    throw new Error('Corrupt data. Retry.');
  }

  return "https://".concat(SessionStorage.getItem('userpool_domain'), "/oauth2/token");
};

var isTokenValid = function isTokenValid(token) {
  if (Config.isDevMode()) {
    return true;
  } else if (token) {
    try {
      return jwt.decode(token, {
        complete: true
      }).payload.exp - Math.round(new Date().getTime() / 1000) > 0;
    } catch (error) {
      return false;
    }
  }
};

export var isAccessTokenValid = function isAccessTokenValid() {
  return isTokenValid(SessionStorage.getItem('access_token'));
};
export var isIdTokenValid = function isIdTokenValid() {
  return isTokenValid(SessionStorage.getItem('id_token'));
};
export var isRefreshTokenPresent = function isRefreshTokenPresent() {
  return Boolean(SessionStorage.getItem('refresh_token'));
};
export var setUserInfoFromIdToken = function setUserInfoFromIdToken() {
  var jwtTokenPayload = jwt.decode(SessionStorage.getItem('id_token'), {
    complete: true
  }).payload;
  SessionStorage.setItem('firstName', jwtTokenPayload['given_name']);
  SessionStorage.setItem('lastName', jwtTokenPayload['family_name']);
  SessionStorage.setItem('groups', JSON.stringify(jwtTokenPayload['cognito:groups']));
  SessionStorage.setItem('tenantId', JSON.parse(jwtTokenPayload['custom:tenantinfo']).tenant_id);
  SessionStorage.setItem('tenantName', JSON.parse(jwtTokenPayload['custom:tenantinfo']).tenant_name);

  if (Config.isPostSessionToLoginOnUpdateEnabled()) {
    postSessionToIframe('loginSessionReceiverIframe');
  }
};
export var postSessionToIframe = function postSessionToIframe(receiverFrameId) {
  if (receiverFrameId) {
    var receiver = document.getElementById(receiverFrameId).contentWindow;

    if (receiver) {
      receiver.postMessage(JSON.stringify(sessionStorage), '*');
    }
  }
};

var getTokensUsingCode = function getTokensUsingCode(code) {
  return fetch(getTokenApi(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
    credentials: 'omit',
    body: generateFormBody({
      code: code,
      grant_type: 'authorization_code',
      client_id: SessionStorage.getItem('client_id'),
      redirect_uri: window.location.origin
    })
  }).then(function (res) {
    switch (res.status) {
      case 200:
        return res.json();

      default:
        throw new Error();
    }
  });
};

export var getAndSetTokensUsingCode = function getAndSetTokensUsingCode(code) {
  return new Promise(function (resolve, reject) {
    getTokensUsingCode(code).then(function (res) {
      SessionStorage.setItems(['access_token', 'id_token', 'refresh_token', 'expires_in', 'token_type'], [res.access_token, res.id_token, res.refresh_token, res.expires_in, res.token_type]);

      if (Config.isPostSessionToLoginOnUpdateEnabled()) {
        postSessionToIframe('loginSessionReceiverIframe');
      }

      resolve();
    })["catch"](function () {
      doLogin();
      reject();
    });
  });
};

var getTokensUsingRefreshToken = function getTokensUsingRefreshToken() {
  return fetch(getTokenApi(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
    credentials: 'omit',
    body: generateFormBody({
      grant_type: 'refresh_token',
      client_id: SessionStorage.getItem('client_id'),
      refresh_token: SessionStorage.getItem('refresh_token')
    })
  }).then(function (res) {
    switch (res.status) {
      case 200:
        return res.json();

      default:
        throw new Error();
    }
  });
};

export var getAndSetTokensUsingRefreshToken = function getAndSetTokensUsingRefreshToken() {
  return new Promise(function (resolve, reject) {
    getTokensUsingRefreshToken().then(function (res) {
      SessionStorage.setItems(['access_token', 'id_token', 'expires_in', 'token_type'], [res.access_token, res.id_token, res.expires_in, res.token_type]);

      if (Config.isPostSessionToLoginOnUpdateEnabled()) {
        postSessionToIframe('loginSessionReceiverIframe');
      }

      resolve();
    })["catch"](function () {
      doLogin();
      reject();
    });
  });
};
export var doLogin = function doLogin() {
  if (Config.isDevMode()) {
    window.location.replace(Config.getRouteLoginDevMode() + '?time=' + Date.now());
  } else {
    window.location.replace(Config.getRouteLogin() + '?time=' + Date.now());
  }
};
export var doLogout = function doLogout() {
  sessionStorage.clear();

  if (Config.isDevMode()) {
    window.location.replace(Config.getRouteLogoutDevMode() + '?time=' + Date.now());
  } else {
    window.location.replace(Config.getRouteLogout() + '?time=' + Date.now());
  }
};
export var doError = function doError() {
  if (Config.isDevMode()) {
    window.location.replace(Config.getRouteErrorDevMode() + '?time=' + Date.now());
  } else {
    window.location.replace(Config.getRouteError() + '?time=' + Date.now());
  }
};
export var doUnauthorized = function doUnauthorized() {
  if (Config.isDevMode()) {
    window.location.replace(Config.getRouteUnauthorizedDevMode() + '?time=' + Date.now());
  } else {
    window.location.replace(Config.getRouteUnauthorized() + '?time=' + Date.now());
  }
};
export default {
  doLogin: doLogin,
  doLogout: doLogout,
  doUnauthorized: doUnauthorized,
  doError: doError,
  getAndSetTokensUsingCode: getAndSetTokensUsingCode,
  getAndSetTokensUsingRefreshToken: getAndSetTokensUsingRefreshToken,
  postToIframe: postSessionToIframe,
  isAccessTokenValid: isAccessTokenValid,
  isIdTokenValid: isIdTokenValid,
  isRefreshTokenPresent: isRefreshTokenPresent,
  setUserInfoFromIdToken: setUserInfoFromIdToken
};