import { enUsMessages } from './en-US';

export interface I18nMessageObject {
  [key: string]: string;
}

export interface I18nMessageContent {
  [key: string]: I18nMessageObject | object;
}

export const i18nContent: { [key: string]: I18nMessageContent } = {
  'en-US': enUsMessages,
};
