/* eslint-disable one-var */

import { Fetch } from 'c2-common-ui';

export const deleteUserGroup = userGroupId =>
  Fetch.delete(`admin/oss/users-groups/groups/${encodeURIComponent(userGroupId)}`);
export const deleteUsersFromExistingUserGroup = (userGroupId, payload) =>
  Fetch.delete(`admin/oss/users-groups/groups/${encodeURIComponent(userGroupId)}`, payload);
export const getUserGroupsList = (showMembers, signal) =>
  Fetch.get(`admin/oss/users-groups/groups?show_members=${encodeURIComponent(showMembers)}`, true, signal);
export const getUsersInUserGroup = userGroupId =>
  Fetch.get(`admin/oss/users-groups/groups/${encodeURIComponent(userGroupId)}`);
export const postAddUsersToExistingUserGroup = (userGroupId, payload) =>
  Fetch.post(`admin/oss/users-groups/groups/${encodeURIComponent(userGroupId)}`, payload);
export const postCreateUserGroup = payload => Fetch.post('admin/oss/users-groups/groups', payload);
export const putRenameUserGroup = (userGroupId, payload) =>
  Fetch.put(`admin/oss/users-groups/groups/${encodeURIComponent(userGroupId)}`, payload);
