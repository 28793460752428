/* eslint-disable react/jsx-no-bind */
/* eslint-disable one-var */
import './styles.scss';

import { Button, LoadingSpinnerLg } from 'c2-common-ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { KEY_STATUS_ACTIVE, KEY_STATUS_REVOKED } from '../../constants/appVariables';
import { selectActiveApiKeys, selectExpiredApiKeys, selectPackageNames } from '../../redux/apiKeys/apiKeys.slice';
import { generateApiKey, regenerateApiKey, renewApiKey, revokeApiKey } from '../../redux/apiKeys/apiKeys.thunks';
import telemetryEvent, { Events, Features } from '../../utils/telemetry';
import ApiKeyDisplay from './ApiKeyDisplay';
import KeyRevokeConfirmModal from './KeyRevokeConfirmModal';

const View = ({ isApiUserOnly, loading }) => {
  const dispatch = useDispatch();

  const activeApiKeys = useSelector(selectActiveApiKeys);
  const expiredApiKeys = useSelector(selectExpiredApiKeys);
  const packageNames = useSelector(selectPackageNames);

  // Component States
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState('');

  const handleCopyKey = keyString => {
    const tempElement = document.createElement('input');

    tempElement.style.style = 'position: absolute; left: -1000px; top: -1000px';
    tempElement.value = keyString;

    document.body.appendChild(tempElement);

    tempElement.select();

    document.execCommand('copy');

    document.body.removeChild(tempElement);

    telemetryEvent().button().click().feature(Features.KEYS).event(Events.COPY_API_KEY);
  };

  const handleGenerateNewKey = () => {
    let uiEvent = '';

    if (activeApiKeys.length) {
      const apiKey = activeApiKeys[0];

      if (apiKey.Status === KEY_STATUS_REVOKED) {
        dispatch(regenerateApiKey({ key: apiKey.Key }));

        uiEvent = Events.SUBMIT_REGENERATE_API_KEY;
      } else {
        dispatch(renewApiKey({ key: apiKey.Key }));

        uiEvent = Events.SUBMIT_RENEW_API_KEY;
      }
    } else {
      dispatch(generateApiKey());

      uiEvent = Events.SUBMIT_GENERATE_API_KEY;
    }

    telemetryEvent().button().click().feature(Features.KEYS).event(uiEvent);
  };

  const handleRevokeKey = () => {
    dispatch(revokeApiKey({ key: selectedKey }));

    handleToggleModal();

    telemetryEvent().button().click().feature(Features.KEYS).event(Events.SUBMIT_REVOKE_API_KEY);
  };

  const handleSelectKey = keyString => {
    setSelectedKey(keyString);
  };

  const handleSetWarningBanner = () => {
    if (activeApiKeys.length > 1) {
      return 'apiKeyManagement.revokeKeyWarning';
    }

    return 'apiKeyManagement.revokeOnlyKeyWarning';
  };

  const handleSetWarningMessage = () => {
    if (activeApiKeys.length > 1) {
      return 'apiKeyManagement.revokeKeyMessage';
    }

    return 'apiKeyManagement.revokeOnlyKeyMessage';
  };

  const handleToggleModal = () => {
    setModalOpen(!modalOpen);

    telemetryEvent().button().click().feature(Features.KEYS).event(Events.TOGGLE_KEY_REVOKE_CONFIRM_MODAL);
  };

  const noActiveKey = !activeApiKeys.length || activeApiKeys[0].Status !== KEY_STATUS_ACTIVE;

  return (
    <div className="contents">
      {loading && <LoadingSpinnerLg />}
      {!loading && (
        <div className="wrapper">
          <div className="package-title">
            <FormattedMessage id="apiKeyManagement.packageKeyword" />
          </div>
          <div className="package-names-list">
            {!packageNames.length && <div className="package-name">--</div>}
            {!!packageNames.length &&
              packageNames.map((keyPackage, i) => (
                <div className="package-name" key={i}>
                  {keyPackage}
                </div>
              ))}
          </div>
          {!!packageNames.length && (
            <div className="caption">
              Note: Package names have been reconfigured and you may see a change, there is no change in functionality.
            </div>
          )}
          {isApiUserOnly && (
            <div className="caption">
              {activeApiKeys.length ? (
                <FormattedMessage id="apiKeyManagement.apiUserOnly.availableActiveKeysCaption" />
              ) : (
                <FormattedMessage id="apiKeyManagement.apiUserOnly.noActiveKeysCaption" />
              )}
            </div>
          )}
          {!isApiUserOnly && (
            <div className="caption">
              {activeApiKeys.length ? (
                <FormattedMessage id="apiKeyManagement.availableActiveKeysCaption" />
              ) : (
                <FormattedMessage id="apiKeyManagement.noActiveKeysCaption" />
              )}
            </div>
          )}
          {!isApiUserOnly && noActiveKey && (
            <Button classes={'generate-key-button'} color={'primary'} onClick={handleGenerateNewKey}>
              <FormattedMessage id="apiKeyManagement.generateNewKey" />
            </Button>
          )}
          {!!activeApiKeys.length &&
            activeApiKeys.map((apiKey, i) => (
              <ApiKeyDisplay
                key={i}
                daysTillExpire={apiKey.DaysTillExpire}
                expirationDate={apiKey.Expiration}
                isApiUserOnly={isApiUserOnly}
                keyString={apiKey.Key}
                onCopyKey={handleCopyKey}
                onSelectKey={handleSelectKey}
                onToggleModal={handleToggleModal}
                showBanner={activeApiKeys.length === 1}
                status={apiKey.Status}
              />
            ))}
          {!isApiUserOnly && !!expiredApiKeys.length && (
            <React.Fragment>
              <div className="separator" />
              <div className="history-title">
                <FormattedMessage id="apiKeyManagement.history" />
              </div>
              {expiredApiKeys.map((apiKey, i) => (
                <ApiKeyDisplay
                  key={i}
                  expirationDate={apiKey.Expiration}
                  isApiUserOnly={isApiUserOnly}
                  isHistorical={true}
                  keyString={apiKey.Key}
                  status={apiKey.Status}
                />
              ))}
            </React.Fragment>
          )}
        </div>
      )}
      {modalOpen && (
        <KeyRevokeConfirmModal
          isOpen={modalOpen}
          onRevokeKey={handleRevokeKey}
          onToggleModal={handleToggleModal}
          warningBanner={handleSetWarningBanner()}
          warningMessage={handleSetWarningMessage()}
        />
      )}
    </div>
  );
};

View.propTypes = {
  isApiUserOnly: PropTypes.bool,
  loading: PropTypes.bool,
};

export default View;
