export enum UserRole {
  ROLE_AM_REGULAR_USER = 'AMRegularUser',
  ROLE_AM_ADMIN = 'AMAdmin',
  ROLE_PM_REGULAR_USER = 'PMRegularUser',
  ROLE_PM_ADMIN = 'PMAdmin',
  ROLE_BM_REGULAR_USER = 'BMRegularUser',
  ROLE_BM_ADMIN = 'BMAdmin',
  ROLE_BM_MANAGER = 'BMManager',
  ROLE_API_ADMIN = 'APIAdmin',
  ROLE_API_USER = 'APIUser',
  ROLE_ADMIN = 'Admin',
  ROLE_STAFF_USER = 'Staff',
  ROLE_STAFF_ADMIN = 'StaffAdmin',
  ROLE_STAFF_EDITOR = 'StaffEditor',
  ROLE_FEDERATED_MANAGER = 'FederatedManager',
}

export enum UserRoleName {
  AMRegularUser = 'AM Regular User',
  AMAdmin = 'AM Admin',
  PMRegularUser = 'PM Regular User',
  PMAdmin = 'PM Admin',
  BMRegularUser = 'BM Regular User',
  BMAdmin = 'BM Admin',
  BMManager = 'BM Manager',
  APIAdmin = 'API Admin',
  APIUser = 'API User',
  Admin = 'Admin',
  Staff = 'Staff',
  StaffAdmin = 'Staff Admin',
  StaffEditor = 'Staff Editor',
  FederatedManager = 'Federated Manager',
}
