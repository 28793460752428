import './styles.scss';

import image from '../../../assets/imgs/CyberCube_logo.svg';

const SideNavBrand = () => (
  <div
    className="side-nav-brand"
    style={{
      backgroundImage: `url(${image})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '60%',
    }}
  />
);

export default SideNavBrand;
