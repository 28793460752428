/* eslint-disable one-var */
import { Config } from '../application/config';
import Fetch from '../webAPI';
import SessionStorage from '../utils/storage';
import { isTestMode } from '.';

var handlePostDataToTimestream = function handlePostDataToTimestream(dimensions, measure) {
  var _Config$getTelemetry = Config.getTelemetry(),
      apiKey = _Config$getTelemetry.apiKey,
      appSyncUrl = _Config$getTelemetry.appSyncUrl,
      dbName = _Config$getTelemetry.dbName,
      tableName = _Config$getTelemetry.tableName,
      customHeader = {
    'x-api-key': apiKey
  },
      defaultDimensions = "{\n      Name: \"userId\"\n      Value: \"".concat(SessionStorage.getItem('username'), "\"\n    } {\n      Name: \"email\"\n      Value: \"").concat(SessionStorage.getItem('email'), "\"\n    } {\n      Name: \"tenantName\"\n      Value: \"").concat(SessionStorage.getItem('tenantName'), "\"\n    } {\n      Name: \"tenantId\"\n      Value: \"").concat(SessionStorage.getItem('tenantId'), "\"\n    } {\n      Name: \"poolId\"\n      Value: \"").concat(SessionStorage.getItem('userpool_id'), "\"\n    } {\n      Name: \"sessionId\"\n      Value: \"").concat(SessionStorage.getItem('telemetry_uuid'), "\"\n    }"),
      query = "mutation {\n      writeTimestream(input: {\n        dimensions: [".concat(defaultDimensions, " ").concat(dimensions, "]\n        measure: ").concat(measure, "\n        timestamp: ").concat(Date.now(), "\n        namespace: \"").concat(dbName, "_").concat(tableName, "\"\n      }) {\n        status\n      }\n    }");
  /* When telemetry is not defined, and we are in a local or dev server, we want to throw an error.
  But, if we are not on a dev or local environment, we want to not fetch.  This is because
  the fetch on line 59 will fail.
  */


  if (!appSyncUrl || !apiKey) {
    if (!Config.getTelemetry().disabled && isTestMode()) {
      // eslint-disable-next-line no-console
      console.log('Telemetry is not defined.');
      Config.disableTelemetry(true);
    }

    return;
  }

  return Fetch.defaultCustomHeader(appSyncUrl, 'POST', false, customHeader, {
    query: query
  });
};

export var addCmsTimeEventMetric = function addCmsTimeEventMetric(metrics) {
  var dimensions = "{\n      Name: \"app\"\n      Value: \"CMS\"\n    } {\n      Name: \"page\"\n      Value: \"".concat(metrics.page || null, "\"\n    } {\n      Name: \"category\"\n      Value: \"").concat(metrics.category || null, "\"\n    } {\n      Name: \"article\"\n      Value: \"").concat(metrics.article || null, "\"\n    } {\n      Name: \"timeOpened\"\n      Value: \"").concat(metrics.timeOpened || null, "\"\n    } {\n      Name: \"timeSpent\"\n      Value: \"").concat(metrics.timeSpent || null, "\"\n    }"),
      measure = "{\n      MeasureName: \"timeEvent\"\n      MeasureValue: \"".concat(metrics.event, "\"\n      MeasureValueType: VARCHAR\n    }");
  return handlePostDataToTimestream(dimensions, measure);
};
export var addUIEventMetric = function addUIEventMetric(metrics) {
  var dimensions = "{\n      Name: \"app\"\n      Value: \"".concat(metrics.app || null, "\"\n    } {\n      Name: \"feature\"\n      Value: \"").concat(metrics.feature || null, "\"\n    } {\n      Name: \"component\"\n      Value: \"").concat(metrics.component || null, "\"\n    } {\n      Name: \"action\"\n      Value: \"").concat(metrics.action || null, "\"\n    }"),
      measure = "{\n      MeasureName: \"uiEvent\"\n      MeasureValue: \"".concat(metrics.event, "\"\n      MeasureValueType: VARCHAR\n    }");

  try {
    return handlePostDataToTimestream(dimensions, measure);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Telemetry service error.');
  }
};
export default {
  addCmsTimeEventMetric: addCmsTimeEventMetric,
  addUIEventMetric: addUIEventMetric
};