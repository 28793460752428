import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl, IntlShape } from 'react-intl';

const IntlFormatterHOC = ComponentToWrap => {
  class HOCWrapper extends Component {
    constructor(props) {
      super(props);

      this.state = {};
    }

    handleFormatIntl = id => {
      const { intl } = this.props;

      return intl.formatMessage({ id: id });
    };

    render() {
      return (
        <React.Fragment>
          <ComponentToWrap {...this.props} onFormatIntl={this.handleFormatIntl} />
        </React.Fragment>
      );
    }
  }

  HOCWrapper.propTypes = {
    intl: PropTypes.any.isRequired,
  };

  return injectIntl(HOCWrapper);
};

export default IntlFormatterHOC;
