import { createSlice } from '@reduxjs/toolkit';
import { Utils } from 'c2-common-ui';

const initialState = {
  notifications: {},
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    addNotification: (state, action) => {
      const newNotifications = Utils.deepClone(state.notifications);
      const keys = Object.keys(newNotifications);
      const newNotification = action.payload;

      if (keys.length === 0) {
        newNotifications[0] = newNotification;
      } else {
        newNotifications[parseInt(keys[keys.length - 1]) + 1] = newNotification;
      }

      state.notifications = newNotifications;
    },
    clearNotifications: state => {
      state.notifications = {};
    },
    deleteNotification: (state, action) => {
      delete state.notifications[action.payload];
    },
  },
});

// Action creators are generated for each case reducer function
export const { addNotification, clearNotifications, deleteNotification } = notificationsSlice.actions;

export const selectNotifications = state => state.notifications.notifications;

export default notificationsSlice.reducer;
