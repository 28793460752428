/* eslint-disable react/jsx-no-bind */
import './styles.scss';

import * as Sentry from '@sentry/react';
import { Footer, Header } from 'c2-common-ui';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectCreateUserLoading,
  selectEditUserLoading,
  selectFetchTenantInfoLoading,
  selectFetchUsersLoading,
} from '../../redux/userAdmin/userAdmin.slice';
import { fetchUsers } from '../../redux/userAdmin/userAdmin.thunks';
import IntlFormatterHOC from '../../utils/intlFormatterHOC';
import ErrorFallback from '../common/ErrorFallback';
import View from './view';

const UserAdmin = ({ onFormatIntl }) => {
  const dispatch = useDispatch();

  const _controller = useMemo(() => new AbortController(), []);
  const _signal = _controller.signal;

  const isCreateUserLoading = useSelector(selectCreateUserLoading);
  const isEditUserLoading = useSelector(selectEditUserLoading);
  const isFetchUsersLoading = useSelector(selectFetchUsersLoading);
  const isFetchTenantInfoLoading = useSelector(selectFetchTenantInfoLoading);

  useEffect(() => {
    dispatch(fetchUsers({ signal: _signal }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = isCreateUserLoading || isEditUserLoading || isFetchUsersLoading || isFetchTenantInfoLoading;

  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      <div className="main-view user-admin">
        <Header label={onFormatIntl('userAdmin.title')} />
        <View loading={isLoading} onFormatIntl={onFormatIntl} />
        <Footer label={onFormatIntl('common.footer')} />
      </div>
    </Sentry.ErrorBoundary>
  );
};

UserAdmin.propTypes = {
  onFormatIntl: PropTypes.func,
};

export default IntlFormatterHOC(UserAdmin);
