import { configureStore } from '@reduxjs/toolkit';
// FOR DEV USE ONLY, uncomment below for testing:
// import thunk from 'redux-thunk';
// import logger from 'redux-logger';
import * as Sentry from '@sentry/react';

import apiKeysReducer from '../redux/apiKeys/apiKeys.slice';
import federationReducer from '../redux/federation/federation.slice';
import notificationsReducer from '../redux/notifications/notifications.slice';
import sessionUserReducer from '../redux/sessionUser/sessionUser.slice';
import userAdminReducer from '../redux/userAdmin/userAdmin.slice';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export default configureStore({
  reducer: {
    apiKeys: apiKeysReducer,
    federation: federationReducer,
    notifications: notificationsReducer,
    sessionUser: sessionUserReducer,
    userAdmin: userAdminReducer,
  },
  // FOR DEV USE ONLY, uncomment below for testing:
  // middleware: [thunk, logger],
  enhancers: [sentryReduxEnhancer],
});
