/* eslint-disable one-var */
export var getDisplayName = function getDisplayName(Component) {
  return Component ? Component.displayName || Component.name : 'Component';
};
export var createHocDisplayName = function createHocDisplayName(parentDisplayName, WrappedComponent) {
  return "".concat(parentDisplayName, "(").concat(getDisplayName(WrappedComponent), ")");
};
export default {
  getDisplayName: getDisplayName,
  createHocDisplayName: createHocDisplayName
};