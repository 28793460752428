// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/imgs/icons.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".selected-filters-container{display:block;width:100%}.selected-filters-container .clear-button{display:block;width:65px;height:26px;color:#0b74a3;font-family:Roboto;font-size:14px;line-height:26px;text-align:center;float:right;cursor:pointer}.selected-filters-container .clear-button:hover{background-color:rgba(0,0,0,0.04);color:#1badef}.selected-filters-container .selected-filters-list{display:block}.selected-filters-container .selected-filters-list .element{position:relative;display:inline-flex;align-items:center;max-width:160px;color:#525252;font-family:Roboto;font-size:13px;line-height:1.85;margin-right:12px;cursor:pointer}.selected-filters-container .selected-filters-list .element:first-child{padding-left:0px}.selected-filters-container .selected-filters-list .element:not(:last-child):after{content:\"\";position:absolute;display:block;top:25%;right:0%;height:50%;border-right:1px solid #9b9b9b;z-index:1}.selected-filters-container .selected-filters-list .element:hover .icon{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:-26px -455px}.selected-filters-container .selected-filters-list .element .text{max-width:130px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.selected-filters-container .selected-filters-list .element .icon{width:10px;height:10px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:-26px -415px;background-repeat:no-repeat;margin-left:8px;margin-right:12px;float:right}\n", ""]);
// Exports
module.exports = exports;
