import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { DEFAULT_ERROR_MESSAGE, ERROR } from '../../constants/appVariables';
import { listAllowedRoles } from '../../graphql/queries/listAllowedRoles';
import { lmGraphQL } from '../../webAPIs/licenseManager';
import { addNotification } from '../notifications/notifications.slice';
import { mapAllowedRoles } from './sessionUser.helpers';
import { setAllowedRoles } from './sessionUser.slice';

export const getAllowedRoles = createAsyncThunk('sessionUser/getAllowedRoles', async (_params, thunkAPI) => {
  try {
    const res = await lmGraphQL({
      query: listAllowedRoles(thunkAPI.getState().sessionUser.id),
    });

    const allowedRoles = mapAllowedRoles(res.data.listAllowedRoles);

    thunkAPI.dispatch(setAllowedRoles(allowedRoles));
  } catch (err) {
    Sentry.captureException(err);

    thunkAPI.dispatch(addNotification({ type: ERROR, message: DEFAULT_ERROR_MESSAGE }));
  }
});
