/* eslint-disable one-var */
import { InputCheckbox, TooltipNoHandler } from 'c2-common-ui';
import PropTypes from 'prop-types';
import React from 'react';

import { APPS } from '../../../../constants/appVariables';

const RolesCheckboxGroupItem = ({
  onFormatIntl,
  formatText,
  roles,
  productType,
  onToggleSelectRole,
  productUsageData,
}) => {
  const reversedRoles = roles.reverse();
  const [rolesOnLoad] = React.useState([...reversedRoles]);

  if (!productUsageData) {
    return null;
  }

  return (
    <div className="input-checkbox-group">
      <div className="row-title">{onFormatIntl(formatText)}</div>
      {reversedRoles.map(([roleKey, roleDetails], i) => {
        const isRoleConfigurationExisting = !!productUsageData[roleKey];

        // Calculations for Activations and Licenses, when it makes sense
        const productActivations = isRoleConfigurationExisting ? productUsageData[roleKey].activations : 0;
        const productLicenses = isRoleConfigurationExisting ? productUsageData[roleKey].licenses : 0;
        const isTenantProductMaxed = productActivations >= productLicenses;

        // Initial roles because roles itself controls all the isSelected state and there is no other way to recover initial state
        const initialRoleData = rolesOnLoad[i];
        const isRoleInitiallySelected =
          initialRoleData.length > 1 && initialRoleData[0] === roleKey ? initialRoleData[1]?.isSelected : false;

        // Admin Tooltip
        const productAdminKey = productType === APPS.CC ? 'APIAdmin' : `${productType}Admin`;
        const isCurrentRoleAdmin = roleKey === productAdminKey;
        const shouldShowAdminTooltip = isCurrentRoleAdmin && roleDetails.description;
        const adminToolTipText = roleDetails.description;

        // Maxed Product Tooltip
        const shouldShowMaxedProductTooltip =
          !isCurrentRoleAdmin && isRoleConfigurationExisting && isTenantProductMaxed;
        const remainingLicenses = productLicenses - productActivations;
        const maxedProductTooltipText = `${remainingLicenses} remaining of ${productLicenses} allowed.`;

        // Role Checkbox
        const isCheckboxDisabled =
          !isCurrentRoleAdmin && isRoleConfigurationExisting && isTenantProductMaxed && !isRoleInitiallySelected;

        return (
          <div key={`${roleKey}_${i}`}>
            <InputCheckbox
              name={roleDetails.name}
              checked={roleDetails.isSelected}
              onChange={onToggleSelectRole(`${productType}_${roleKey}`)}
              disabled={isCheckboxDisabled}
            />
            {shouldShowAdminTooltip && (
              <React.Fragment>
                <span className="info-icon" />
                <TooltipNoHandler infoArr={[adminToolTipText]} showOnClick={false} />
              </React.Fragment>
            )}
            {shouldShowMaxedProductTooltip && (
              <React.Fragment>
                <span className="info-icon" />
                <TooltipNoHandler infoArr={[maxedProductTooltipText]} showOnClick={false} />
              </React.Fragment>
            )}
          </div>
        );
      })}
    </div>
  );
};

RolesCheckboxGroupItem.propTypes = {
  formatText: PropTypes.string,
  onFormatIntl: PropTypes.func,
  onToggleSelectRole: PropTypes.func,
  productType: PropTypes.string,
  productUsageData: PropTypes.object,
  roles: PropTypes.array,
};

export default React.memo(RolesCheckboxGroupItem);
