import { Session, SessionStorage } from 'c2-common-ui';

import { apiPrefixEnv, urlAppsEnv, urlLoginEnv } from '../constants/environmentVariables';
import { AppConfig } from '../types/apps';
import { UserRole } from '../types/userRoles';

export const getSessionIdToken = () => {
  return SessionStorage.getItem('id_token');
};

export interface AppConfigObj {
  apiPrefix: string;
  urlApps: string;
  urlLogin: string;
  telemetryApiKey: string;
  telemetryUrl: string;
  telemetryDbName: string;
  SentryApiDsn: string;
  SentryTracesSampleRate: number;
  environment: string;
}

export const setInitialAppConfig = (appConfigObj: AppConfigObj) => {
  SessionStorage.setItem('appConfig.apiPrefix', apiPrefixEnv || appConfigObj.apiPrefix);
  SessionStorage.setItem('appConfig.urlApps', urlAppsEnv || appConfigObj.urlApps);
  SessionStorage.setItem('appConfig.urlLogin', urlLoginEnv || appConfigObj.urlLogin);
  SessionStorage.setItem('appConfig.redirect_uri', encodeURIComponent(window.location.href));
};

export const setSessionDevMode = () => {
  localStorage.setItem('devModeCyberCube', 'true');
};

export const getAppsFromSession = () => {
  const apps = SessionStorage.getItem('apps');

  if (!!apps && Array.isArray(apps)) {
    return JSON.parse(SessionStorage.getItem('apps')) as AppConfig[];
  }
};

export const getAppConfigUrlAppsFromSession = () => {
  return SessionStorage.getItem('appConfig.urlApps');
};

export const setAppConfigUrlAppsFromSession = (appConfigs: AppConfig[]) => {
  SessionStorage.setItem('apps', JSON.stringify(appConfigs));
};

export const isUrlLocalhost = () => {
  return localStorage.getItem('devModeCyberCube') || window.location.href.toString().indexOf('localhost') !== -1;
};

export const sessionLogout = () => {
  Session.doLogout();
};

export const setEnvToSession = (newEnv: string) => SessionStorage.setItem('env', newEnv);
export const getEnvFromSession = () => SessionStorage.getItem('env');
export const getEmailFromSession = () => SessionStorage.getItem('email');
export const getIdFromSession = () => SessionStorage.getItem('username');
export const getFullNameFromSession = () => SessionStorage.getItem('fullName');

export const getRolesFromSession = () => JSON.parse(SessionStorage.getItem('rolesWithProducts')) as UserRole[];

export const setIsApiUserOnlyToSession = (value: boolean) => {
  SessionStorage.setItem('isApiUserOnly', value);
};
