import React from 'react';
import { useSelector } from 'react-redux';

import {
  selectIsAmAdmin,
  selectIsApiAdmin,
  selectIsBmAdmin,
  selectIsPmAdmin,
} from '../../../redux/sessionUser/sessionUser.slice';
import ViewAll from './viewAll';
import ViewDefault from './viewDefault';

const TextBanner = ({ ...props }) => {
  const isAmAdmin = useSelector(selectIsAmAdmin);
  const isApiAdmin = useSelector(selectIsApiAdmin);
  const isBmAdmin = useSelector(selectIsBmAdmin);
  const isPmAdmin = useSelector(selectIsPmAdmin);

  const View =
    (isAmAdmin && isApiAdmin && isBmAdmin && isPmAdmin) ||
    (isAmAdmin && !isApiAdmin && isBmAdmin && isPmAdmin) ||
    ((isAmAdmin || isPmAdmin) && isApiAdmin && isBmAdmin) ||
    (isAmAdmin && !isApiAdmin && isBmAdmin && !isPmAdmin) ||
    (!isAmAdmin && !isApiAdmin && isBmAdmin && isPmAdmin)
      ? ViewAll
      : ViewDefault;

  return <View {...props} />;
};

export default TextBanner;
