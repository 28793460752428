import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { DEFAULT_ERROR_MESSAGE, ERROR, SUCCESS } from '../../constants/appVariables';
import { getFederationIdpInfo, putCreateFederationIdp, putSetFederationRoleMapping } from '../../webAPIs/federation';
import { addNotification } from '../notifications/notifications.slice';
import { setEntityId, setMetadataUrl, setOrganizationName, setRoleToGroupMapping, setSsoUrl } from './federation.slice';

export const createFedIdp = createAsyncThunk('userAdmin/createFedIdp', async ({ metadata }, thunkAPI) => {
  try {
    const res = await putCreateFederationIdp(metadata);

    if (res.status === 'success') {
      thunkAPI.dispatch(
        addNotification({
          type: SUCCESS,
          message: 'Metadata has been successfully uploaded.',
        })
      );
    } else if (res.status === 'error') {
      thunkAPI.dispatch(addNotification({ type: ERROR, message: DEFAULT_ERROR_MESSAGE }));
    }

    await thunkAPI.dispatch(fetchFedIdpInfo({}));
  } catch (err) {
    Sentry.captureException(err);

    thunkAPI.dispatch(addNotification({ type: ERROR, message: DEFAULT_ERROR_MESSAGE }));
  }
});

export const fetchFedIdpInfo = createAsyncThunk('userAdmin/fetchFedIdpInfo', async ({ signal = null }, thunkAPI) => {
  try {
    const res = await getFederationIdpInfo(signal);

    if (res.status === 'success') {
      thunkAPI.dispatch(setEntityId(res.data.Audience));
      thunkAPI.dispatch(setMetadataUrl(res.data.metadataURL));
      thunkAPI.dispatch(setOrganizationName(res.data.Organization));
      thunkAPI.dispatch(setSsoUrl(res.data.SSOURL));

      const mapping = [];

      Object.entries(res.data.RoleToGroupMapping).forEach(([group, roleMapping]) => {
        if (Array.isArray(roleMapping)) {
          roleMapping.forEach(role => {
            mapping.push({
              group,
              role,
            });
          });
        } else {
          mapping.push({
            group,
            role: roleMapping,
          });
        }
      });

      thunkAPI.dispatch(setRoleToGroupMapping(mapping));
    } else if (res.status === 'error') {
      thunkAPI.dispatch(
        addNotification({
          type: 'error',
          message: 'Unable to retrieve data.',
        })
      );
    }
  } catch (err) {
    Sentry.captureException(err);

    thunkAPI.dispatch(addNotification({ type: ERROR, message: DEFAULT_ERROR_MESSAGE }));
  }
});

export const setFedRoleMapping = createAsyncThunk('userAdmin/setFedRoleMapping', async ({ mapping }, thunkAPI) => {
  try {
    const res = await putSetFederationRoleMapping(mapping);

    if (res.status === 'success') {
      thunkAPI.dispatch(
        addNotification({
          type: SUCCESS,
          message: 'Group Mappings has been successfully set.',
        })
      );

      // TODO PS2-3891 isAuthRequired null if this parameter is null or undefined. Looks like strange behavior on the side of c2-common.
      // TODO Apparently fixed in version 2.0.8. It will be possible to remove the parameter after updating c2-common
      await thunkAPI.dispatch(fetchFedIdpInfo({ signal: true }));
    }
  } catch (err) {
    Sentry.captureException(err);

    thunkAPI.dispatch(addNotification({ type: ERROR, message: DEFAULT_ERROR_MESSAGE }));
  }
});
