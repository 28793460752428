/* eslint-disable react/jsx-no-bind */
/* eslint-disable one-var */
import './styles.scss';

import * as Sentry from '@sentry/react';
import { Button, Footer, Header, LoadingSpinnerLg, SessionStorage } from 'c2-common-ui';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ROLE_ADMIN } from '../../constants/appVariables';
import { selectFetchFedIdpInfoLoading, selectRoleToGroupMapping } from '../../redux/federation/federation.slice';
import { fetchFedIdpInfo, setFedRoleMapping } from '../../redux/federation/federation.thunks';
import {
  clearNotifications,
  deleteNotification,
  selectNotifications,
} from '../../redux/notifications/notifications.slice';
import { selectAllowedRoles } from '../../redux/sessionUser/sessionUser.slice';
import IntlFormatterHOC from '../../utils/intlFormatterHOC';
import telemetryEvent, { Events, Features } from '../../utils/telemetry';
import ErrorFallback from '../common/ErrorFallback';
import AddAutoprovisioningModal from './AddAutoprovisioningModal';

const Autoprovisioning = ({ onFormatIntl }) => {
  const dispatch = useDispatch();

  const _controller = useMemo(() => new AbortController(), []);
  const _signal = _controller.signal;

  const allowedRoles = useSelector(selectAllowedRoles);
  const isFetchFedIdpInfoLoading = useSelector(selectFetchFedIdpInfoLoading);
  const roleToGroupMapping = useSelector(selectRoleToGroupMapping);

  // Component States
  const [modalOpen, setModalOpen] = useState(false);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const roles = Object.keys(allowedRoles).map((role, i) => ({
      id: i,
      displayName: allowedRoles[role].name,
      value: role,
    }));

    if (JSON.parse(SessionStorage.getItem('rolesWithProducts')).includes(ROLE_ADMIN)) {
      roles.push({
        id: roles.length,
        displayName: 'Super Admin',
        value: ROLE_ADMIN,
      });
    }

    dispatch(fetchFedIdpInfo({ signal: _signal }));

    setRoles(roles);

    return () => {
      if (_controller) {
        _controller.abort();
      }

      dispatch(clearNotifications());
    };
  }, [allowedRoles, dispatch, _controller, _signal]);

  const handleSetRoleMapping = roleMapping => {
    dispatch(setFedRoleMapping({ mapping: roleMapping }));

    telemetryEvent().button().click().feature(Features.AUTOPROVISIONING).event(Events.SUBMIT_AUTOPROVISIONING_GROUP);
  };

  const handleToggleModal = () => {
    setModalOpen(!modalOpen);

    telemetryEvent()
      .button()
      .click()
      .feature(Features.AUTOPROVISIONING)
      .event(Events.TOGGLE_ADD_AUTOPROVISIONING_MODAL);
  };

  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      <div className="main-view autoprovisioning">
        <Header classes={'main-header'} label={onFormatIntl('autoprovisioning.title')} />
        <div className="contents">
          {isFetchFedIdpInfoLoading && <LoadingSpinnerLg />}
          {!isFetchFedIdpInfoLoading && (
            <div className="wrapper">
              <div className="caption">
                Automated provisioning uses SAML settings to automatically assign roles and permissions to users of the
                CyberCube platform by group. You can see current
                <br />
                automated mappings of the groups to roles below. Click the button below to add to or edit these groups.
                <br />
                <br />
                You can set up group names in your IdP, then copy them into the appropriate field. Finally, choose the
                role you wish to automatically assign to it.
                <br />
                <br />
                You can set up your provisioning details (including group names) to the CyberCube platform from your IdP
                using our <a href="#/federation">Federation tool</a>.
              </div>
              <Button classes={'add-groups-button'} color={'primary'} onClick={handleToggleModal}>
                {roleToGroupMapping.length > 0 ? 'Edit Groups' : 'Add Groups'}
              </Button>
              {roleToGroupMapping.length === 0 && <div className="no-group">No Groups Added.</div>}
              {roleToGroupMapping.length > 0 &&
                roleToGroupMapping.map(({ group, role }, i) => (
                  <div className="section" key={i}>
                    <div>{group}</div>
                    <span className="arrow-icon" />
                    <div>{allowedRoles[role] ? allowedRoles[role].name : role}</div>
                  </div>
                ))}
            </div>
          )}
        </div>
        <Footer label={onFormatIntl('common.footer')} />
        {modalOpen && (
          <AddAutoprovisioningModal
            isOpen={modalOpen}
            onSubmit={handleSetRoleMapping}
            onToggleModal={handleToggleModal}
            roleToGroupMapping={roleToGroupMapping}
            roles={roles}
          />
        )}
      </div>
    </Sentry.ErrorBoundary>
  );
};

Autoprovisioning.propTypes = {
  onFormatIntl: PropTypes.func,
};

export default IntlFormatterHOC(Autoprovisioning);
