/* eslint-disable react/jsx-no-bind */
/* eslint-disable one-var */
import './styles.scss';

import React from 'react';
import { FormattedMessage } from 'react-intl';

const ErrorTableRow = () => (
  <div className="error-table-row">
    <div className="error-message">
      <span className="bold-text">
        <FormattedMessage id="userTable.noMatchesFoundPartOneLabel" />
      </span>
      &nbsp;
      <FormattedMessage id="userTable.noMatchesFoundPartTwoLabel" />
    </div>
  </div>
);

ErrorTableRow.propTypes = {};

export default ErrorTableRow;
