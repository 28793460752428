import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

export interface ButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  automationId?: string;
  classes?: string | string[];
  locked?: boolean;
}

const Button: React.FC<ButtonProps> = ({ automationId, children, classes, color, disabled=false, locked=false, onClick }) => {
  if (disabled) {
    return (
      <button
        className={classnames('button', color, classes)}
        data-automation-id={automationId}
        disabled={disabled}
      >
        {children}
      </button>
    );
  } else if (locked) {
    return (
      <button
        className={classnames('button enabled locked', color, classes)}
        data-automation-id={automationId}
      >
        <i className="fas fa-circle-notch fa-spin" />
      </button>
    );
  }

  return (
    <button
      className={classnames('button enabled', color, classes)}
      data-automation-id={automationId}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  automationId: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]),
  color: PropTypes.string,
  disabled: PropTypes.bool,
  locked: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

export default Button;
