import './index.scss';

import { Application } from 'c2-common-ui';
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './components/App/App';
import { hasAdminPermission, shouldLogin, startApp } from './index.helpers';

const container = document.getElementById('shellContainer');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

Application.Bootstrap.initSessionHandler();

setTimeout(async () => {
  await startApp();

  if (shouldLogin()) {
    window.location.href = '/staticViews/login.htm';
  } else if (!hasAdminPermission()) {
    window.location.href = '/staticViews/unauthorized.htm';
  } else {
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  }
}, 250);
