import './styles.scss';

import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';

// API of react-tooltip https://react-tooltip.com/docs/examples/basic-examples#using-anchor-element-id

const Tooltip = ({ clickable = false, effect = 'solid', place = 'bottom', type = 'dark', ...props }) => {
  return (
    <ReactTooltip
      className="tooltip-wrapper"
      clickable={clickable}
      effect={effect}
      place={place}
      type={type}
      {...props}
    />
  );
};

Tooltip.propTypes = {
  clickable: PropTypes.bool,
  effect: PropTypes.string,
  place: PropTypes.string,
  type: PropTypes.string,
};

export default Tooltip;
