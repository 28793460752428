import './styles.scss';

import { Button, ModalSm } from 'c2-common-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import IntlFormatterHOC from '../../../utils/intlFormatterHOC';

const KeyRevokeConfirmModal = ({ isOpen, onFormatIntl, onRevokeKey, onToggleModal, warningBanner, warningMessage }) => (
  <ModalSm
    classes={'revoke-confirmation-modal'}
    onHandleClose={onToggleModal}
    open={isOpen}
    title={onFormatIntl('apiKeyManagement.areYouSure')}
  >
    <div className="modal-subheader">
      <span className="warning-icon" />
      <FormattedMessage id={warningBanner} />
    </div>
    <div className="modal-body">
      <div className="message">
        <FormattedMessage id={warningMessage} />
      </div>
    </div>
    <div className="modal-footer">
      <Button color={'secondary'} onClick={onToggleModal}>
        <FormattedMessage id="apiKeyManagement.cancel" />
      </Button>
      <Button color={'primary'} onClick={onRevokeKey}>
        <FormattedMessage id="apiKeyManagement.revoke" />
      </Button>
    </div>
  </ModalSm>
);

KeyRevokeConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  onFormatIntl: PropTypes.func,
  onRevokeKey: PropTypes.func,
  onToggleModal: PropTypes.func,
  warningBanner: PropTypes.string,
  warningMessage: PropTypes.string,
};

export default IntlFormatterHOC(KeyRevokeConfirmModal);
