import { createSlice } from '@reduxjs/toolkit';

import { fetchApiKeys } from './apiKeys.thunks';

const initialState = {
  activeApiKeys: [],
  expiredApiKeys: [],
  packageNames: [],

  // Loading States
  fetchApiKeysLoading: false,
};

export const apiKeysSlice = createSlice({
  name: 'apiKeys',
  initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    setActiveApiKeys: (state, action) => {
      state.activeApiKeys = action.payload;
    },
    setExpiredApiKeys: (state, action) => {
      state.expiredApiKeys = action.payload;
    },
    setPackageNames: (state, action) => {
      state.packageNames = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchApiKeys.pending, state => {
        state.fetchApiKeysLoading = true;
      })
      .addCase(fetchApiKeys.fulfilled, state => {
        state.fetchApiKeysLoading = false;
      });
  },
});

// Action creators are generated for each case reducer function
export const { setActiveApiKeys, setExpiredApiKeys, setPackageNames } = apiKeysSlice.actions;

export const selectActiveApiKeys = state => state.apiKeys.activeApiKeys;
export const selectExpiredApiKeys = state => state.apiKeys.expiredApiKeys;
export const selectFetchApiKeysLoading = state => state.apiKeys.fetchApiKeysLoading;
export const selectPackageNames = state => state.apiKeys.packageNames;

export default apiKeysSlice.reducer;
