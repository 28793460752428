/* eslint-disable react/jsx-no-bind */
/* eslint-disable one-var */
import './styles.scss';

import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DISABLED, LOCKED, PENDING } from '../../../../constants/appVariables';
import {
  selectEmail,
  selectIsBmAdmin,
  selectIsFederated,
  selectIsFederatedAuthOnly,
  selectIsPmAdmin,
  selectIsXmAdmin,
} from '../../../../redux/sessionUser/sessionUser.slice';
import { addLoadingIndicator } from '../../../../redux/userAdmin/userAdmin.slice';
import { enableUser, resendInviteLink, unlockUser } from '../../../../redux/userAdmin/userAdmin.thunks';
import IntlFormatterHOC from '../../../../utils/intlFormatterHOC';
import telemetryEvent, { Events, Features } from '../../../../utils/telemetry';
import TableRowContents from './tableRowContents';

const CustomTableRow = memo(
  ({
    allUserGroups,
    checked,
    onAddUserToExistingUserGroup,
    onFormatIntl,
    onToggleDisableUserModal,
    onToggleEditUserModal,
    onToggleResetMfaModal,
    onToggleResetPasswordModal,
    onToggleSelectUser,
    user,
  }) => {
    const dispatch = useDispatch();

    const email = useSelector(selectEmail);
    const isBmAdmin = useSelector(selectIsBmAdmin);
    const isFederated = useSelector(selectIsFederated);
    const isFederatedAuthOnly = useSelector(selectIsFederatedAuthOnly);
    const isPmAdmin = useSelector(selectIsPmAdmin);
    const isXmAdmin = useSelector(selectIsXmAdmin);

    // Component States
    const [menuItems, setMenuItems] = useState([]);

    const handleEnableUser = () => {
      dispatch(addLoadingIndicator(user.id));
      dispatch(enableUser({ userid: user.id, name: user.name }));

      telemetryEvent().button().click().feature(Features.ADMIN).event(Events.SUBMIT_ENABLE_USER);
    };

    const handleResendInvite = () => {
      dispatch(addLoadingIndicator(user.id));
      dispatch(
        resendInviteLink({
          userid: user.id,
          email: user.email,
          name: user.name,
        })
      );

      telemetryEvent().button().click().feature(Features.ADMIN).event(Events.SUBMIT_RESEND_INVITATION);
    };

    const handleSelectMenuItem = optionObj => {
      switch (optionObj.id) {
        case 'ADD_TO_USER_GROUP': {
          onAddUserToExistingUserGroup(optionObj.groupId, user.id, user.email, user.name);
          break;
        }
        case 'DISABLE_USER':
          onToggleDisableUserModal(user.id);
          break;
        case 'EDIT_USER':
          onToggleEditUserModal(user.id);
          break;
        case 'RESET_MFA':
          onToggleResetMfaModal(user.id);
          break;
        case 'RESET_PASSWORD':
        default:
          onToggleResetPasswordModal(user.id);
          break;
      }
    };

    const handleToggleSelectUser = event => {
      if (event) {
        event.stopPropagation();
      }

      onToggleSelectUser(user.id, user.email, user.name, user.roles);
    };

    const handleUnlockUser = () => {
      dispatch(addLoadingIndicator(user.id));
      dispatch(unlockUser({ userid: user.id, name: user.name }));

      telemetryEvent().button().click().feature(Features.ADMIN).event(Events.SUBMIT_UNLOCK_USER);
    };

    useEffect(() => {
      const menuItems = [];
      const addToUserGroupMenuItem = {
        name: onFormatIntl('userTable.addToExistingUserGroupLabel'),
        disabled: allUserGroups.length < 1,
        subGroup: allUserGroups.map(({ id, name }) => ({
          id: { id: 'ADD_TO_USER_GROUP', groupId: id },
          name: name,
        })),
        positionSubGroupTop: false,
      };
      const disableUserMenuItem = {
        id: { id: 'DISABLE_USER' },
        name: onFormatIntl('userTable.disableUserLabel'),
      };
      const editUserMenuItem = {
        id: { id: 'EDIT_USER' },
        name: onFormatIntl('userTable.editUserLabel'),
      };
      const resetMfaMenuItem = {
        id: { id: 'RESET_MFA' },
        name: onFormatIntl('userTable.resetMfaLabel'),
        disabled: user.status === PENDING,
      };
      const restPasswordMenuItem = {
        id: { id: 'RESET_PASSWORD' },
        name: onFormatIntl('userTable.resetPasswordLabel'),
        disabled: user.status === PENDING,
      };

      if (isFederatedAuthOnly) {
        menuItems.push(editUserMenuItem);
      } else if ([DISABLED, LOCKED].indexOf(user.status) === -1 && !isFederated) {
        if (user.roles.length) {
          menuItems.push(editUserMenuItem, resetMfaMenuItem, restPasswordMenuItem);
        } else {
          menuItems.push(editUserMenuItem);
        }
      }

      if ([DISABLED, LOCKED].indexOf(user.status) === -1 && email !== user.email) {
        menuItems.push(disableUserMenuItem);
      }

      if (isBmAdmin || isPmAdmin || isXmAdmin) {
        menuItems.push(addToUserGroupMenuItem);
      }

      setMenuItems(menuItems);
    }, [
      allUserGroups,
      email,
      isBmAdmin,
      isFederated,
      isFederatedAuthOnly,
      isPmAdmin,
      isXmAdmin,
      onFormatIntl,
      user.email,
      user.roles,
      user.status,
    ]);

    return (
      <div className="table-row">
        <TableRowContents
          checked={checked}
          menuItems={menuItems}
          onEnableUser={handleEnableUser}
          onResendInvite={handleResendInvite}
          onSelectMenuItem={handleSelectMenuItem}
          onToggleSelectUser={handleToggleSelectUser}
          onUnlockUser={handleUnlockUser}
          user={user}
        />
      </div>
    );
  }
);

CustomTableRow.propTypes = {
  allUserGroups: PropTypes.array,
  checked: PropTypes.bool,
  onAddUserToExistingUserGroup: PropTypes.func,
  onFormatIntl: PropTypes.func,
  onToggleDisableUserModal: PropTypes.func,
  onToggleEditUserModal: PropTypes.func,
  onToggleResetMfaModal: PropTypes.func,
  onToggleResetPasswordModal: PropTypes.func,
  onToggleSelectUser: PropTypes.func,
  user: PropTypes.shape({
    email: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string,
    lastLogin: PropTypes.string,
    name: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.string,
  }),
};

CustomTableRow.displayName = 'CustomTableRow';

export default IntlFormatterHOC(CustomTableRow);
