import React from 'react';
import Button from '../Button';
import { ButtonProps } from '../Button';
import backIcon from './assets/back_button.svg';
import './backButton.styles.scss';

const BackButton: React.FC<ButtonProps> = (props) => {
  return (
    <Button
      color='secondary'
      {...props}
      classes='back-button'
    >
      <img className='back-icon' src={backIcon} />
      Back
    </Button>
  );
};

export default BackButton;

