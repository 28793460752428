import { Fetch, SessionStorage } from 'c2-common-ui';

const API_PATH = localStorage.getItem('devModeCyberCube') ? 'https://api.admin.stage2020.cybcube.com/' : '';

export const getApiKeysList = signal =>
  Fetch.get(`${API_PATH}${SessionStorage.getItem('isApiUserOnly') ? 'odap' : 'admin'}/external/list`, true, signal);
export const postGenerateApiKey = () => Fetch.post(`${API_PATH}admin/external/generate`);
export const postRegenerateApiKey = key =>
  Fetch.post(`${API_PATH}admin/external/generate/key:${encodeURIComponent(key)}`);
export const postRenewApiKey = key => Fetch.post(`${API_PATH}admin/external/renew/key:${encodeURIComponent(key)}`);
export const postRevokeApiKey = payload => Fetch.post(`${API_PATH}admin/external/revoke`, payload);
