import { createSlice } from '@reduxjs/toolkit';

import { fetchFedIdpInfo } from './federation.thunks';

const initialState = {
  entityId: '',
  metadataUrl: '',
  organizationName: '',
  roleToGroupMapping: [],
  ssoUrl: '',

  // Loading States
  fetchFedIdpInfoLoading: false,
};

export const federationSlice = createSlice({
  name: 'federation',
  initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    setEntityId: (state, action) => {
      state.entityId = action.payload;
    },
    setMetadataUrl: (state, action) => {
      state.metadataUrl = action.payload;
    },
    setOrganizationName: (state, action) => {
      state.organizationName = action.payload;
    },
    setRoleToGroupMapping: (state, action) => {
      state.roleToGroupMapping = action.payload;
    },
    setSsoUrl: (state, action) => {
      state.ssoUrl = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchFedIdpInfo.pending, state => {
        state.fetchFedIdpInfoLoading = true;
      })
      .addCase(fetchFedIdpInfo.fulfilled, state => {
        state.fetchFedIdpInfoLoading = false;
      });
  },
});

// Action creators are generated for each case reducer function
export const { setEntityId, setMetadataUrl, setOrganizationName, setRoleToGroupMapping, setSsoUrl } =
  federationSlice.actions;

export const selectEntityId = state => state.federation.entityId;
export const selectFetchFedIdpInfoLoading = state => state.federation.fetchFedIdpInfoLoading;
export const selectMetadataUrl = state => state.federation.metadataUrl;
export const selectOrganizationName = state => state.federation.organizationName;
export const selectRoleToGroupMapping = state => state.federation.roleToGroupMapping;
export const selectSsoUrl = state => state.federation.ssoUrl;

export default federationSlice.reducer;
