// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/imgs/icons.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".download-button{height:40px;font-size:14px;outline:none;border:none;cursor:pointer;display:flex;align-items:center;color:white}.download-button .download-icon{width:20px;height:20px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:-220px -680px;background-repeat:no-repeat;background-color:transparent;margin-right:10px}.download-button.download-loading{color:#65686d;cursor:no-drop}.download-button.download-loading i{color:#009dc6;margin-right:10px;font-size:15px}.download-button:focus{outline:none}\n", ""]);
// Exports
module.exports = exports;
