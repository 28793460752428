import './styles.scss';

import { Button, InputCheckbox, InputTextbox, ModalMd, ModalSm, SessionStorage } from 'c2-common-ui';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import IntlFormatterHOC from '../../../utils/intlFormatterHOC';

class UploadMetadataModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthOnly: false,
      loading: false,
      metadataFile: null,
      metadataUrl: '',
      modalOpen: false,
    };
  }

  handleCheckboxClick = () => {
    this.setState({
      isAuthOnly: !this.state.isAuthOnly,
    });
  };

  handleConfirm = () => {
    const metadata = this.state.metadataFile ? this.state.metadataFile : this.state.metadataUrl.trim();

    if (this.state.isAuthOnly) {
      SessionStorage.setItem('fed_auth_only', true);

      this.props.onSetFederatedAuthOnly(true);
    }

    this.props.onSubmit(metadata);
    this.handleToggleConfirmModal();
    this.handleToggle();
  };

  handleInputChange = event => {
    const target = event.currentTarget;

    document.getElementById('fileUploadInput').value = '';

    this.setState({
      metadataFile: null,
      metadataUrl: target.value,
    });
  };

  handleFileUploadChange = () => {
    this.setState({
      metadataFile: document.getElementById('fileUploadInput').files[0],
      metadataUrl: '',
    });
  };

  handleSubmit = () => {
    if (this.props.isUpdate) {
      this.handleToggleConfirmModal();
    } else {
      this.props.onSubmit(this.state.metadataFile ? this.state.metadataFile : this.state.metadataUrl);
      this.handleToggle();
    }
  };

  handleToggle = () => {
    this.props.onToggleModal();
  };

  handleToggleConfirmModal = () => {
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen,
    }));
  };

  handleTriggerFileUpload = () => {
    document.getElementById('fileUploadInput').click();
  };

  render() {
    return (
      <ModalMd
        classes={'user-modal-md upload-metadata-modal'}
        onHandleClose={this.handleToggle}
        open={this.props.isOpen}
        title={this.props.onFormatIntl('userModal.uploadMetadataTitle')}
      >
        {this.props.isUpdate && (
          <div className="modal-subheader">
            <span className="warning-icon" />
            <span className="warning-text">Updating SAML Metadata may cause issues with your SAML provider.</span>
          </div>
        )}
        <div className="modal-body">
          <div className="caption">
            Ensure your URL, Certificate, and Identifiers match exactly what was provided by your access management
            provider.
          </div>
          <div className="section">
            <InputTextbox
              classes={'metadata-textbox'}
              label={'Metadata URL'}
              name={'metadataUrlInput'}
              onChange={this.handleInputChange}
              placeholder={'Enter URL'}
              value={this.state.metadataUrl}
            />
            <span className="or">or</span>
            <Button classes={'file-upload'} onClick={this.handleTriggerFileUpload}>
              Upload File
            </Button>
            <span className="file-name">{this.state.metadataFile && this.state.metadataFile.name}</span>
            <input
              id="fileUploadInput"
              name="fileUploadInput"
              onChange={this.handleFileUploadChange}
              style={{ display: 'none' }}
              type="file"
            />
          </div>
          <div className="section">
            <InputCheckbox
              name={'Enable for authentication only'}
              checked={this.state.isAuthOnly}
              onChange={this.handleCheckboxClick}
            />
          </div>
        </div>
        <div className="modal-footer">
          <Button color={'secondary'} disabled={this.state.loading} onClick={this.handleToggle}>
            <FormattedMessage id="userModal.cancelButton" />
          </Button>
          <Button
            color={'primary'}
            disabled={this.state.metadataFile === null && this.state.metadataUrl === ''}
            onClick={this.handleSubmit}
          >
            <FormattedMessage id="userModal.saveButton" />
          </Button>
        </div>
        {this.state.modalOpen && (
          <ModalSm
            classes={'user-modal-sm metadata-confirm'}
            onHandleClose={this.handleToggleConfirmModal}
            open={this.state.modalOpen}
            title={this.props.onFormatIntl('userModal.confirmTitle')}
          >
            <div className="modal-subheader">
              <span className="warning-icon" />
              <span className="warning-text">You are about to change your SAML Metadata!</span>
            </div>
            <div className="modal-body">
              <span className="message">
                <FormattedMessage id={'userModal.metadataUpdateConfirm'} />
              </span>
            </div>
            <div className="modal-footer">
              <Button color={'secondary'} disabled={this.state.loading} onClick={this.handleToggleConfirmModal}>
                <FormattedMessage id="userModal.cancelButton" />
              </Button>
              <Button color={'primary'} disabled={this.state.loading} onClick={this.handleConfirm}>
                <FormattedMessage id="userModal.proceedButton" />
              </Button>
            </div>
          </ModalSm>
        )}
      </ModalMd>
    );
  }
}

UploadMetadataModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isUpdate: PropTypes.bool,
  onFormatIntl: PropTypes.func,
  onSetFederatedAuthOnly: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onToggleModal: PropTypes.func.isRequired,
};

export default IntlFormatterHOC(UploadMetadataModal);
