/* eslint-disable react/jsx-no-bind */
/* eslint-disable one-var */
import { InputCheckbox, MenuOptions } from 'c2-common-ui';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { ACTIVE, DISABLED, LOCKED, PENDING } from '../../../../constants/appVariables';
import {
  selectAllowedRoles,
  selectIsBmAdmin,
  selectIsFederated,
  selectIsPmAdmin,
  selectIsXmAdmin,
} from '../../../../redux/sessionUser/sessionUser.slice';
import { selectLoadingIndicators } from '../../../../redux/userAdmin/userAdmin.slice';
import IntlFormatterHOC from '../../../../utils/intlFormatterHOC';
import { mapAndSortRoles } from '../../../../utils/user';
import Tooltip from '../../../common/Tooltip';

const TableRowContents = ({
  checked,
  menuItems,
  onEnableUser,
  onResendInvite,
  onSelectMenuItem,
  onToggleSelectUser,
  onUnlockUser,
  user,
}) => {
  const allowedRoles = useSelector(selectAllowedRoles);
  const isBmAdmin = useSelector(selectIsBmAdmin);
  const isFederated = useSelector(selectIsFederated);
  const isPmAdmin = useSelector(selectIsPmAdmin);
  const isXmAdmin = useSelector(selectIsXmAdmin);
  const loadingIndicators = useSelector(selectLoadingIndicators);

  const getRoleDisplayNames = userRoles => {
    return mapAndSortRoles(allowedRoles, userRoles);
  };

  const handleClick = () => {
    if (user.status === DISABLED) {
      onEnableUser();
    } else if (user.status === LOCKED) {
      onUnlockUser();
    } else if (user.status === PENDING && !isFederated) {
      onResendInvite();
    }
  };

  return (
    <React.Fragment>
      <div className="table-element" title={user.name}>
        <span>
          {(isBmAdmin || isPmAdmin || isXmAdmin) && <InputCheckbox checked={checked} onChange={onToggleSelectUser} />}
        </span>
        {user.name}
      </div>
      <div className="table-element" title={user.email}>
        {user.email}
      </div>
      <div className="table-element">
        {user.status === ACTIVE && <FormattedMessage id="userTable.activeLabel" />}
        {user.status !== ACTIVE && (
          <div>
            {user.status === DISABLED && <FormattedMessage id="userTable.disabledLabel" />}
            {user.status === LOCKED && <FormattedMessage id="userTable.lockedLabel" />}
            {user.status === PENDING && <FormattedMessage id="userTable.invitePendingLabel" />}

            <div
              className={classnames('clickable-text', {
                disabled: loadingIndicators.includes(user.id),
              })}
              onClick={handleClick}
            >
              {user.status === DISABLED && <FormattedMessage id="userTable.enableUserLabel" />}
              {user.status === LOCKED && <FormattedMessage id="userTable.unlockUserLabel" />}
              {user.status === PENDING && !isFederated && <FormattedMessage id="userTable.resendInviteLabel" />}
            </div>
          </div>
        )}
      </div>
      <div className="table-element">
        {(() => {
          const roleDisplayNames = getRoleDisplayNames(user.roles);

          if (user.roles.length === 0) {
            return '--';
          } else if (user.roles.length === 1) {
            return <span title={roleDisplayNames}>{roleDisplayNames}</span>;
          }

          return (
            <div>
              <Tooltip id={`roles_${user.id}`}>
                <div className="tooltip-contents">
                  {roleDisplayNames.map((role, i) => (
                    <span key={`key_tooltip_${i}`}>{role}</span>
                  ))}
                </div>
              </Tooltip>
              {`${user.roles.length} roles`}
              <span className="hoverable-text" data-tip data-for={`roles_${user.id}`}>
                user roles
              </span>
            </div>
          );
        })()}
      </div>
      {(isBmAdmin || isPmAdmin || isXmAdmin) && (
        <div className="table-element">
          {(() => {
            if (user.groups.length === 0) {
              return '--';
            } else if (user.groups.length === 1) {
              return <span title={user.groups}>{user.groups}</span>;
            }

            return (
              <React.Fragment>
                <Tooltip id={`groups_${user.id}`}>
                  <div className="tooltip-contents">
                    {[...user.groups].sort().map((group, i) => (
                      <span key={`key_tooltip_${i}`}>{group}</span>
                    ))}
                  </div>
                </Tooltip>
                {`${user.groups.length} groups`}
                <span className="hoverable-text" data-tip data-for={`groups_${user.id}`}>
                  user groups
                </span>
              </React.Fragment>
            );
          })()}
        </div>
      )}
      <div className="table-element" title={user.lastLogin}>
        {user.lastLogin || '--'}
      </div>
      <div className="table-element" title={user.disabledDate}>
        {user.disabledDate || '--'}
      </div>
      <div className="user-options">
        <MenuOptions anchorMarginRight={5} anchorMarginTop={5} onClick={onSelectMenuItem} options={menuItems} />
      </div>
    </React.Fragment>
  );
};

TableRowContents.propTypes = {
  checked: PropTypes.bool,
  menuItems: PropTypes.array,
  onEnableUser: PropTypes.func,
  onResendInvite: PropTypes.func,
  onSelectMenuItem: PropTypes.func,
  onToggleSelectUser: PropTypes.func,
  onUnlockUser: PropTypes.func,
  user: PropTypes.shape({
    disabledDate: PropTypes.string,
    email: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string,
    lastLogin: PropTypes.string,
    name: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.string,
  }),
};

export default IntlFormatterHOC(TableRowContents);
