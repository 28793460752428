import './styles.scss';

import { Button, FilterTypeahead, InputSearchbox, MenuOptions } from 'c2-common-ui';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  selectAllowedRoles,
  selectIsBmAdmin,
  selectIsFederated,
  selectIsPmAdmin,
  selectIsXmAdmin,
} from '../../../redux/sessionUser/sessionUser.slice';
import { selectUserGroups } from '../../../redux/userAdmin/userAdmin.slice';

const UserInputsGroup = ({
  currentFilterGroupsList,
  currentFilterRolesList,
  loading,
  onFormatIntl,
  onPostUserInputMetric,
  onSelectGroupFilterItem,
  onSelectMenuItem,
  onSelectRoleFilterItem,
  onSetSearchInput,
  onToggleAddUserModal,
  selectedUsers,
}) => {
  const allowedRoles = useSelector(selectAllowedRoles);
  const isBmAdmin = useSelector(selectIsBmAdmin);
  const isFederated = useSelector(selectIsFederated);
  const isPmAdmin = useSelector(selectIsPmAdmin);
  const isXmAdmin = useSelector(selectIsXmAdmin);
  const userGroups = useSelector(selectUserGroups);

  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const createUserGroupMenuItems = actionType =>
      userGroups.map(({ id, name }) => ({
        id: { id: `${actionType}_TO_USER_GROUP`, groupId: id },
        name: name,
      }));

    setMenuItems([
      {
        name: 'Add to Existing User Group',
        disabled: Object.keys(selectedUsers).length === 0 || userGroups.length === 0,
        subGroup: createUserGroupMenuItems('ADD'),
      },
      {
        id: { id: 'CREATE_NEW_USER_GROUP' },
        name: 'Create New User Group',
      },
      {
        name: 'Edit User Group',
        disabled: userGroups.length === 0,
        positionSubGroupTop: false,
        subGroup: createUserGroupMenuItems('EDIT'),
      },
    ]);
  }, [selectedUsers, userGroups]);

  const handleRenderAnchorComponent = () => {
    return (
      <div className="user-groups-button">
        <span>Other Actions</span>
        <i className="fas fa-caret-down" />
      </div>
    );
  };

  return (
    <div className="user-inputs-group">
      <div className="table-actions">
        <InputSearchbox
          classes={classnames('search-user-input', { 'loading-mask': loading })}
          onChange={onSetSearchInput}
          onFocus={onPostUserInputMetric}
          placeholder={onFormatIntl('userTable.searchNamePlaceholder')}
        />
        {!loading && <span className="filter-by-text">Filter by</span>}
        <FilterTypeahead
          allowSearch={false}
          classes={classnames('user-roles-filter', { 'loading-mask': loading })}
          disabled={!loading && Object.keys(allowedRoles).length === 0}
          items={currentFilterRolesList}
          label={'User Role'}
          onSelectItem={onSelectRoleFilterItem}
        />
        {(isBmAdmin || isPmAdmin || isXmAdmin) && (
          <FilterTypeahead
            allowSearch={false}
            classes={classnames('user-groups-filter', {
              'loading-mask': loading,
            })}
            disabled={!loading && userGroups.length === 0}
            items={currentFilterGroupsList}
            label={'User Group'}
            onSelectItem={onSelectGroupFilterItem}
          />
        )}
      </div>
      <div className="misc-actions">
        {(isBmAdmin || isPmAdmin || isXmAdmin) && (
          <MenuOptions
            classes={loading && 'loading-mask'}
            anchorComponent={handleRenderAnchorComponent()}
            onClick={onSelectMenuItem}
            options={menuItems}
          />
        )}
        {!isFederated && (
          <Button
            classes={classnames('add-user', { 'loading-mask': loading })}
            color={'primary'}
            onClick={onToggleAddUserModal}
          >
            <FormattedMessage id="userAdmin.addButton" />
          </Button>
        )}
      </div>
    </div>
  );
};

UserInputsGroup.propTypes = {
  currentFilterGroupsList: PropTypes.array,
  currentFilterRolesList: PropTypes.array,
  loading: PropTypes.bool,
  onFormatIntl: PropTypes.func,
  onPostUserInputMetric: PropTypes.func,
  onSelectGroupFilterItem: PropTypes.func,
  onSelectMenuItem: PropTypes.func,
  onSelectRoleFilterItem: PropTypes.func,
  onSetSearchInput: PropTypes.func,
  onToggleAddUserModal: PropTypes.func,
  selectedUsers: PropTypes.object,
};

export default UserInputsGroup;
