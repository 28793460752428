/* eslint-disable react/jsx-no-bind */
import './styles.scss';

import * as Sentry from '@sentry/react';
import { Footer, Header } from 'c2-common-ui';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { WARNING } from '../../constants/appVariables';
import { selectFetchApiKeysLoading } from '../../redux/apiKeys/apiKeys.slice';
import { fetchApiKeys } from '../../redux/apiKeys/apiKeys.thunks';
import {
  addNotification,
  clearNotifications,
  selectNotifications,
} from '../../redux/notifications/notifications.slice';
import { selectIsApiAdmin } from '../../redux/sessionUser/sessionUser.slice';
import IntlFormatterHOC from '../../utils/intlFormatterHOC';
import ErrorFallback from '../common/ErrorFallback';
import View from './view';

const ApiKeyManagement = ({ onFormatIntl }) => {
  const dispatch = useDispatch();

  const _controller = useMemo(() => new AbortController(), []);
  const _signal = _controller.signal;

  const isFetchApiKeysLoading = useSelector(selectFetchApiKeysLoading);
  const isApiAdmin = useSelector(selectIsApiAdmin);

  useEffect(() => {
    dispatch(fetchApiKeys({ signal: _signal }));

    addNotification({
      type: WARNING,
      message: 'Package names have been reconfigured and you may see a change, there is no change in functionality.',
    });

    return () => {
      if (_controller) {
        _controller.abort();
      }

      dispatch(clearNotifications());
    };
  }, [dispatch, _controller, _signal]);

  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      <div className="main-view api-key-management">
        <Header
          label={
            !isApiAdmin ? onFormatIntl('apiKeyManagement.apiUserOnly.title') : onFormatIntl('apiKeyManagement.title')
          }
        />
        <View isApiUserOnly={!isApiAdmin} loading={isFetchApiKeysLoading} />
        <Footer label={onFormatIntl('common.footer')} />
      </div>
    </Sentry.ErrorBoundary>
  );
};

ApiKeyManagement.propTypes = {
  onFormatIntl: PropTypes.func,
};

export default IntlFormatterHOC(ApiKeyManagement);
