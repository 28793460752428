// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/imgs/icons.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".add-label-container{position:relative;display:inline-block}.add-label-container .add-label-button{display:flex;justify-content:center;align-items:center;height:32px;color:#585959;font-family:Roboto;font-size:14px;font-weight:bold;line-height:1.71;padding:0px 8px;cursor:pointer}.add-label-container .add-label-button .label{padding:5px}.add-label-container .add-label-button.active .caret-icon,.add-label-container .add-label-button:hover .caret-icon{background-position:-20px -560px}.add-label-container .add-label-button:focus{outline:none}.add-label-container .add-label-button .caret-icon{width:25px;height:25px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:-20px -520px;background-repeat:no-repeat;margin-left:3px}.add-label-container .dropdown-wrapper{position:absolute;top:38px}.add-label-container .dropdown-wrapper.right{right:0}.add-label-container .dropdown-wrapper.right .dropdown .caret-up{right:14px;left:unset}.add-label-container .dropdown-wrapper .dropdown{position:relative;display:inline-block;width:200px;max-height:254px;z-index:666;background-color:#fff;border-radius:4px;padding:8px 0px;box-shadow:0 4px 16px 0 rgba(45,46,46,0.08),0 1px 4px 0 rgba(45,46,46,0.2)}.add-label-container .dropdown-wrapper .dropdown .caret-up{position:absolute;top:-14px;width:0;height:0;border:7px solid transparent;border-bottom-color:#ffffff;z-index:2;left:10px}.add-label-container .dropdown-wrapper .dropdown .elements-wrapper{max-height:190px;overflow:auto}.add-label-container .dropdown-wrapper .dropdown .elements-wrapper .element{padding:0px 20px;width:100%;height:38px;font-size:14px;font-weight:normal;line-height:2.71;color:#585959;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;cursor:pointer}.add-label-container .dropdown-wrapper .dropdown .elements-wrapper .element:hover,.add-label-container .dropdown-wrapper .dropdown .elements-wrapper .element.active{background-color:#f3f3f4}\n", ""]);
// Exports
module.exports = exports;
