export const getTenantInfoQuery = cognitoPoolID => `
  query {
    getTenantInfo(CognitoPoolID: "${cognitoPoolID}") {
      Domains
      APIKeys {
        Revoked
        Expiration
        Key
        Status
        UUID
      }
      Products {
        Name
        Type
        SKU
        MetricLimits {
          Type
          Name
          Timeframe
          Value
          HardLimit
        }
        AddOns {
          Name
          SKU
        }
        Packages {
          Name
          SKU
        }
      }
    }
  }
`;
