/* eslint-disable react/jsx-no-bind */
/* eslint-disable one-var */
import './styles.scss';

import { Button } from 'c2-common-ui';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  KEY_STATUS_ACTIVE,
  KEY_STATUS_EXPIRED,
  KEY_STATUS_EXPIRING,
  KEY_STATUS_REVOKED,
} from '../../../constants/appVariables';

const View = ({ expirationDate, isApiUserOnly, isHistorical, keyString, onCopyKey, onToggleModal, status }) => {
  const handleSetKeyStatusText = () => {
    let statusText = '';

    switch (status) {
      case KEY_STATUS_EXPIRING:
        statusText = 'aboutToExpire';
        break;
      case KEY_STATUS_EXPIRED:
        statusText = 'expired';
        break;
      case KEY_STATUS_REVOKED:
        statusText = 'revoked';
        break;
      default:
        statusText = 'active';
        break;
    }

    return <FormattedMessage id={`apiKeyManagement.${statusText}`} />;
  };

  return (
    <div className="contents">
      <div className="key-details">
        <div className="column">
          <div className="title">
            <FormattedMessage id="apiKeyManagement.apiKey" />
          </div>
          <div className="text">{keyString}</div>
        </div>
        <div className="column">
          <div className="title">
            <FormattedMessage id="apiKeyManagement.keyStatus" />
          </div>
          <div className="text">
            <span className={classnames('status-icon', `${isHistorical ? 'unavailable' : status.toLowerCase()}`)} />
            <span>{handleSetKeyStatusText()}</span>
          </div>
        </div>
        <div className="column">
          <div className="title">
            {status === KEY_STATUS_REVOKED ? (
              <FormattedMessage id="apiKeyManagement.revokeDate" />
            ) : (
              <FormattedMessage id="apiKeyManagement.expirationDate" />
            )}
          </div>
          <div className="text">{expirationDate}</div>
        </div>
      </div>
      {(status === KEY_STATUS_ACTIVE || status === KEY_STATUS_EXPIRING) && (
        <div className="key-actions">
          <Button classes={'key-copy-button'} color={'secondary'} onClick={onCopyKey}>
            <FormattedMessage id="apiKeyManagement.copyKey" />
          </Button>
          {!isApiUserOnly && (
            <Button classes={'key-revoke-button'} color={'secondary'} onClick={onToggleModal}>
              <FormattedMessage id="apiKeyManagement.revokeKey" />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

View.propTypes = {
  expirationDate: PropTypes.string,
  isApiUserOnly: PropTypes.bool,
  isHistorical: PropTypes.bool,
  keyString: PropTypes.string,
  onCopyKey: PropTypes.func,
  onToggleModal: PropTypes.func,
  status: PropTypes.string,
};

export default View;
