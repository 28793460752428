/* eslint-disable react/jsx-no-bind */
/* eslint-disable one-var */
import './styles.scss';

import * as Sentry from '@sentry/react';
import { Fetch, Session, SessionStorage } from 'c2-common-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ADMIN, ERROR } from '../../constants/appVariables';
import { addNotification } from '../../redux/notifications/notifications.slice';
import { selectEmail, selectName } from '../../redux/sessionUser/sessionUser.slice';
import telemetryEvent, { Events, Features } from '../../utils/telemetry';
import { getAppDetails } from '../../webAPIs/users';
import SideNavBrand from './SideNavBrand';
import SideNavUser from './SideNavUser';
import View from './view';

const SideNav = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const email = useSelector(selectEmail);
  const name = useSelector(selectName);

  // Component States
  const [activeTab, setActiveTab] = useState('');
  const [docsUrl, setDocsUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [version, setVersion] = useState('');

  const configureDocsUrl = () => {
    let docsUrl = '';

    if (localStorage.getItem('devModeCyberCube') || window.location.href.toString().indexOf('localhost') !== -1) {
      setDocsUrl('http://localhost:3000');
    } else {
      const getDocsUrl = function (appsArr) {
        const docsUrlObj = appsArr.filter(app => app.name === 'DOCS')[0];

        docsUrl = docsUrlObj && docsUrlObj.url;

        return docsUrl;
      };

      if (SessionStorage.getItem('apps')) {
        const appsArr = JSON.parse(SessionStorage.getItem('apps'));

        setDocsUrl(getDocsUrl(appsArr));
      } else {
        const host = window.location.host.split('.');

        host.shift();

        Fetch.get(SessionStorage.getItem('appConfig.urlApps'))
          .then(res => {
            SessionStorage.setItem('apps', JSON.stringify(res));

            setDocsUrl(getDocsUrl(res));
          })
          .catch(err => {
            Sentry.captureException(err);

            setDocsUrl('');
          });
      }
    }
  };

  const handleLogout = async () => {
    try {
      telemetryEvent().button().click().feature(Features.SIDE_NAV).event(Events.USER_LOGGED_OUT);
    } catch (err) {
      Sentry.captureException(err);

      // eslint-disable-next-line no-console
      console.error(err);
    }

    Session.doLogout();
  };

  const handleOpenDocsPage = () => {
    if (!docsUrl) {
      dispatch(addNotification({ type: ERROR, message: 'Docs Page is currently unavailable' }));
    } else {
      window.open(docsUrl);

      telemetryEvent().button().click().feature(Features.SIDE_NAV).event(Events.VISIT_DOCS_PAGE);
    }
  };

  const handleOpenPage = useCallback(
    tabName => {
      const uiEvent = `visit_${(tabName || ADMIN).toLowerCase()}`;

      navigate(`/${tabName}`);

      setActiveTab(tabName.toLowerCase());

      telemetryEvent().button().click().feature(Features.SIDE_NAV).event(uiEvent);
    },
    [navigate]
  );

  useEffect(() => {
    setActiveTab(window.location.hash.split('/')[1]);

    getAppDetails()
      .then(res => {
        if (res.status === 'success') {
          setVersion(res.data.RbacVersion);
        }

        setIsLoading(false);
      })
      .catch(err => {
        Sentry.captureException(err);

        setIsLoading(false);
        setVersion('');
      });

    configureDocsUrl();
  }, []);

  return (
    <div className="side-nav">
      <SideNavBrand />
      <View activeTab={activeTab} onOpenDocsPage={handleOpenDocsPage} onOpenPage={handleOpenPage} />
      <SideNavUser email={email} isLoading={isLoading} name={name} onLogoutUser={handleLogout} version={version} />
    </div>
  );
};

SideNav.displayName = 'SideNav';

export default SideNav;
