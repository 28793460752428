import { APPS } from '../../../../constants/appVariables';

export const createRoleActivationsSettings = activationData => {
  return {
    [APPS.AM]: {
      AMRegularUser: {
        activations: activationData.amActivations,
        licenses: activationData.amLicenses,
      },
    },
    [APPS.PM]: {
      PMRegularUser: {
        activations: activationData.pmActivations,
        licenses: activationData.pmLicenses,
      },
    },
    [APPS.CC]: {
      APIUser: {
        activations: activationData.apiActivations,
        licenses: activationData.apiLicenses,
      },
    },
    [APPS.BM]: {
      BMRegularUser: {
        activations: activationData.bmActivations,
        licenses: activationData.bmLicenses,
      },
      BMManager: {
        activations: activationData.bmManagerActivations,
        licenses: activationData.bmManagerLicenses,
      },
    },
    [APPS.XM]: {
      XMRegularUser: {
        activations: activationData.xmActivations,
        licenses: activationData.xmLicenses,
      },
    },
  };
};
