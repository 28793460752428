/* eslint-disable react/jsx-no-bind */
import './styles.scss';

import { Button, ModalSm } from 'c2-common-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import IntlFormatterHOC from '../../../../utils/intlFormatterHOC';

const ResetPasswordModal = ({ isOpen, onFormatIntl, onSubmit, onToggleModal, userName }) => {
  const handleSubmit = () => {
      onSubmit();
      handleToggle();
    },
    handleToggle = () => {
      onToggleModal();
    };

  return (
    <ModalSm
      classes={'user-modal-sm'}
      onHandleClose={handleToggle}
      open={isOpen}
      title={onFormatIntl('userModal.resetPasswordTitle')}
    >
      <div className="modal-subheader">
        <span className="warning-icon" />
        Attention
      </div>
      <div className="modal-body">
        <div className="message">
          Please confirm you would like to reset <strong>{userName}</strong>&#39;s password. They will receive an
          email/SMS with temporary password.
        </div>
      </div>
      <div className="modal-footer">
        <Button color={'secondary'} onClick={handleToggle}>
          <FormattedMessage id="userModal.cancelButton" />
        </Button>
        <Button color={'primary'} onClick={handleSubmit}>
          <FormattedMessage id="userModal.resetButton" />
        </Button>
      </div>
    </ModalSm>
  );
};

ResetPasswordModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onFormatIntl: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onToggleModal: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
};

export default IntlFormatterHOC(ResetPasswordModal);
