import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allowedRoles: {},
  email: '',
  id: '',
  isAmAdmin: false,
  isApiAdmin: false,
  isApiUserOnly: false,
  isBmAdmin: false,
  isFederated: false,
  isFederatedAuthOnly: false,
  isPmAdmin: false,
  isSuperAdmin: false,
  isXmAdmin: false,
  name: '',
  tenantId: '',
  tenantName: '',
  userpoolId: '',
  isFederatedManager: false,
};

export const sessionUserSlice = createSlice({
  name: 'sessionUser',
  initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    setAllowedRoles: (state, action) => {
      state.allowedRoles = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setId: (state, action) => {
      state.id = action.payload;
    },
    setIsAmAdmin: (state, action) => {
      state.isAmAdmin = action.payload;
    },
    setIsApiAdmin: (state, action) => {
      state.isApiAdmin = action.payload;
    },
    setIsApiUserOnly: (state, action) => {
      state.isApiUserOnly = action.payload;
    },
    setIsBmAdmin: (state, action) => {
      state.isBmAdmin = action.payload;
    },
    setIsFederated: (state, action) => {
      state.isFederated = action.payload;
    },
    setIsFederatedAuthOnly: (state, action) => {
      state.isFederatedAuthOnly = action.payload;
    },
    setIsPmAdmin: (state, action) => {
      state.isPmAdmin = action.payload;
    },
    setIsSuperAdmin: (state, action) => {
      state.isSuperAdmin = action.payload;
    },
    setIsXmAdmin: (state, action) => {
      state.isXmAdmin = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setTenantName: (state, action) => {
      state.tenantName = action.payload;
    },
    setUserpoolId: (state, action) => {
      state.userpoolId = action.payload;
    },
    setIsFederatedManager: (state, action) => {
      state.isFederatedManager = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAllowedRoles,
  setEmail,
  setId,
  setIsAmAdmin,
  setIsApiAdmin,
  setIsApiUserOnly,
  setIsBmAdmin,
  setIsFederated,
  setIsFederatedAuthOnly,
  setIsPmAdmin,
  setIsSuperAdmin,
  setIsXmAdmin,
  setName,
  setTenantName,
  setUserpoolId,
  setIsFederatedManager,
} = sessionUserSlice.actions;

export const selectAllowedRoles = state => state.sessionUser.allowedRoles;
export const selectEmail = state => state.sessionUser.email;
export const selectId = state => state.sessionUser.id;
export const selectIsAmAdmin = state => state.sessionUser.isAmAdmin;
export const selectIsApiAdmin = state => state.sessionUser.isApiAdmin;
export const selectIsApiUserOnly = state => state.sessionUser.isApiUserOnly;
export const selectIsBmAdmin = state => state.sessionUser.isBmAdmin;
export const selectIsFederated = state => state.sessionUser.isFederated;
export const selectIsFederatedAuthOnly = state => state.sessionUser.isFederatedAuthOnly;
export const selectIsPmAdmin = state => state.sessionUser.isPmAdmin;
export const selectIsSuperAdmin = state => state.sessionUser.isSuperAdmin;
export const selectIsXmAdmin = state => state.sessionUser.isXmAdmin;
export const selectIsFederatedManager = state => state.sessionUser.isFederatedManager;
export const selectName = state => state.sessionUser.name;
export const selectTenantId = state => state.sessionUser.tenantId;
export const selectTenantName = state => state.sessionUser.tenantName;
export const selectUserpoolId = state => state.sessionUser.userpoolId;

export default sessionUserSlice.reducer;
