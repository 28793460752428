import './ErrorStatus.styles.scss';

import { Button } from 'c2-common-ui';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import ErrorNotFound from './ErrorNotFound';

interface ErrorStatusProps {
  code: number;
}

const ErrorStatus: React.FC<ErrorStatusProps> = ({ code }) => {
  const navigate = useNavigate();

  const handleClick = React.useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <div className="error-status-page">
      <h1 className="error-status-code">{code}</h1>
      <ErrorNotFound />
      <Button onClick={handleClick} classes={'primary error-status-button'}>
        Go Home
      </Button>
    </div>
  );
};

export default React.memo(ErrorStatus);
