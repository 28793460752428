import './App.scss';

import { NotificationCenter } from 'c2-common-ui';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { deleteNotification, selectNotifications } from '../../redux/notifications/notifications.slice';
import SideNav from '../SideNav';

const Main: React.FC = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(selectNotifications);
  const onDeleteNotification = (index: number) => dispatch(deleteNotification(index));

  return (
    <div className="shell">
      <NotificationCenter notifications={notifications} onHandleDeleteNotification={onDeleteNotification} />
      <SideNav />
      <main className="main-view__container">
        <Outlet />
      </main>
    </div>
  );
};

export default React.memo(Main);
