import { debounce } from 'throttle-debounce';

var updateSessionStorage = function updateSessionStorage() {
  // Not copying over keys which begin with 'appConfig.'
  var sessionStorageToBeCopied = Object.keys(sessionStorage).reduce(function (object, key) {
    if (key.indexOf('appConfig.') !== 0) {
      object[key] = sessionStorage[key];
    }

    return object;
  }, {});
  localStorage.setItem('updateSessionStorage', JSON.stringify(sessionStorageToBeCopied));
  localStorage.removeItem('updateSessionStorage');
},
    debouncedUpdateSessionStorage = debounce(2500, updateSessionStorage),
    SessionStorage = {
  getItem: function getItem(key) {
    if (!sessionStorage.length) {
      localStorage.setItem('getSessionStorage', Date.now());
    }

    return sessionStorage.getItem(key);
  },
  setItem: function setItem(key, val) {
    sessionStorage.setItem(key, val);
    debouncedUpdateSessionStorage();
  },
  setItems: function setItems(arrKeys, arrVals) {
    arrKeys.forEach(function (key, idx) {
      sessionStorage.setItem(key, arrVals[idx]);
    });
    debouncedUpdateSessionStorage();
  },
  removeItem: function removeItem(key) {
    sessionStorage.removeItem(key);
    localStorage.setItem('removeSessionStorageKey', key);
    localStorage.removeItem('removeSessionStorageKey');
  },
  clear: function clear() {
    sessionStorage.clear();
    localStorage.setItem('clearSessionStorage', true);
    localStorage.removeItem('clearSessionStorage');
  }
};

export default SessionStorage;