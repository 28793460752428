/* eslint-disable one-var */
import { ACTIVE, DISABLED, FORCE_CHANGE_PASSWORD, LOCKED, PENDING, ROLE_API_USER } from '../constants/appVariables';

const getUserGroups = (groups, userId) => {
  const userGroups = [];

  if (groups && groups.length) {
    groups.forEach(group => {
      if (group.users && group.users.some(({ user_id }) => user_id === userId)) {
        userGroups.push(group.name);
      }
    });
  }

  return userGroups;
};

const getUserStatus = user => {
  if (user.enabled) {
    switch (user.status) {
      case FORCE_CHANGE_PASSWORD:
        return PENDING;
      case LOCKED.toUpperCase():
        return LOCKED;
      default:
        return ACTIVE;
    }
  } else if (!user.enabled && user.status === LOCKED.toUpperCase()) {
    return LOCKED;
  }

  return DISABLED;
};

export const getNumUsersByProduct = (users, status = 'pending', products = []) =>
  users.filter(
    ({ flags, roles }) => flags[status] && roles.some(role => products.some(product => role.indexOf(product) > -1))
  ).length;

export const mapAndSortGroups = groups =>
  groups
    .map(userGroup => ({
      id: userGroup.group_id,
      name: userGroup.name,
      owner: userGroup.owner,
      type: userGroup.type,
      users: userGroup.users,
    }))
    .sort((groupA, groupB) => {
      if (!groupA?.name) {
        return 1;
      }

      if (!groupB?.name) {
        return -1;
      }

      return groupA?.name.toLowerCase() > groupB?.name.toLowerCase() ? 1 : -1;
    });

export const mapAndSortRoles = (rolesMapping, userRoles) =>
  userRoles
    .map(role => {
      return rolesMapping && rolesMapping[role] ? rolesMapping[role].name : role;
    })
    .sort();

export const mapUserInfo = (user, groups) => ({
  disabledDate: user.disabledDate,
  email: user.email,
  groups: getUserGroups(groups, user.user_id),
  id: user.user_id,
  lastLogin: user.lastLogin,
  name: user.name,
  roles: user.roles,
  status: getUserStatus(user),
  flags: {
    pending: user.enabled && user.status === FORCE_CHANGE_PASSWORD,
    active: getUserStatus(user) === ACTIVE,
  },
});

export const getValueFromAttributesOrEmpty = (attributes, prop) => {
  const attribute = attributes && attributes.find(({ Name }) => Name === prop);

  return (attribute && attribute.Value) || '';
};

export const isApiUser = roles => roles.includes(ROLE_API_USER);
